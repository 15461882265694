<template>
  <div class="layout__central">
    <div :class="$style.root">
      <div class="container">
        <div class="row">
          <div class="col-xs-offset-3 col-xs-7">
            <img :src="require('./image.svg')" />

            <h2 :class="$style.title">{{ $trlMessage('page.404.title') }}</h2>
            <p :class="$style.text">{{ $trlMessage('page.404.text') }}</p>
            <a href="/" class="button">{{ $trlMessage('page.404.return') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style lang="less" module>
.root {
  composes: global(error-block);
  background-image: none;
}

.title {
  composes: global(error-block__title);
  margin: 10px 0;
}

.text {
  margin: 10px 0;
}
</style>

<i18n lang="json">{}</i18n>
