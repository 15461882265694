<template>
  <component
    :is="tag"
    type="button"
    data-qa="attach-button"
    :disabled="disabled"
    :class="$style.attach"
    @click="$emit(disabled ? 'disabled-click' : 'click')"
  >
    <base-icon type="clip" :class="$style.icon" width="32" />
    <span :class="$style.name">
      <slot name="caption">
        {{ $i18n('attach') }}
      </slot>
    </span>
  </component>
</template>

<i18n lang="json">
{
  "attach": {
    "ru_RU": "Прикрепить файл",
    "en_US": "Attach file"
  }
}
</i18n>

<script>
import { defineComponent } from 'vue';
import BaseIcon from '@/components/icon.vue';

export default defineComponent({
  name: 'AttachmentAddButton',
  components: { BaseIcon },
  props: {
    disabled: Boolean
  },
  emits: ['click', 'disabled-click'],
  computed: {
    tag() {
      return this.disabled ? 'div' : 'button';
    }
  }
});
</script>

<style module>
.attach {
  display: flex;
  align-items: center;
  max-width: 100%;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  color: $blueColor;
}
.attach:hover {
  color: $redColor;
}
.attach[disabled] {
  cursor: default;
  color: #cccccc;
}
.attach:hover[disabled] {
  color: #cccccc;
}
.icon {
  flex: 0 0 auto;
  margin: 0;
  color: $textColor;
}
</style>
