<template>
  <div :class="progressClassName" :title="deadlineTitle">
    <span class="deadline-progress__icon">
      <icon type="timer" />
    </span>
    <span class="deadline-progress__title">
      {{ title }}
    </span>
    <span class="deadline-progress__gauge">
      <span class="deadline-progress__value" :style="progressStyle"></span>
    </span>
  </div>
</template>

<script>
import Icon from '../icon.vue';

export default {
  name: 'DeadlineProgressPure',
  components: {
    Icon
  },
  props: {
    progress: {
      type: Number,
      required: true
    },
    daysRemaining: {
      type: Number,
      required: true
    },
    deadlineTitle: {
      type: String,
      default: null
    }
  },
  computed: {
    progressClassName() {
      return {
        'deadline-progress': true,
        'deadline-progress_warning': this.progress >= 0.8 && this.progress <= 1,
        'deadline-progress_overdue': this.progress > 1
      };
    },
    progressStyle() {
      return {
        width: `${Math.min(this.progress * 100, 100)}%`
      };
    },
    title() {
      const days = Math.abs(this.daysRemaining);
      return this.daysRemaining >= 0
        ? this.$trlMessage('dashboard.progress.remaining', {
            days
          })
        : this.$trlMessage('dashboard.progress.overdue', {
            days
          });
    }
  }
};
</script>

<style lang="less">
@import '../../../less/common/mixins/font';
@import '../../../less/common/variables.less';

.deadline-progress {
  color: #707b7f;
  position: relative;
  display: flex;
  align-items: center;

  &_warning {
    color: @yellowColor;
  }

  &_overdue {
    color: @redColor;
  }

  &__gauge {
    display: block;
    width: 50px;
    height: 5px;
    border-radius: 3px;
    background: #d3d3d3;
    overflow: hidden;
    margin-left: 10px;
    position: relative;
  }

  &__value {
    display: block;
    position: absolute;
    width: 0;
    height: 5px;
    background: @lightTextColor;
    min-width: 1px;
  }

  &_warning &__value {
    background: @yellowColor;
  }

  &_overdue &__value {
    background: @redColor;
  }

  &__title {
    .font_bold();
    font-size: 11px;
    line-height: 1;
    text-transform: uppercase;
  }

  &__icon {
    @offset: -8.5px;
    @marginRight: 5px;
    margin: @offset @offset + @marginRight @offset @offset;
    display: flex;
  }
}
</style>

<i18n lang="json">{}</i18n>
