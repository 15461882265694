import '@/services/feature-toggle/feature-toggle';
import { configureCompat } from 'vue';

import registerNpsElement from '@/modules/nps/register-nps-element';
import { RegisterGlobalComponents } from './register-global-components';

configureCompat({
  MODE: 2,
  GLOBAL_MOUNT: false,
  OPTIONS_DESTROYED: false,
  INSTANCE_SCOPED_SLOTS: false,
  FILTERS: false,
  RENDER_FUNCTION: false,
  GLOBAL_DELETE: false,
  GLOBAL_SET: false,
  ATTR_ENUMERATED_COERCION: false,
  ATTR_FALSE_VALUE: false,

  COMPONENT_V_MODEL: undefined,
  COMPONENT_ASYNC: undefined,
  COMPONENT_FUNCTIONAL: undefined,
  CONFIG_DEVTOOLS: undefined,
  CONFIG_KEY_CODES: undefined,
  CONFIG_OPTION_MERGE_STRATS: undefined,
  CONFIG_PRODUCTION_TIP: undefined,
  CONFIG_SILENT: undefined,
  CUSTOM_DIR: undefined,
  CONFIG_WHITESPACE: undefined,
  GLOBAL_EXTEND: undefined,
  GLOBAL_MOUNT_CONTAINER: undefined,
  GLOBAL_OBSERVABLE: undefined,
  GLOBAL_PRIVATE_UTIL: undefined,
  GLOBAL_PROTOTYPE: undefined,
  INSTANCE_ATTRS_CLASS_STYLE: undefined,
  INSTANCE_CHILDREN: undefined,
  INSTANCE_DELETE: undefined,
  INSTANCE_DESTROY: undefined,
  INSTANCE_EVENT_EMITTER: undefined,
  INSTANCE_EVENT_HOOKS: undefined,
  INSTANCE_LISTENERS: undefined,
  INSTANCE_SET: undefined,
  CONFIG_IGNORED_ELEMENTS: undefined,
  OPTIONS_DATA_FN: undefined,
  OPTIONS_BEFORE_DESTROY: undefined,
  PRIVATE_APIS: undefined,
  OPTIONS_DATA_MERGE: undefined,
  PROPS_DEFAULT_THIS: undefined,
  TRANSITION_CLASSES: undefined,
  V_ON_KEYCODE_MODIFIER: undefined,
  WATCH_ARRAY: undefined,
  TRANSITION_GROUP_ROOT: undefined
});

registerNpsElement();
RegisterGlobalComponents.register();
