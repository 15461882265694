<template>
  <base-popover
    :id="id"
    ref="popper"
    :shown="shown"
    :disabled="disabled"
    :placement="placement"
    :distance="distance"
    :shift="shift"
    :boundary="boundary"
    :flip="flip"
    :auto-boundary-max-size="autoBoundaryMaxSize"
    :overflow-padding="overflowPadding"
    :prevent-overflow-on-cross-axis="preventOverflowOnCrossAxis"
    :content-class="computedContentClass"
    :will-change="willChange"
    :max-height="maxHeight"
    :strategy="strategy"
    :middlewares="middlewares"
    @update:shown="handleShown"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
  >
    <template #default="{ hide, show, toggle, disabled: isDisabled, shown: isShown }">
      <slot :hide="hide" :show="show" :toggle="toggle" :disabled="isDisabled" :shown="isShown" />
    </template>
    <template #content="{ hide, maxHeight }">
      <slot name="content" :hide="hide" :max-height="maxHeight" />
    </template>
  </base-popover>
</template>

<script>
import BasePopover from '@/components/ui/base-popover/base-popover.vue';

export default {
  name: 'BaseDropdown',
  components: {
    BasePopover
  },
  props: {
    id: BasePopover.props.id,
    shown: BasePopover.props.shown,
    disabled: BasePopover.props.disabled,
    placement: BasePopover.props.placement,
    distance: BasePopover.props.distance,
    shift: BasePopover.props.shift,
    boundary: BasePopover.props.boundary,
    flip: BasePopover.props.flip,
    autoBoundaryMaxSize: BasePopover.props.autoBoundaryMaxSize,
    overflowPadding: {
      type: BasePopover.props.overflowPadding.type,
      default: () => ({
        top: 55 // Шоб под шапку не заежало
      })
    },
    contentClass: BasePopover.props.contentClass,
    preventOverflowOnCrossAxis: BasePopover.props.preventOverflowOnCrossAxis,
    willChange: BasePopover.props.willChange,
    maxHeight: BasePopover.props.maxHeight,
    strategy: BasePopover.props.strategy,
    middlewares: BasePopover.props.middlewares
  },
  emits: ['change', 'shown', 'hidden', 'focus', 'blur'],
  computed: {
    computedContentClass() {
      const defaultContentClass = {
        [this.$style.dropdown]: true
      };
      if (Array.isArray(this.contentClass)) {
        return [defaultContentClass, ...this.contentClass];
      }
      if (typeof this.contentClass === 'object' && this.contentClass) {
        return {
          ...defaultContentClass,
          ...this.contentClass
        };
      }
      if (typeof this.contentClass === 'string') {
        return {
          ...defaultContentClass,
          [this.contentClass]: true
        };
      }
      return defaultContentClass;
    }
  },
  methods: {
    handleShown(shown) {
      this.$emit('change', shown);
    },
    show() {
      this.$refs.popper.show();
    },
    hide() {
      this.$refs.popper.hide();
    },
    toggle() {
      this.$refs.popper.toggle();
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables';

.dropdown {
  border-radius: 3px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
  background: @whiteColor;
  overflow: hidden auto;
  width: auto;
}
</style>

<i18n lang="json">{}</i18n>
