<template>
  <form-field
    :delimiter="options.breakAfter"
    :data-qa="options.name || id"
    :error-schema="errorSchema"
  >
    <template #default>
      <input type="hidden" :value="value" />
    </template>
  </form-field>
</template>

<script>
import FormField from './form-field.vue';

export default {
  name: 'FormFieldHidden',
  components: { FormField },
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    errorSchema: {
      type: Array,
      default: () => []
    },
    id: {
      type: [String, Number],
      required: true
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
