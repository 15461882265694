<template>
  <component
    :is="calculatedTag"
    v-bind="$attrs"
    type="button"
    :href="to"
    class="button"
    :class="{
      ['button_' + resultTheme]: resultTheme,
      ['button_' + size]: size,
      button_ghost: ghost,
      button_preload: loading
    }"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
import { KINDS, SIZES } from './constants';

export default {
  name: 'LinkButton',
  props: {
    kind: {
      type: String,
      default: '',
      validator(value) {
        return KINDS.hasOwnProperty(value);
      }
    },
    size: {
      type: String,
      default: '',
      validator(value) {
        return SIZES.hasOwnProperty(value);
      }
    },
    to: {
      type: String,
      required: true
    },
    ghost: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    resultTheme() {
      return KINDS[this.kind];
    },
    calculatedTag() {
      if (this.disabled) {
        return 'button';
      }
      return 'a';
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
