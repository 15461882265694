<template>
  <resource-action :promise="structurePromise" @success="handleStructure">
    <template #default="{ loading: structureLoading }">
      <cursor-tree-paginator
        :parent-child-map="parentChildMapWithInactive"
        :dictionary-id="dictionaryId"
        :collapsible="showInModal"
        :show-inactive="showInactive"
      >
        <template
          #default="{
            items,
            loading,
            loadingByLevel,
            canLoadMoreByLevel,
            cursorByLevel,
            next,
            query,
            setQuery,
            searchMode
          }"
        >
          <slot
            :items="items"
            :loading="(!items.length && loading) || structureLoading"
            :structure-loading="structureLoading"
            :structure-change-info="structureChangeInfo"
            :hierarchy-map="hierarchyMap"
            :hierarchy-map-with-inactive="hierarchyMapWithInactive"
            :show-inactive="showInactive"
            :disabled="structureLoading || !!structureChangeInfo"
            :search-mode="searchMode"
            :loading-by-level="loadingByLevel"
            :has-inactive="hasInactive"
            :change-inactive-toggle="handleShowInactive"
            :set-query="setQuery"
            :query="query"
            :can-load-more-by-level="canLoadMoreByLevel"
            :cursor-by-level="cursorByLevel"
            :next="next"
            :update-structure="updateStructure"
            :show-in-modal="showInModal"
          />
          <notifier-message v-on="pollerListeners" />
        </template>
      </cursor-tree-paginator>
    </template>
  </resource-action>
</template>

<script>
import { defineComponent } from 'vue';

import { MessageEvent } from '@/types/poller-message';
import { getMethodsById } from '@/api/dictionary-partial';
import { StorageHelper } from '@/util/storage-helper';
import { makeHierarchyMap } from '@/components/hierarchy/hierarchy-map';
import { normalizeValue } from '@/components/base-list/normalize-value';
import CursorTreePaginator from '@/components/partial-dictionary-autocomplete/cursor-tree-paginator.vue';
import ResourceAction from '@/components/resource-action/resource-action.vue';
import NotifierMessage from '@/components/hf/notifier-message/notifier-message.vue';
import PartialDropdownAutocomplete from './partial-dropdown-autocomplete.vue';

export default defineComponent({
  name: 'DictionaryResource',
  components: { NotifierMessage, CursorTreePaginator, ResourceAction },
  props: {
    multiple: PartialDropdownAutocomplete.props.multiple,
    value: PartialDropdownAutocomplete.props.value,
    dictionaryId: {
      type: String,
      required: true
    },
    autoUpdateOutdated: {
      type: Boolean,
      default: true
    }
  },
  emits: ['structure-info-changed', 'structure-changed'],
  data() {
    return {
      structurePromise: null,
      parentChildMapWithInactive: {},
      parentChildMap: {},
      meta: {},
      showInactive: false,
      structureChangeInfo: null
    };
  },
  computed: {
    storageKey() {
      return `dictionary-${this.dictionaryId}-is-active`;
    },
    hierarchyMap() {
      return makeHierarchyMap(this.parentChildMap);
    },
    hierarchyMapWithInactive() {
      return makeHierarchyMap(this.parentChildMapWithInactive);
    },
    partialMethods() {
      return getMethodsById(this.dictionaryId);
    },
    showInModal() {
      return this.meta.levels >= 3;
    },
    hasInactive() {
      return !!this.meta.has_inactive;
    },
    pollerListeners() {
      return {
        [MessageEvent.job]: this.handleDictionaryUpdated
      };
    }
  },
  watch: {
    dictionaryId: {
      immediate: true,
      handler(newDictionaryId, oldDictionaryId) {
        this.showInactive = StorageHelper.getBool(this.storageKey, false, { namespaced: true });
        if (newDictionaryId !== oldDictionaryId) {
          this.updateStructure();
        }
      }
    },
    structureChangeInfo(info) {
      this.$emit('structure-info-changed', info);
    },
    structureShouldUpdate(flag) {
      if (flag) {
        this.updateStructure();
      }
    }
  },
  methods: {
    updateStructure() {
      this.structurePromise = Promise.all([
        this.partialMethods.getStructure(),
        this.partialMethods.getStructure({ include_inactive: false })
      ]);
      return this.structurePromise;
    },
    handleStructure([{ meta, items }, { items: structureWithoutInactive }]) {
      this.parentChildMapWithInactive = Object.freeze(items);
      this.parentChildMap = Object.freeze(structureWithoutInactive);
      this.meta = meta || {};

      const normalizedValue = normalizeValue(this.value, { multiple: this.multiple });
      let forceShowInactive = false;
      if (this.multiple && normalizedValue.length) {
        forceShowInactive = !normalizedValue.some((id) => !this.isInactiveId(id));
      } else {
        forceShowInactive = this.isInactiveId(normalizedValue);
      }
      if (forceShowInactive) {
        this.showInactive = forceShowInactive;
      }

      this.structureChangeInfo = null;
      this.$emit('structure-changed');
    },
    isInactiveId(id) {
      return id in this.parentChildMapWithInactive && !(id in this.parentChildMap);
    },
    handleShowInactive(flag) {
      this.showInactive = flag;
      StorageHelper.set(this.storageKey, flag, { namespaced: true });
    },
    handleDictionaryUpdated(_, message) {
      const messageInfo = this.partialMethods.checkMessage(message);
      if (!messageInfo.matched) {
        return;
      }
      if (messageInfo.type === MessageEvent.dictionaryUpdated) {
        this.structureChangeInfo = messageInfo.data;
        this.autoUpdateOutdated && this.updateStructure();
      }
    }
  }
});
</script>

<i18n lang="json">{}</i18n>
