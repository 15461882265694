<script>
import { ref, watch, h } from 'vue';
import { trlMessage } from '@/services/i18n';

import BaseDropdown from '../dropdown-next.vue';
import BaseInput from '../input/input.vue';
import { useSelectionHook } from './use-selection-hook';
import { useAutocompleteHook } from './use-autocomplete-hook';
import { useSuggestableHook } from './use-suggestable-hook';

import AutocompleteMenu from './autocomplete-menu.vue';
import AutocompleteGroupItem from './items/group.vue';
import AutocompleteEmptyItem from './items/empty.vue';
import AutocompleteItem from './items/item.vue';
import AutocompleteReset from './autocomplete-reset.vue';
import { makeNewItem } from './helpers';
import { SlotChecker } from '@/util/slot-checker';

const noop = () => {};

// 0_o) странно, чо-то не могу воткнуть это как дефолтное значение пропы в компоненте
const DEFAULT_PLACEHOLDER = trlMessage('enter.search.keyword');

export default {
  name: 'VAutocomplete',
  emptyItem: {},
  components: {
    AutocompleteMenu,
    AutocompleteGroupItem,
    AutocompleteEmptyItem,
    AutocompleteItem,
    BaseInput
  },
  props: {
    invalid: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    selection: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    menuProps: {
      type: Object,
      default: () => ({})
    },
    dropdownProps: {
      type: Object,
      default: undefined
    },
    searchEngineInstance: {
      type: Object,
      default: undefined
    },
    includeGroupItem: {
      type: Boolean,
      default: true
    },
    includeEmptyGroup: {
      type: Boolean,
      default: false
    },
    showResetButton: {
      type: Boolean,
      default: true
    },
    allowNewItems: {
      type: Boolean,
      default: true
    },
    focusSearchInput: {
      type: Boolean,
      default: false
    },
    activeFirstItem: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggle', 'change', 'inputValue'],
  setup(props, { emit }) {
    const dropdown = ref(null);
    const input = ref(null);
    const menu = ref(null);
    const { selectionValue, setSelectionValue, toggleCheck } = useSelectionHook({
      multiple: props.multiple
    });

    const toggleCheckAndEmitEvent = (item) => {
      const isDropdownOpened = dropdown.value?.show;
      if (!props.multiple && isDropdownOpened) {
        // если разрешен выбор только одного элемента и у нас открыт дропдаун,
        // то при выборе элемента закрываем д.д.
        dropdown.value?.handleToggle();
      }

      if (typeof item === 'string' && !props.allowNewItems) {
        return;
      }

      const valueItem = typeof item === 'string' ? makeNewItem(item) : item;
      const value = toggleCheck(valueItem);
      emit('toggle', valueItem);
      emit('change', value);
    };

    const { filterValue, setFilterValue, result } = useAutocompleteHook({
      searchEngineInstance: props.searchEngineInstance
    });

    const onCommit = ({ commitActiveValue = true } = {}) => {
      const isDropdownOpened = dropdown.value?.show;
      const commitValue =
        commitActiveValue &&
        (isDropdownOpened === undefined || isDropdownOpened) &&
        activeItem.value
          ? activeItem.value
          : filterValue.value;

      if (commitValue === '') {
        return;
      }
      toggleCheckAndEmitEvent(commitValue);
    };

    const { keyDownEventHandler, items, activeItem, setActiveItem } = useSuggestableHook(
      {
        result,
        includeGroupItem: props.includeGroupItem,
        includeEmptyGroup: props.includeEmptyGroup,
        activeFirstItem: props.activeFirstItem,
        menu
      },
      { onCommit }
    );

    watch(
      () => props.selection,
      (currSelection) => {
        setSelectionValue(currSelection ?? []);
      },
      { immediate: true }
    );

    watch(
      () => props.value,
      (currValue) => {
        setFilterValue(currValue);
      },
      { immediate: true }
    );

    return {
      dropdown,
      input,
      menu,
      filterValue, // autocomplete filter value (string)
      setFilterValue,
      items,
      activeItem,
      setActiveItem,
      setHoverActiveItem: setActiveItem,
      keyDownEventHandler,
      selectionValue, // selection value (array or single-item array)
      setSelectionValue,
      toggleCheck: toggleCheckAndEmitEvent,
      onCommit,
      focus: () => input.value?.$refs.input.focus()
    };
  },
  render() {
    const createAutocompleteMenuItem = (props) =>
      h(AutocompleteItem, {
        item: props.item,
        isActive: props.isActive,
        isChecked: props.isChecked,
        onMousemove: () => this.setHoverActiveItem(props.item),
        onClick: () => this.toggleCheck(props.item)
      });

    const createAutocompleteMenuGroupItem = (props) =>
      h(AutocompleteGroupItem, {
        item: props.item
      });

    const createAutocompleteMenuEmptyItem = () => h(AutocompleteEmptyItem, {});

    const createAutocompleteMenuEl = (props = {}) =>
      this.items
        ? h(
            AutocompleteMenu,
            {
              ref: 'menu',
              tabindex: 0,
              items: this.items,
              checkedItems: this.selectionValue,
              activeItem: this.activeItem,
              ...this.menuProps
            },
            {
              item: SlotChecker.isSlotNotEmpty(this.$slots.item, { ...props })
                ? (slotData) =>
                    this.$slots.item({
                      ...slotData,
                      ...props
                    })
                : createAutocompleteMenuItem,
              groupItem: SlotChecker.isSlotNotEmpty(this.$slots.groupItem)
                ? this.$slots.groupItem()
                : createAutocompleteMenuGroupItem,
              emptyItem: SlotChecker.isSlotNotEmpty(this.$slots.emptyItem)
                ? this.$slots.emptyItem()
                : createAutocompleteMenuEmptyItem
            }
          )
        : null;

    const inputProps = {
      invalid: this.invalid,
      value: this.filterValue,
      id: this.id,
      focus: this.focusSearchInput,
      placeholder: this.placeholder || DEFAULT_PLACEHOLDER,
      autocomplete: 'off',
      type: 'search',
      name: this.name,
      // events
      onInputValue: (value) => {
        this.setFilterValue(value);
        this.$emit('inputValue', value);
      },
      onKeydown: this.keyDownEventHandler,
      onBlur: (event) => {
        // Если фокус перешёл на пункт меню, то игнорируем это событие,
        // чтобы следом вызвалось событие click/enter
        if (this.menu?.$el === event?.relatedTarget) {
          return;
        }
        this.onCommit({ commitActiveValue: false });
      }
    };

    const resetEl =
      this.showResetButton &&
      h(AutocompleteReset, {
        onReset: () => {
          this.setFilterValue('');
          this.setSelectionValue([]);
          this.$emit('change', []);
        }
      });

    if (this.dropdownProps) {
      return h(
        BaseDropdown,
        {
          ref: 'dropdown',
          ...this.dropdownProps
        },
        {
          default: (props) =>
            h(BaseInput, {
              ref: 'input',
              ...inputProps,
              class: this.$style.searchInput,
              onClick: props.onToggle,
              onInput: (event) => {
                // TODO: fix it
                // eslint-disable-next-line no-unused-expressions
                props.show ? noop : props.onToggle(event);
              }
            }),
          menu: (props) => [
            resetEl,
            this.$slots.menuPrepend?.({ items: this.items }),
            createAutocompleteMenuEl({
              ...props,
              setActiveItem: this.setActiveItem,
              setHoverActiveItem: this.setHoverActiveItem,
              toggleCheck: this.toggleCheck
            })
          ]
        }
      );
    }

    const menuTopDelimiterEl = h('div', { class: this.$style.menuTopDelimiter });
    return h('div', {}, [
      h(
        'div',
        {
          class: this.$style.inputPaddingsWrapper
        },
        [
          h(BaseInput, {
            ref: 'input',
            ...inputProps,
            class: this.$style.searchInput
          })
        ]
      ),
      resetEl,
      menuTopDelimiterEl,
      createAutocompleteMenuEl({
        setActiveItem: this.setActiveItem,
        setHoverActiveItem: this.setHoverActiveItem,
        toggleCheck: this.toggleCheck
      })
    ]);
  }
};
</script>

<style lang="less" module>
@import '../../../less/common/variables.less';

.menuTopDelimiter {
  border-top: 1px solid @borderColor;
}

.inputPaddingsWrapper {
  box-sizing: border-box;
  padding: 0 10px 10px 10px;
}

input[type='search'].searchInput {
  box-sizing: border-box;
}
</style>

<i18n lang="json">{}</i18n>
