<template>
  <div :class="$style.item">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HeaderItem'
};
</script>

<style module lang="less">
@import '~@less/common/variables';

.item:not(:empty) {
  flex: 0 0 auto;
  min-width: 52px;
  transition: background-color 0.1s linear;
}
.item:hover {
  background: black;
}
.item > a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>

<i18n lang="json">{}</i18n>
