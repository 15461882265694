<template>
  <form-field
    :label="label"
    :description="description"
    :delimiter="options.breakAfter"
    :data-qa="options.name || $attrs.id"
    :error-schema="errorSchema"
    :files="files"
    error-placement="top"
  >
    <template #default="{ id }">
      <file-attachment-list
        :hide-add-button="!canAttachFile"
        :files="attachedFiles"
        :base-url="$uploadBaseUrl"
        :multiple-select="false"
        :show-loading-files="false"
        :class="$style.fileAttachmentList"
        @change="attachedFiles = $event"
      >
        <template #button="{ open, loading }">
          <base-button
            v-bind="fieldProps"
            :id="id"
            kind="light"
            :name="id"
            :loading="loading"
            :class="$style.uploadButton"
            @click="open"
          >
            {{ caption }}
          </base-button>
        </template>
      </file-attachment-list>
    </template>
  </form-field>
</template>

<script>
import { trlMessage } from '@/services/i18n';
import FileAttachmentList from '@/components/ui/file-attachment-list/file-attachment-list';
import BaseButton from '@/components/button/button';
import FormField from '@/components/vjsf/widgets/form-field';
import { getFileInfos } from '@/components/hf/survey-form/helpers/get-file-infos';

export default {
  name: 'FormFieldAttachFile',
  components: { FileAttachmentList, BaseButton, FormField },
  inject: {
    setFormDataByPointer: { default: undefined },
    $getAttachedFiles: { default: () => [] },
    $uploadBaseUrl: { default: undefined }
  },
  inheritAttrs: false,
  props: {
    formData: {
      type: Array,
      default: () => []
    },
    uiSchema: Object,
    schema: {
      type: Object,
      required: true
    },
    errorSchema: {
      type: Array,
      default: () => []
    },
    pointer: {
      type: String,
      required: true
    }
  },
  data() {
    const alreadyAttachedFiles =
      typeof this.$getAttachedFiles === 'function'
        ? this.$getAttachedFiles()
        : this.$getAttachedFiles;
    return {
      attachedFiles: getFileInfos(this.formData, alreadyAttachedFiles)
    };
  },
  computed: {
    label() {
      return this.schema.title;
    },
    description() {
      return this.uiSchema['ui:description'];
    },
    options() {
      return this.uiSchema['ui:options'] || {};
    },
    files() {
      return this.schema.metadata?.files ?? [];
    },
    fieldProps() {
      return {
        ...this.$attrs,
        ...this.options
      };
    },
    canAttachFile() {
      return this.attachedFiles.length < 10;
    },
    caption() {
      return this.attachedFiles.filter(({ id }) => id).length
        ? trlMessage('settings.survey.widget.attach_file.attach_response.more')
        : trlMessage('settings.survey.widget.attach_file.attach_response');
    }
  },
  watch: {
    attachedFiles: {
      handler(files) {
        const ids = files.map(({ id }) => id).filter(Boolean);
        this.setFormDataByPointer(this.pointer, ids);
      }
    }
  }
};
</script>

<style lang="less" module>
.uploadButton,
.fileAttachmentList {
  position: relative;
}

.fileAttachmentList {
  left: -8px;
}

.uploadButton {
  left: 8px;
}
</style>

<i18n lang="json">{}</i18n>
