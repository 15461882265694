<template>
  <component :is="tag" :class="$style.layout" :style="styles">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'FieldsLayout',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    gap: {
      type: String,
      default: '10px'
    },
    gapBefore: {
      type: String,
      default: '0px'
    },
    gapAfter: {
      type: String,
      default: '10px'
    }
  },
  computed: {
    styles() {
      return {
        rowGap: this.gap,
        marginTop: this.gapBefore,
        marginBottom: this.gapAfter
      };
    }
  }
};
</script>

<style module>
.layout {
  display: flex;
  flex-direction: column;
}
</style>

<i18n lang="json">{}</i18n>
