<template>
  <div>
    <slot name="prepend">
      <span v-if="title" :class="$style.title">{{ title }}</span>
    </slot>
    <slot v-for="(item, index) in valueState" :key="index" name="item" :item="item">
      <reminder-item
        v-model="valueState[index]"
        :focus="lastAdded === valueState[index]"
        :class="$style.item"
        @remove="handleRemove(index)"
      />
    </slot>
    <div :class="$style.footer">
      <button-input @click="handleAdd">{{ $trlMessage('button.add') }}</button-input>
    </div>
  </div>
</template>

<script>
import { watch, ref } from 'vue';

import ButtonInput from '../button-input/button-input.vue';
import ReminderItem from './reminder-item.vue';
import { DEFAULT_UNIT } from './units';

export default {
  name: 'ReminderList',
  components: {
    ReminderItem,
    ButtonInput
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const valueState = ref(props.value ?? []);
    watch(
      () => props.value,
      (value) => {
        valueState.value = value;
      }
    );
    watch(valueState, (value) => emit('change', value));

    const lastAdded = ref(undefined);

    return {
      valueState,
      lastAdded,
      handleAdd: () => {
        const item = { id: DEFAULT_UNIT };
        lastAdded.value = item;
        valueState.value.push(item);
      },
      handleRemove: (index) => {
        valueState.value.splice(index, 1);
      }
    };
  }
};
</script>

<style lang="less" module>
@removeBtnWidth: 30px;

.title {
  composes: form-group__label from global;
}

.item {
  margin-bottom: 10px;
}

.footer {
  padding-right: @removeBtnWidth;
}
</style>

<i18n lang="json">{}</i18n>
