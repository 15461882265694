<template>
  <div :class="$style.summary">
    <template v-if="vacancyRequest.taken_by">
      <span data-qa="change-at" :class="$style.changedAt">
        {{ changedDateStr }}
      </span>
      <span data-qa="taken-by" :class="$style.takenBy">
        {{ vacancyRequest.taken_by.name || vacancyRequest.taken_by.email }}
      </span>
    </template>
    <template v-else>
      <span data-qa="received" :class="$style.received">
        {{ $trlMessage('vacancy_request.popup.request_received') }}
      </span>
      <span data-qa="hr-department" :class="$style.hrDepartment">
        {{ $trlMessage('vacancy_request.popup.hr_department') }}
      </span>
    </template>
  </div>
</template>

<script>
import { DateTimeHelper } from '@/util/date-time-helper';

export default {
  name: 'ApprovalSummary',
  props: {
    vacancyRequest: {
      type: Object,
      required: true
    }
  },
  computed: {
    changedDateStr() {
      const date = DateTimeHelper.toShortFormat({ date: this.vacancyRequest.changed });
      return `${this.$trlMessage('vacancy_request.popup.taken')}, ${date}`;
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.summary {
  composes: form-group from global;
  composes: form-group_actions from global;
  color: @vantaBlackColor;
}

.received,
.hrDepartment,
.changedAt,
.takenBy {
  display: block;
}

.received,
.changedAt {
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

.hrDepartment,
.takenBy {
  font-weight: bold;
}
</style>

<i18n lang="json">{}</i18n>
