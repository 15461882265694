<template>
  <component :is="tag" :class="$style.container">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'TwoColumns',
  props: {
    tag: {
      type: String,
      default: 'div'
    }
  }
};
</script>

<style module>
.container {
  flex: 0 0 auto;
  display: flex;
  width: 100%;
}
.container > *:first-child {
  flex: 0 0 calc(1px * var(--sidebar-width, 250));
  width: calc(1px * var(--sidebar-width, 250));
  min-width: 250px;
  max-width: 400px;
}
.container > *:last-child {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
</style>

<i18n lang="json">{}</i18n>
