<template>
  <base-dropdown :content-class="$style.dropdownContent" placement="bottom-end">
    <template #default="{ toggle }">
      <button
        type="button"
        :disabled="!editor.isActive('table')"
        :class="$style.arrowBtn"
        @click="toggle"
      >
        <svg width="16" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.707 16l4-4 .707.707-4.707 4.707L3 12.707 3.707 12l4 4z" fill="currentColor" />
        </svg>
      </button>
    </template>
    <template #content="{ hide }">
      <item-button
        name="table-del"
        :class="$style.btn"
        @click="
          hide();
          deleteTable();
        "
      />

      <item-button
        name="table-cell-border"
        :class="$style.btn"
        :active="hasBorder"
        @click="
          hide();
          toggleCellBorder();
        "
      />

      <item-button
        name="table-col-after"
        :class="$style.btn"
        @click="
          hide();
          addColumnAfter();
        "
      />
      <item-button
        name="table-col-before"
        :class="$style.btn"
        @click="
          hide();
          addColumnBefore();
        "
      />
      <item-button
        name="table-del-col"
        :class="$style.btn"
        @click="
          hide();
          deleteColumn();
        "
      />
      <item-button
        name="table-row-before"
        :class="$style.btn"
        @click="
          hide();
          addRowBefore();
        "
      />
      <item-button
        name="table-row-after"
        :class="$style.btn"
        @click="
          hide();
          addRowAfter();
        "
      />
      <item-button
        name="table-del-row"
        :class="$style.btn"
        @click="
          hide();
          deleteRow();
        "
      />
      <item-button
        name="table-join-cells"
        :class="$style.btn"
        @click="
          hide();
          mergeOrSplit();
        "
      />
    </template>
  </base-dropdown>
</template>

<script>
import BaseDropdown from '@/components/ui/base-dropdown/base-dropdown';
import ItemButton from './item-button.vue';

export default {
  name: 'TableItems',
  components: {
    BaseDropdown,
    ItemButton
  },
  props: {
    editor: {
      type: Object,
      default: null
    }
  },
  emits: ['merge'],
  computed: {
    hasBorder() {
      return this.editor.isActive('tableCell', { border: true });
    }
  },
  methods: {
    deleteTable() {
      this.editor.chain().focus().deleteTable().run();
    },
    toggleCellBorder() {
      this.editor.chain().focus().setCellAttribute('border', !this.hasBorder).run();
    },
    addColumnAfter() {
      this.editor.chain().focus().addColumnAfter().run();
    },
    addColumnBefore() {
      this.editor.chain().focus().addColumnBefore().run();
    },
    deleteColumn() {
      this.editor.chain().focus().deleteColumn().run();
    },
    addRowBefore() {
      this.editor.chain().focus().addRowBefore().run();
    },
    addRowAfter() {
      this.editor.chain().focus().addRowAfter().run();
    },
    deleteRow() {
      this.editor.chain().focus().deleteRow().run();
    },
    mergeOrSplit() {
      this.editor.chain().focus().mergeOrSplit().run();
      this.$emit('merge');
    }
  }
};
</script>

<style module>
.arrowBtn {
  @mixin reset-button-styles;
  border-radius: 2px;
  width: 16px;
  height: 32px;

  &:disabled {
    color: #ebebeb;
  }

  &:not(:disabled) {
    background-color: #444444;
    color: #ffffff;
  }

  &:not(:disabled):hover {
    background-color: #151319;
  }
}

.dropdownContent {
  overflow: visible;
  display: flex;
  white-space: nowrap;
  line-height: 0;
  padding: 4px;
}

.btn:not(:last-child) {
  margin-right: 7px;
}
</style>

<i18n lang="json">{}</i18n>
