import { Ref, UnwrapRef, onBeforeUnmount, ref } from 'vue';
import { featureToggle, FeatureTypes } from './feature-toggle';

export function useFeatureAvailability(featureName: FeatureTypes): Ref<UnwrapRef<boolean>> {
  const enabled = ref(featureToggle.isEnabled(featureName));

  function handleEnable(name: string) {
    if (name === featureName) {
      enabled.value = true;
    }
  }

  function handleDisable(name: string) {
    if (name === featureName) {
      enabled.value = false;
    }
  }

  featureToggle.emitter.on('enable', handleEnable);
  featureToggle.emitter.on('disable', handleDisable);

  onBeforeUnmount(() => {
    featureToggle.emitter.off('enable', handleEnable);
    featureToggle.emitter.off('disable', handleDisable);
  });

  return enabled;
}
