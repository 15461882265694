<template>
  <div v-if="canAddApplicants || isWatcher" :class="classes">
    <h3 v-if="canAddApplicants">{{ $trlMessage('left_menu.base') }}</h3>
    <ul>
      <li :class="active ? $options.style.activeItem : $options.style.item">
        <a :href="href" :class="$options.style.link" data-qa="all-applicants">
          <span :class="$options.style.linkText">{{ linkText }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { userPermissions } from '@/services/config/user-permissions';
import { VirtualStatusId } from '@/services/status/status';
import style from './sidebar.module.less';

export default {
  style,
  name: 'SidebarMenu',
  props: {
    active: Boolean,
    linkPresets: {
      type: Object,
      required: true
    }
  },
  computed: {
    isWatcher: () => userPermissions.isWatcher,
    canAddApplicants: () => userPermissions.get('can_add_applicants'),
    classes() {
      return {
        [style.section]: this.canAddApplicants,
        [style.watcherSection]: this.isWatcher
      };
    },
    href() {
      const linkPreset = this.linkPresets['all'] || {};
      const { status, applicant } = linkPreset;
      let baseHref = '#/applicants';
      if (!status || Object.values(VirtualStatusId).includes(status)) {
        return baseHref;
      }
      if (applicant) {
        return `${baseHref}/filter/${status}/id/${applicant}`;
      }
      return `${baseHref}/filter/${status}`;
    },
    linkText() {
      return this.canAddApplicants
        ? this.$trlMessage('left_menu.all_applicants')
        : this.$trlMessage('left_menu.dashboard');
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
