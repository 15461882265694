<template>
  <div :class="className">
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'LayoutRow',
  props: {
    borderBefore: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    className() {
      return {
        [this.$style.row]: true,
        [this.$style.borderBefore]: this.borderBefore
      };
    }
  }
};
</script>

<style lang="less" module>
@margin: 35px;

.row {
  position: relative;
}

.row > *:first-child {
  margin-inline-start: @margin;
}

.row > *:last-child {
  margin-inline-end: @margin;
}

.row.borderBefore::before {
  position: absolute;
  content: '';
  inset: 0 @margin auto @margin;
  height: 1px;
  background-color: #eaeaea;
}
</style>

<i18n lang="json">{}</i18n>
