export const KINDS = {
  danger: 'pink',
  warn: 'warn',
  success: 'green',
  light: 'white',
  lightGhost: 'white-ghost',
  dark: 'dark',
  black: 'black',
  purple: 'purple',
  '': ''
};

export const SIZES = {
  big: 'big',
  giant: 'giant',
  '': ''
};
