const mediaSizes = {
  screenXs: 480, // Extra small screen / phone
  screenSm: 768, // Small screen / tablet
  screenMd: 992, // Medium screen / desktop
  screenLg: 1200 // Large screen / wide desktop
};

const widths = {
  nano: '55px',
  micro: '70px',
  smaller: '170px',
  small: '182px',
  normal: '200px',
  medium: '250px',
  large: '300px',
  larger: '350px',
  big: '400px',
  bigger: '500px',
  giant: '550px',
  titan: '600px',
  full: '100%'
};

const variables = {
  /* Размеры */
  ...Object.fromEntries(Object.entries(widths).map(([key, value]) => [`${key}Width`, value])),

  // Наборный текст
  baseLineHeight: '22px',
  baseFontSize: '16px',
  // Второстепенный текст
  secondaryLineHeight: '18px',
  secondaryFontSize: '14px',
  // Layout
  layoutFilterHeight: '45px',
  // Header
  headerLineHeight: '30px',
  headerFontSize: '22px',
  fontFamilyGraphik: 'Graphik, Arial, Tahoma, Verdana, sans-serif',

  /* Цвета */

  darkViolet: '#38343f',
  textColor: '#151319',
  // есть еще серый в стилях #ccc
  lightTextColor: '#8f999c', // @TODO: заменить например на #6C767A, у #8f999c недостаточный контраст
  errorColor: '#ff0000',
  headerColor: '#151319',
  grayColor: '#333',
  grayMid: '#ccc',
  grayLight: '#ebebeb',
  whiteColor: '#ffffff',
  vantaBlackColor: '#000000',
  blueColor: '#22bbd2',
  greenColor: '#61c497',
  yellowColor: '#ec7e00',
  lightGreenColor: '#63af00',
  focusColor: '#00a2c4',
  violetColor: '#410097',
  linkColor: '#50808a',
  redColor: '#db525e',
  nyRedColor: '#e80027',
  march8Color: '#ff0061',
  borderColor: '#ebebeb',
  btnGreen: '#7ac016',
  delimiterColor: '#E0E0E0',

  /* Media Queries */

  // [breakpoint]: [size] → [breakpoint]: [size]px
  ...Object.fromEntries(Object.entries(mediaSizes).map(([key, value]) => [key, `${value}px`])),
  // So media queries don't overlap when required, provide a maximum
  screenXsMax: `${mediaSizes.screenSm - 1}px`,
  screenSmMax: `${mediaSizes.screenMd - 1}px`,
  screenMdMax: `${mediaSizes.screenLg - 1}px`
};

const sidebarTextColor = 'var(--sidebar-text-color, #929097)';
const sidebarTextColorHover = `var(--sidebar-text-color-hover, ${variables.whiteColor})`;

module.exports = {
  ...variables,
  // Theme colors
  sidebarBackground: `var(--sidebar-background, ${variables.darkViolet})`,
  sidebarTextColor,
  sidebarTextColorHover,
  sidebarTextColorActive: `var(--sidebar-text-color-active, ${sidebarTextColorHover})`,
  sidebarTextBackgroundActive: 'var(--sidebar-text-background-active, transparent)',
  sidebarHeaderTextColor: `var(--sidebar-header-text-color, ${sidebarTextColor})`,
  sidebarDividerColor: 'var(--sidebar-divider-color, #47434e)',
  sidebarVerticalDividerColor: `var(--sidebar-vertical-divider-color, ${variables.darkViolet})`,
  plusActionBackground: `var(--plus-button-background, ${variables.blueColor})`,
  plusActionColor: `var(--plus-button-color, ${variables.whiteColor})`
};
