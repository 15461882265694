<template>
  <div :class="$style.wrapper">
    <skeleton zone="sidebar-general" width="100%" height="300" dark>
      <sidebar-menu :active="isActiveApplicantLink" :link-presets="linkPresets" />

      <vacancy-request-list :collapsed="requestsCollapsed" @toggle="toggleRequests" />

      <div data-qa="vacancy-list">
        <div :class="$options.style.section">
          <div :class="$options.style.dropdown">
            <recruiter-select
              v-if="selectedMember"
              :disabled="isWatcher"
              :dropdown-props="{ size: 'big' }"
              :value="selectedMember"
              only-leaf
              @input="handleChangeMember"
            >
              <template #toggle="{ toggle, innerValue }">
                <button
                  type="button"
                  :title="innerValue && innerValue.name"
                  :class="$style.select"
                  :disabled="isWatcher"
                  data-qa="sidebar-recruiters-select"
                  @click.stop="toggle"
                >
                  <template v-if="!innerValue || innerValue.isMe">
                    {{ $trlMessage('vacancy.count.mine') }}
                  </template>
                  <template v-else>
                    {{ innerValue.name }}
                  </template>
                </button>
              </template>
            </recruiter-select>
          </div>

          <svg role="img" class="skeleton sidebar-vacancies" width="169" height="123">
            <rect
              width="100%"
              height="100%"
              x="0"
              y="0"
              clip-path="url(#skeleton-sidebar-vacancies)"
              fill="url(#skeleton-shadow)"
            />

            <defs>
              <clipPath id="skeleton-sidebar-vacancies">
                <skeleton-bar x="0" y="3" width="127" height="10" />
                <skeleton-bar x="0" y="21" width="169" height="10" />

                <skeleton-bar x="0" y="49" width="106" height="10" />
                <skeleton-bar x="0" y="67" width="148" height="10" />

                <skeleton-bar x="0" y="95" width="106" height="10" />
                <skeleton-bar x="0" y="113" width="148" height="10" />
              </clipPath>
            </defs>
          </svg>

          <skeleton
            zone="sidebar-vacancies"
            dark
            :loading="showLoading"
            :instant-loader-showing="true"
          >
            <div v-if="isEmptyVacanciesList" :class="$style.notFound">
              {{ $trlMessage('sidebar.not-found') }}
            </div>

            <vacancies-tree
              v-else
              :tree="filteredVacancies"
              :selected-vacancy-id="selectedVacancy"
              :permissions="vacancyPermissions"
              :link-presets="linkPresets"
              :is-watcher="isWatcher"
            />

            <div v-if="isClosedVacanciesExists" :class="$style.closedLinkWrapper">
              <router-link
                :to="linkToClosedVacancies"
                :class="$options.style.arrowLink"
                data-qa="closed-vacancies"
              >
                <span :class="[$options.style.linkText, $options.style.arrowLinkText]">
                  {{ $trlMessage('vacancies.closed') }}
                  <base-icon type="arrow-right" />
                </span>
              </router-link>
            </div>
          </skeleton>

          <notifier-message v-on="pollerListeners" />
        </div>
      </div>
    </skeleton>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { userPermissions } from '@/services/config/user-permissions';
import { VacancyAPI } from '@/api/vacancy';
import { AnalyticsAPI } from '@/api/analytics';
import { VacancyState } from '@/types/vacancy-state';
import { MessageEvent } from '@/types/poller-message';
import RecruiterSelect from '@/components/recruiters-select/recruiters-select.vue';
import NotifierMessage from '@/components/hf/notifier-message/notifier-message';
import BaseIcon from '@/components/icon';
import style from './sidebar.module.less';
import SidebarMenu from './sidebar-menu';
import VacancyRequestList from './vacancy-request-list/vacancy-request-list';
import VacanciesTree from './vacancies-tree';
import { Skeleton, SkeletonBar } from '@/components/skeleton';
import { SkeletonHelper, SkeletonZones } from '@/util/skeleton-helper';
import { SEARCH_VACANCIES_ROUTE_NAME } from '@/modules/main-screen/constants';

export default {
  style,
  name: 'DefaultContent',
  components: {
    SidebarMenu,
    VacancyRequestList,
    RecruiterSelect,
    NotifierMessage,
    BaseIcon,
    VacanciesTree,
    Skeleton,
    SkeletonBar
  },
  data() {
    return {
      isClosedVacanciesExists: false,
      linkPresets: {}
    };
  },
  computed: {
    vacancyPermissions: () => userPermissions.vacancyPermissions,
    isWatcher: () => userPermissions.isWatcher,
    ...mapGetters('sidebar', [
      'selectedMember',
      'requestsCollapsed',
      'filteredVacancies',
      'showLoading'
    ]),
    selectedVacancy() {
      const { vacancyId } = this.$route.params;
      return Number(vacancyId) || vacancyId;
    },
    isEmptyVacanciesList() {
      const { parents } = this.filteredVacancies;
      return parents.length === 0;
    },
    isActiveApplicantLink() {
      return this.$route.name?.startsWith('applicants');
    },
    linkToClosedVacancies() {
      return {
        name: SEARCH_VACANCIES_ROUTE_NAME,
        query: {
          vacancyFilters: {
            state: 'CLOSED'
          }
        }
      };
      // '#/search/vacancies?only_current_status=1&type=vacancies&t=0&vacancyFilters%5Bstate%5D=CLOSED';
    },
    pollerListeners() {
      return {
        [MessageEvent.vacancyAdd]: this.checkClosedVacancies,
        [MessageEvent.vacancyRemove]: this.checkClosedVacancies,
        [MessageEvent.vacancyEdit]: this.checkClosedVacancies
      };
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler({ params: { vacancyId, applicantId, filter } }) {
        if (!vacancyId) {
          // "Все кандидаты" тоже учитываются, но там нет id вакансии
          vacancyId = 'all';
        }
        if (!this.linkPresets[vacancyId] || !applicantId) {
          this.linkPresets[vacancyId] = {};
        }
        if (filter) {
          this.linkPresets[vacancyId].status = filter;
        }
        if (applicantId) {
          this.linkPresets[vacancyId].applicant = applicantId;
        }
      }
    }
  },
  created() {
    this.changeMember({ id: this.selectedMember });
    SkeletonHelper.patchPromise(SkeletonZones.SIDEBAR_VACANCIES, this.checkClosedVacancies());
  },
  methods: {
    ...mapMutations('sidebar', ['toggleRequests']),
    ...mapActions('sidebar', ['changeMember']),
    handleChangeMember(id) {
      if (this.selectedMember !== id) {
        AnalyticsAPI.send('sidebar_change_member');
      }
      this.changeMember({ id });
    },
    checkClosedVacancies() {
      return VacancyAPI.searchListByFilter({
        count: 1,
        state: [VacancyState.closed],
        extended: true
      }).then(({ items }) => {
        this.isClosedVacanciesExists = !!items.length;
      });
    }
  }
};
</script>

<style module>
.wrapper {
  margin-top: 20px;
}

.notFound {
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--font-regular-weight, normal);
  color: $sidebarTextColor;
  opacity: 0.4;
}

.select {
  display: block;
  font-size: 11px;
  font-weight: bold;
  color: $sidebarHeaderTextColor;
  text-transform: uppercase;
  line-height: 13px;
  padding: 5px 15px 5px 0;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: none;
  border-bottom: 3px solid $sidebarHeaderTextColor;
  border-radius: 0;
  appearance: none;
  background-color: transparent;
  transition:
    color 300ms ease,
    border-color 300ms ease;
  text-align: left;
  position: relative;
  @mixin ellipsis;

  &::after {
    content: ' ';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: currentColor transparent transparent transparent;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  &:not(:disabled):hover {
    color: $sidebarTextColorHover;
    border-bottom-color: $sidebarTextColorHover;
  }

  &:disabled {
    padding-right: 0;
  }
  &:disabled::after {
    display: none;
  }
}

.closedLinkWrapper {
  padding-top: 26px;
}
</style>

<i18n lang="json">{}</i18n>
