<template>
  <div :class="className">
    <button
      v-if="hasClickListener"
      type="button"
      :disabled="disabled"
      :class="$style.title"
      @click="$emit('click', $event)"
    >
      <base-icon v-if="icon" :type="icon" :class="$style.icon" />
      <span ref="title" :class="$style.titleText">
        <slot name="title" :title="title">
          <span v-html="title"></span>
        </slot>
      </span>
    </button>
    <span v-else :class="$style.title">
      <base-icon v-if="icon" :type="icon" :class="$style.icon" />
      <span ref="title" :class="$style.titleText">
        <slot name="title" :title="title">
          <span v-html="title"></span>
        </slot>
      </span>
    </span>

    <button
      v-if="canRemove"
      type="button"
      data-qa="remove-button"
      :class="$style.removeButton"
      @click="$emit('remove', $event)"
    >
      <base-icon :class="$style.removeIcon" type="remove" />
    </button>
  </div>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'BaseAttachment',
  components: {
    BaseIcon
  },
  props: {
    icon: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: ''
    },
    canRemove: {
      type: Boolean,
      default: true
    },
    hasError: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledLike: {
      type: Boolean,
      default: false
    },
    updateOnMount: {
      type: Boolean,
      default: false
    },
    // ассоциированное с прикреплением значение,
    // не выводится, но используется для анимации при его изменении
    value: {
      type: [Object, File],
      default: undefined
    }
  },
  emits: ['click', 'remove', 'update'],
  computed: {
    hasClickListener() {
      return Boolean(this.$listeners?.click || this.$attrs?.onClick);
    },
    className() {
      return {
        [this.$style.root]: true,
        [this.$style.removable]: this.canRemove,
        [this.$style.error]: this.hasError,
        [this.$style.disabledLike]: this.disabledLike,
        [this.$style.withIcon]: Boolean(this.icon)
      };
    }
  },
  watch: {
    value: {
      handler() {
        this.shouldEmitUpdate = this.shouldEmitUpdate || this.updateOnMount;
        if (!this.shouldEmitUpdate) {
          this.shouldEmitUpdate = true;
          return;
        }
        this.$nextTick(() => {
          this.$emit('update', this.$el);
          this.highlight();
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    highlight() {
      const node = this.$refs.title;
      if (!node) {
        return;
      }
      const className = this.$style.animatedTitle;
      node.addEventListener('animationend', () => node.classList.remove(className), {
        once: true
      });
      node.classList.add(className);
    }
  }
};
</script>

<style module>
$iconSize: 32px;

.loadingIcon {
  color: #999999;
}

.root {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  width: fit-content;
}

.root.removable {
  max-width: calc(100% - $iconSize);
}

.icon {
  flex: none;
  position: absolute;
  left: 0;
  top: -3px;
  height: $iconSize;
  width: $iconSize;
  color: $vantaBlackColor;
}

.title {
  @mixin ellipsis;
  display: inline-flex;
  justify-content: flex-start;
  font-size: 16px;
  align-items: center;
  line-height: 27px;
  height: 27px;
}

.title[type='button'] {
  @mixin reset-button-styles;
  cursor: pointer;
  color: $linkColor;
}

.disabledLike .title[type='button'] {
  color: #ccc;
}

.title[type='button'],
.disabledLike .title[type='button'] {
  &:hover {
    color: $redColor;
  }
}

.disabledLike .title[type='button']:disabled,
.title[type='button']:disabled {
  color: #ccc;
  &:hover {
    color: #ccc;
  }
  .icon {
    color: inherit;
  }
}

.title[type='button']:disabled {
  cursor: default;
}

.titleText {
  @mixin ellipsis;
  text-align: left;
  margin-left: 0;
}

.withIcon .titleText {
  margin-left: 28.5px;
}

.error .title {
  color: $redColor;
}

.removeButton {
  @mixin reset-button-styles;
  position: absolute;
  top: -3px;
  right: -$iconSize;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.removeIcon {
  width: $iconSize;
  height: $iconSize;
  color: #cccccc;
}

@keyframes baseAttachmentHighlight {
  0% {
    color: $blueColor;
  }
  50% {
    color: $blueColor;
  }
  100% {
    color: $textColor;
  }
}

.animatedTitle {
  animation: baseAttachmentHighlight 0.8s;
}
</style>

<i18n lang="json">{}</i18n>
