<template>
  <base-label :class="$style.label">
    <div :class="$style.checkboxContainer">
      <div :class="$style.extraCheckboxIcon">
        <slot name="extra-checkbox-icon"></slot>
      </div>
      <base-checkbox
        :checked="checked"
        :value="value"
        :class="$style.checkbox"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </div>
    <span
      :class="[$style.labelContent, ellipsis ? $style.labelEllipsisContent : null]"
      :title="ellipsis ? label : undefined"
      >{{ label }}</span
    >
  </base-label>
</template>

<script>
import BaseLabel from '@/components/label/label.vue';
import BaseCheckbox from '@/components/checkbox/checkbox.vue';

export default {
  name: 'VCheckbox',
  components: {
    BaseLabel,
    BaseCheckbox
  },
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change-value'
  },
  props: {
    ellipsis: {
      type: Boolean,
      default: false
    },
    checked: {
      type: [Array, Number, String, Boolean],
      default: null
    },
    value: {
      type: [Number, String, Boolean],
      default: ''
    },
    label: {
      type: String,
      required: true
    }
  },
  emits: ['change-value']
};
</script>

<style module>
.label {
  display: flex;
  align-items: flex-start;
  margin-bottom: 3px;
  width: max-content;
  font-weight: var(--font-regular-weight, normal);
}

.checkboxContainer {
  flex: none;
  display: flex;
  height: 22px;
  align-items: center;
  padding-top: 1px;
  position: relative;
}

.extraCheckboxIcon {
  position: absolute;
  left: -4px;
  top: -6px;
}

.label > .checkboxContainer .checkbox {
  /* TODO: override global styles, remove later */
  position: initial;
  top: initial;
}

.labelContent {
  margin-left: 8px;
  flex: 1;
}

.labelEllipsisContent {
  @mixin ellipsis;
}
</style>

<i18n lang="json">{}</i18n>
