<template>
  <input
    :id="id"
    ref="input"
    :aria-invalid="invalid ? 'true' : 'false'"
    :value="value"
    v-bind="$attrs"
    :class="className"
    v-on="listeners"
  />
</template>

<script>
import widthStyles from '../../shared-css-modules/widths.module.css';

export default {
  name: 'BaseInput',
  model: {
    prop: 'value',
    event: 'inputValue'
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    invalid: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    big: Boolean,
    size: {
      type: String,
      default: 'full',
      validator(value) {
        return Boolean(widthStyles + '.' + value);
      }
    },
    arrowDisable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['inputValue', 'input'],
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => {
          this.$emit('inputValue', event.target.value);
          this.$emit('input', event);
        }
      };
    },
    className() {
      const sizeClassName = widthStyles[this.size];
      return {
        'form-control_invalid': this.invalid,
        [this.$style.formControl]: true,
        [sizeClassName]: Boolean(sizeClassName),
        [this.$style.big]: this.big,
        [this.$style.arrow]: this.arrowDisable
      };
    }
  },
  mounted() {
    if (this.focus) {
      this.setFocus();
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('keydown', this.checkInputFocus);
  },
  methods: {
    setFocus() {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });

      document.body.addEventListener('keydown', this.checkInputFocus);
    },
    checkInputFocus() {
      // Исправляет ошибку в Safari, при которой после закрытия
      // модального окна через Esc, содержащее поле ввода с фокусом
      // (причём фокус при монтировании, после setFocus()),
      // может срабатывать непонятный скролл
      if (event.keyCode === 27 && document.activeElement === this.$refs.input) {
        this.$refs.input.blur();
      }
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/mixins/font';

.formControl {
  .font_regular();
  padding: 4px 15px 5px 15px;
  box-sizing: border-box;
  margin: 0;
  border-radius: 3px;
  vertical-align: top;
  outline: 0 none;
  display: inline-block;
  font-size: 16px;
  line-height: 19px;
  background: #f2f2f2;
  background-image: none;
  border: 1px solid #e3e3e3;

  &:focus {
    border-color: #2cc8df;
  }

  &[disabled] {
    color: #cccccc;
    cursor: default;
    /* <3 Safari */
    -webkit-text-fill-color: #cccccc;
  }
}

.big {
  padding-top: 9px;
  padding-bottom: 10px;
}
.arrows input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arrows input[type='number'] {
  -moz-appearance: textfield;
}
</style>

<i18n lang="json">{}</i18n>
