<template>
  <div :class="$style.container" :style="styles">
    <slot
      name="before"
      :down="handleNext"
      :up="handlePrev"
      :enter="handleEnter"
      :left="handleLeft"
      :right="handleRight"
    />
    <div v-if="loading" :class="$style.item">
      <slot name="loading">
        <loading-item />
      </slot>
    </div>
    <div v-else-if="!flatItems.length" :class="$style.item">
      <slot name="not-found">
        <empty-item />
      </slot>
    </div>
    <virtual-scroller
      v-else
      ref="scroller"
      tabindex="0"
      :class="$style.scroller"
      :items="flatItems"
      :min-item-size="itemSize"
      :key-field="keyField"
      :page-mode="pageMode"
      @scroll="handleScroll"
      @keydown.down.exact.native.prevent="handleNext"
      @keydown.up.exact.native.prevent="handlePrev"
      @keydown.enter.exact.native.prevent="handleEnter"
      @keydown.left.exact.native.prevent="handleLeft"
      @keydown.right.exact.native.prevent="handleRight"
      @mousemove="handleMousemove"
      @item-mouseenter="handleSelect"
    >
      <template #before>
        <slot name="before-list" />
      </template>
      <template #default="{ item, index }">
        <base-item-wrapper
          :class="$style.item"
          :item="item"
          :previous-item="flatItems[index - 1]"
          :next-item="flatItems[index + 1]"
          :index="index"
          :highlight="index === pointer"
        >
          <template #default="{ item: currentItem, previousItem, nextItem, highlight }">
            <slot
              v-if="!!currentItem[isLabelField]"
              name="item-header"
              :item="currentItem"
              :index="index"
              :deep="currentItem[deepField]"
            >
              <header-item>
                <slot name="item-header-title" :item="currentItem">
                  {{ currentItem[captionField] }}
                </slot>
              </header-item>
            </slot>
            <slot
              v-else
              name="item"
              :item="currentItem"
              :index="index"
              :highlight="highlight"
              :disabled="currentItem.disabled"
              :deep="currentItem[deepField]"
              :active="!!currentItem.active"
              :available="currentItem.available"
              :previous-item="previousItem"
              :previous-deep="previousItem[deepField]"
              :next-item="nextItem"
              :next-deep="nextItem[deepField]"
              :caption-field="captionField"
            >
              <base-item
                :disabled="currentItem.disabled"
                :deep="currentItem[deepField]"
                :active="!!currentItem.active"
              >
                <slot name="item-title" :item="currentItem">
                  <span
                    :title="currentItem[captionField]"
                    v-html="currentItem[captionField + '_highlight'] || currentItem[captionField]"
                  ></span>
                </slot>
              </base-item>
            </slot>
          </template>
        </base-item-wrapper>
      </template>
      <template #after>
        <slot name="after-list" />
      </template>
    </virtual-scroller>
    <slot name="after" :down="handleNext" :up="handlePrev" :enter="handleEnter" />
  </div>
</template>

<script>
import { computed, ref, toRefs, watch } from 'vue';

import VirtualScroller from '@/components/ui/virtual-scroller/virtual-scroller';
import HeaderItem from '@/components/list-item/header-item';
import BaseItem from '@/components/list-item/base-item';
import EmptyItem from '@/components/list-item/empty-item';
import LoadingItem from '@/components/list-item/loading-item';
import { makeHierarchyMap } from '../hierarchy/hierarchy-map';
import { isLabelField, keyField, deepField } from './composables/constants';
import { useFlatList } from './composables/use-flat-list';
import { useListPointer } from './composables/use-list-pointer';
import BaseItemWrapper from './base-item-wrapper';

export default {
  name: 'BaseList',
  components: {
    BaseItemWrapper,
    HeaderItem,
    BaseItem,
    EmptyItem,
    LoadingItem,
    VirtualScroller
  },
  props: {
    loading: Boolean,
    pageMode: VirtualScroller.props.pageMode,
    items: {
      type: Array,
      required: true
    },
    itemSize: {
      type: [Number, String],
      default: 42
    },
    showInactive: Boolean,
    maxHeight: {
      type: [Number, String],
      default: null
    },
    hierarchyMap: {
      type: Object,
      default: () => makeHierarchyMap({})
    },
    loopPointer: {
      type: Boolean,
      default: true
    },
    itemsPostprocessing: {
      type: Function,
      default: (items) => items
    },
    captionField: {
      type: String,
      default: 'name'
    }
  },
  emits: ['scroll', 'enter-item', 'left-key', 'right-key'],
  setup(props, { emit }) {
    const {
      showInactive,
      items,
      itemsPostprocessing,
      hierarchyMap,
      loopPointer,
      loading,
      maxHeight
    } = toRefs(props);
    const scroller = ref(null);
    const disableSelection = ref(false);
    const { items: flatItems } = useFlatList(items, {
      showInactive,
      parentChildMap: computed(() => hierarchyMap.value?.parentChildMap || {}),
      postprocessing: itemsPostprocessing
    });
    const { next, prev, select, pointer, reset } = useListPointer(flatItems, {
      loop: loopPointer
    });

    watch(loading, (flag) => {
      if (!flag) {
        reset();
      }
    });

    const styles = computed(() => {
      const normalizedMaxHeight =
        typeof maxHeight.value === 'number' ? `${maxHeight.value}px` : maxHeight.value;
      return {
        maxHeight: normalizedMaxHeight
      };
    });

    function scrollToIndex(index) {
      if (!scroller.value) {
        return;
      }
      disableSelection.value = true;
      scroller.value.scrollToIndex(index);
    }
    function handleNext() {
      next();
      scrollToIndex(pointer.value);
    }
    function handlePrev() {
      prev();
      scrollToIndex(pointer.value);
    }

    const highlightedItem = computed(() => {
      return flatItems.value[pointer.value];
    });
    return {
      styles,
      pointer,
      flatItems,
      keyField,
      isLabelField,
      deepField,
      highlightedItem,
      scrollToIndex,
      handleSelect: (index) => {
        if (!disableSelection.value) {
          select(index);
        }
      },
      handleMousemove: () => {
        disableSelection.value = false;
      },
      handleScroll: () => {
        disableSelection.value = true;
        emit('scroll');
      },
      handleNext,
      handlePrev,
      handleEnter: () => {
        emit('enter-item', flatItems.value[pointer.value]);
      },
      handleLeft: () => {
        emit('left-key', highlightedItem.value);
      },
      handleRight: () => {
        emit('right-key', highlightedItem.value);
      },
      scroller
    };
  }
};
</script>

<style module>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.scroller {
  outline: none;
  flex-grow: 1;
  text-align: left;
}
.item {
  display: flex;
  width: 100%;
  justify-content: center;
  cursor: default;
  user-select: none;
}
</style>

<i18n lang="json">{}</i18n>
