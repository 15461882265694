<template>
  <div>
    <base-label v-if="title" :class="$style.title" :for="id">{{ title }}</base-label>
    <ul v-if="items && items.length > 0" :class="$style.list">
      <li v-for="(item, index) in items" :key="item.id || item.email">
        <slot name="itemPrepend" :item="item" :index="index"></slot>
        <slot name="item" :item="item" :index="index" :invalid="isInvalid(index)">
          <email-list-item
            :item="item"
            :invalid="isInvalid(index)"
            @remove="$emit('remove', item)"
          />
        </slot>
        <slot name="itemAppend" :item="item" :index="index"></slot>
      </li>
    </ul>
    <slot name="append" :emails="emails"></slot>
  </div>
</template>

<script>
import EmailListItem from './email-list-item.vue';
import BaseLabel from '@/components/label/label.vue';

export default {
  name: 'EmailList',
  components: {
    EmailListItem,
    BaseLabel
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: undefined
    },
    items: {
      type: Array,
      default: () => []
    },
    invalidValueIndices: {
      type: Array,
      default: () => []
    }
  },
  emits: ['remove'],
  computed: {
    emails() {
      return this.items.map((item) => item.email);
    }
  },
  methods: {
    isInvalid(index) {
      return this.invalidValueIndices.includes(index);
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.title {
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 3px;
}

.list {
  list-style: none inside none;
  margin: 0 0 10px 0;
  padding: 0;
}
</style>

<i18n lang="json">{}</i18n>
