<template>
  <div :class="[$style.search, { [$style.active]: isSearchActive }]" data-qa="search-header">
    <template v-if="isSearchActive">
      <base-icon type="search-active" :class="$style.iconSearchActive" />

      <search-input :is-show-loading="isShowLoading" />

      <a :class="$style.searchClose" :href="closeLinkHref" data-qa="close-search">
        <base-icon type="search-close" :class="$style.iconClose" />
      </a>
    </template>
    <slot v-else name="toggle" />
  </div>
</template>

<script>
import SearchInput from '@/modules/search/search-header/search-input';
import { emitter } from '@/services/emitter/emitter';
import BaseIcon from '@/components/icon.vue';
import {
  SEARCH_APPLICANTS_DOUBLE_ROUTE_NAME,
  SEARCH_APPLICANTS_ROUTE_NAME,
  SEARCH_VACANCIES_ROUTE_NAME
} from '@/modules/main-screen/constants';

export default {
  name: 'SearchHeader',
  components: {
    SearchInput,
    BaseIcon
  },
  data() {
    return {
      isSearchActive: false,
      isShowLoading: false,
      fromPath: ''
    };
  },
  computed: {
    closeLinkHref() {
      return this.$router.resolve({ path: this.fromPath })?.href;
    }
  },
  created() {
    this.$watch(() => this.$route, this.checkSearchState, {
      immediate: true
    });

    emitter.on('search:loading', this.toggleLoading);
  },
  beforeDestroy() {
    emitter.off('search:loading', this.toggleLoading);
  },
  methods: {
    toggleLoading(loading) {
      this.isShowLoading = loading;
    },
    checkSearchState(to, from) {
      const isSearch = [
        SEARCH_APPLICANTS_ROUTE_NAME,
        SEARCH_VACANCIES_ROUTE_NAME,
        SEARCH_APPLICANTS_DOUBLE_ROUTE_NAME
      ].includes(to?.name);

      if (isSearch !== this.isSearchActive) {
        this.isSearchActive = isSearch;
        if (isSearch) {
          this.fromPath = from.path;
        }
      }
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';
@import '~@less/common/mixins/font.less';
@import '~@less/common/mixins/input.less';

.search {
  height: @layoutHeaderHeight;
  color: white;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;

  &.active {
    flex-grow: 1;
    overflow: visible;
    transition: none;

    &:hover {
      background-color: transparent;
    }
  }
}

.iconSearchActive {
  content: '';
  flex-shrink: 0;
  height: @layoutHeaderHeight;
  margin: -1px -1px 0 15px;
}

.searchClose {
  padding: 8px 10px;
  width: 52px;
  height: @layoutHeaderHeight;
  flex-shrink: 0;
  margin-left: auto;
  user-select: none;
}

.iconClose {
  display: inline-block;
  font-size: 35px;
  width: 1em;
  height: 1em;
  vertical-align: top;
}
</style>

<i18n lang="json">{}</i18n>
