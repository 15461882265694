<template>
  <button
    v-bind="$attrs"
    :type="type"
    :class="{
      [$style.button]: true,
      ['button_' + resultTheme]: resultTheme,
      ['button_' + size]: size,
      ['button_disabled']: fakeDisabled,
      button_preload: loading,
      button_ghost: ghost
    }"
    @click="!loading ? $emit('click', $event) : null"
  >
    <slot />
  </button>
</template>

<script>
import { KINDS, SIZES } from './constants';

export default {
  name: 'BaseButton',
  props: {
    kind: {
      type: String,
      default: '',
      validator(value) {
        return KINDS.hasOwnProperty(value);
      }
    },
    size: {
      type: String,
      default: '',
      validator(value) {
        return SIZES.hasOwnProperty(value);
      }
    },
    type: {
      type: String,
      default: 'button'
    },
    ghost: {
      type: Boolean,
      default: false
    },
    fakeDisabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    resultTheme() {
      return KINDS[this.kind];
    }
  }
};
</script>

<style lang="less" module src="./button.module.less" />

<i18n lang="json">{}</i18n>
