<template>
  <div :class="layoutClasses" data-qa="page-layout">
    <two-columns tag="header" data-qa="header" :class="$style.header">
      <div :class="[$style.item, $style.logoWrapper]">
        <a :href="logoLink" :class="$style.logoLink" data-qa="logo-link">
          <img alt="logo" :src="logo" :class="$style.logo" />
        </a>
      </div>
      <skeleton zone="header" width="100%" height="55px" dark :class="[$style.item, $style.right]">
        <div :class="[$style.rightSide]">
          <div v-if="!isSearchPage" :class="[$style.item, $style.title]" data-qa="vacancy-block">
            <portal-target name="page-header">
              <slot name="header-title" />
            </portal-target>
          </div>

          <slot name="right-side">
            <template v-if="isAuthorized">
              <slot name="search">
                <search-header :class="$style.item">
                  <template #toggle>
                    <header-item data-qa="open-search">
                      <a :href="url('#/search/applicants')">
                        <base-icon type="search" :class="$style.searchIcon" />
                      </a>
                    </header-item>
                  </template>
                </search-header>
              </slot>
              <slot name="calendar">
                <header-item :class="$style.item">
                  <a :href="url('#calendar')" data-qa="calendar-link">
                    <base-icon type="calendar-bold" :class="$style.calendarIcon" />
                  </a>
                </header-item>
              </slot>
              <slot name="stats">
                <header-item v-if="canGetStats" :class="$style.item">
                  <a :class="$style.reportIcon" :href="url('/reports')" data-qa="report-link" />
                </header-item>
              </slot>
              <slot name="warning">
                <transition
                  v-if="untilNy"
                  name="warning"
                  :enter-class="$style.warningEnter"
                  :enter-active-class="$style.warningEnterActive"
                  :leave-to-class="$style.warningLeaveTo"
                  :leave-active-class="$style.warningLeaveActive"
                  @before-leave="beforeWarningLeave"
                >
                  <header-item v-show="!isSearchPage" :class="[$style.item, $style.warning]">
                    <header-warnings :until-ny="untilNy" />
                  </header-item>
                </transition>
              </slot>
            </template>
          </slot>
          <slot name="menu">
            <div v-if="isAuthorized" :class="[$style.item, $style.menu]">
              <user-menu :show-settings="showSettingsLink" />
            </div>
          </slot>
        </div>
      </skeleton>
    </two-columns>

    <layout-info v-if="isAuthorized" />

    <section :class="$style.section">
      <slot name="content">
        <component :is="contentComponent" :class="$style.content">
          <aside v-if="isShowSidebar" :class="$style.aside">
            <div :class="[$style.sidebar, { [$style.loaded]: isSidebarLoaded }]" data-qa="sidebar">
              <div id="sidebar-content" class="g-scrollable" :class="$style.sidebarContent">
                <sidebar-default-content v-show="!isSearchPage" />
              </div>
            </div>
            <div v-show="isSidebarLoaded" ref="resizeControl" :class="$style.resizeControl" />
            <div ref="resizeContainer" :class="$style.resizeContainer">
              <div ref="resizePlaceholder" :class="$style.resizePlaceholder" />
            </div>
          </aside>
          <main :class="mainClasses">
            <slot />
          </main>
        </component>
      </slot>
      <portal-target name="after-page-content" />
    </section>

    <div :class="$style.button">
      <div :class="$style.buttonGroup">
        <slot name="left-buttons" />
      </div>
      <div :class="$style.buttonGroup">
        <slot name="right-buttons" :is-elixir-enabled="isElixirEnabled">
          <huntflow-news v-if="isAuthorized" />
          <feedback-button />
        </slot>
      </div>
    </div>
    <modal-view />
    <app-version-info />
    <system-notifications />
    <email-verification-checker />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PortalTarget } from 'portal-vue';
import { appConfig } from '@/services/config/app-config';
import { userPermissions } from '@/services/config/user-permissions';
import { SkeletonEvents, SkeletonHelper, SkeletonZones } from '@/util/skeleton-helper';
import BaseIcon from '@/components/icon.vue';
import UserMenu from '@/components/user-menu/user-menu.vue';
import HeaderWarnings from '@/components/hf/header-warnings/header-warnings.vue';
import AppVersionInfo from '@/components/app-version/app-version-info.vue';
import SystemNotifications from '@/components/system-notifications/component.vue';
import ModalView from '@/components/modal-view.vue';
import EmailVerificationChecker from '@/components/email-verification/main/email-verification-checker.vue';
import { Skeleton, SkeletonBar } from '@/components/skeleton';
import HuntflowNews from '@/modules/news/news.vue';
import SearchHeader from '@/modules/search/search-header/search-header.vue';
import SidebarDefaultContent from '@/modules/sidebar/default-content.vue';
import { initMenuResize } from './sidebar-resize';
import TwoColumns from './two-columns.vue';
import HeaderItem from './header-item.vue';
import LayoutInfo from './layout-info.vue';
import FeedbackButton from './feedback-button.vue';
import {
  SEARCH_APPLICANTS_DOUBLE_ROUTE_NAME,
  SEARCH_APPLICANTS_ROUTE_NAME,
  SEARCH_VACANCIES_ROUTE_NAME
} from '@/modules/main-screen/constants';

export default {
  name: 'PageLayout',
  components: {
    Skeleton,
    SkeletonBar,
    FeedbackButton,
    TwoColumns,
    HuntflowNews,
    LayoutInfo,
    HeaderItem,
    UserMenu,
    BaseIcon,
    AppVersionInfo,
    SystemNotifications,
    HeaderWarnings,
    ModalView,
    SearchHeader,
    SidebarDefaultContent,
    EmailVerificationChecker,
    PortalTarget
  },
  props: {
    showSettingsLink: {
      type: Boolean,
      default: true
    },
    logoLink: {
      type: String,
      default: '/'
    }
  },
  data() {
    return {
      isSidebarLoaded: false
    };
  },
  computed: {
    ...mapGetters('config', ['brandThemesLogo']),
    isAuthorized: () => appConfig.isAuthorized,
    orgNick: () => userPermissions.get('nick'),
    canGetStats: () => userPermissions.get('can_get_stats'),
    untilNy() {
      return window.UntilNY;
    },
    isRuLang() {
      return appConfig.get('lang') === 'ru';
    },
    logo() {
      if (this.brandThemesLogo) {
        return this.brandThemesLogo;
      }
      return !this.isRuLang ? require('./images/logo-en.svg') : require('./images/logo-ru.svg');
    },
    isElixirEnabled() {
      return Boolean(appConfig.get('elixir'));
    },
    isSearchPage() {
      return [
        SEARCH_APPLICANTS_ROUTE_NAME,
        SEARCH_VACANCIES_ROUTE_NAME,
        SEARCH_APPLICANTS_DOUBLE_ROUTE_NAME
      ].includes(this.$route?.name);
    },
    isShowSidebar() {
      return this.$route?.matched[0]?.meta?.layout === 'sidebar';
    },
    contentComponent() {
      if (this.isShowSidebar) {
        return TwoColumns;
      }
      return 'div';
    },
    layoutClasses() {
      return {
        [this.$style.layout]: true,
        // Этот класс описан и в index.html - он используется для определения,
        // когда скрывать дефолтный скелетон сайдбара (layout-placeholder).
        // Здесь он указан потому что в момент инициализации страницы
        // роутер ещё не знает текущий route и isShowSidebar=false.
        // Что происходит: сайдбар ещё скрыт, скелетон уже скрыт,
        // и только после инициализации route'а сайдбар отображается.
        // Визуально происходит "моргание" сайдбара
        'page-not-loaded': this.$route ? !this.$route.name : false
      };
    },
    mainClasses() {
      const routeClass = this.$style[this.$route?.name];
      return {
        [this.$style.main]: true,
        [routeClass]: Boolean(routeClass)
      };
    }
  },
  watch: {
    isShowSidebar: {
      handler() {
        this.$nextTick(this.initResize);
      },
      immediate: true
    }
  },
  mounted() {
    SkeletonHelper.on(SkeletonEvents.CONTENT_APPEARING, this.handleContentAppearing);
  },
  beforeDestroy() {
    SkeletonHelper.off(SkeletonEvents.CONTENT_APPEARING, this.handleContentAppearing);
    this.resize?.destroy();
  },
  methods: {
    handleContentAppearing(zone) {
      if (zone === SkeletonZones.SIDEBAR_GENERAL) {
        SkeletonHelper.off(SkeletonEvents.CONTENT_APPEARING, this.handleContentAppearing);
        this.isSidebarLoaded = true;
      }
    },
    url(url) {
      return `/my/${this.orgNick}${url}`;
    },
    initResize() {
      if (!this.$refs.resizeContainer) {
        return;
      }
      this.resize = initMenuResize({
        container: this.$refs.resizeContainer,
        control: this.$refs.resizeControl,
        placeholder: this.$refs.resizePlaceholder
      });
    },
    beforeWarningLeave(el) {
      if (el.style.width) {
        return;
      }
      const width = el.getBoundingClientRect().width;
      // На случай, когда блок warning скрылся раньше (isSearchPage),
      // чем загрузилась информация о демо (untilNy)
      if (!width) {
        return;
      }
      el.style.width = `${width}px`;
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables';
@import '~@less/common/mixins/font';
@import '~@less/common/mixins/flex';

.layout {
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  min-width: 960px;
}
.logoWrapper {
  padding: 0 15px;
}
.logoLink {
  display: flex;
  height: @layoutHeaderHeight;
  align-items: center;
  /*
    Safari использует OCR, и в Shadow DOM вставляет распознанный текст,
    из-за чего при наведении курсор такой как при наведении на обычный текст
  */
  cursor: pointer;
}
.logo {
  object-fit: cover;
  max-height: 90%;
  max-width: 100%;
}
.header {
  --skeleton-opacity: 0.1;
  height: @layoutHeaderHeight;
  background-color: @headerColor;
  position: relative;
  z-index: 2000;
}
.content {
  flex: 1;
  display: flex;
  height: 100%;
}
.main {
  display: flex;
  overflow: auto;
  flex: 1;
  &.calendar {
    --content-loader-offset: 55px;
  }
}
.section {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: auto;
  position: relative;
}
.item + .item {
  position: relative;
}
.item + .item::before {
  content: '';
  border-left: 1px solid #38363b;
  position: absolute;
  left: 0;
  height: 25px;
  top: 14px;
  z-index: 1;
}
.item.right {
  overflow: auto;
}
.rightSide {
  display: flex;
  overflow: auto;
}
.title {
  align-items: center;
  display: flex;
  flex: 1 0 0;
  overflow: auto;
  color: #929097;
}
.menu {
  flex: 0 0 auto;
  max-width: 200px;
  color: #929097;
  position: relative;
}
.searchIcon {
  margin-top: -3px;
}
.calendarIcon {
  margin-top: -5px;
  color: @whiteColor;
}
.reportIcon {
  background-image: url(~@static/i/blocks/stats-report/stats.svg);
  background-repeat: no-repeat;
  background-position: 18px 15px;
}
.warning {
  &:hover {
    background: none;
  }
  &.warningEnterActive,
  &.warningLeaveActive {
    transition:
      opacity 400ms,
      width 400ms ease-out;
    overflow: hidden;
    min-width: 0;
  }
  &.warningEnter,
  &.warningLeaveTo {
    opacity: 0;
    width: 0 !important; // important используется, чтобы переписать inline
  }
}
.button {
  position: fixed;
  bottom: 10px;
  z-index: 10;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 25px 0 15px;
  width: 100%;
  transition: opacity 200ms ease-out;
}
.buttonGroup {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.buttonGroup > * {
  pointer-events: auto;
}
.menuCommon() {
  min-width: 250px;
  .flex-child();
  flex: 0 0 auto;
  max-width: 400px;
}
.aside {
  .menuCommon();
  width: calc(1px * var(--sidebar-width, 250));
  display: flex;
  flex-direction: column;
  position: relative;
  &::after {
    // Сделанно не через border
    // из за особенности позиционирования
    // .header-container:after
    content: ' ';
    position: absolute;
    width: 1px;
    background-color: @sidebarVerticalDividerColor;
    top: 0;
    bottom: 0;
    left: 100%;
    z-index: 9;
  }
}
.sidebar {
  .menuCommon();
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  display: block;
  min-width: auto;
  background: @sidebarBackground;
  background-image: none;
  position: relative;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: @sidebarBackground;
    opacity: 0;
    pointer-events: none;
  }
  &.loaded::before {
    opacity: 1;
    transition: opacity 400ms;
  }
}
.sidebarContent {
  overflow: auto;
  padding: 0 25px 70px 15px;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  height: 100%;
}
.resizeContainer {
  position: absolute;
  left: 242px;
  width: 160px;
  top: 0;
  z-index: 9;
}
.resizePlaceholder {
  position: fixed;
  display: none;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 9;
}
.resizeControl {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 10px;
  box-sizing: border-box;
  margin-left: -8px;
  z-index: 10;
  cursor: col-resize;
  border-right: 3px solid transparent;
  transition: border-right-color 0.2s;
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 5px;
  }
  &:hover,
  &:global(.is-pointer-down),
  &:global(.is-dragging) {
    border-right-color: #69548e;
  }
}
</style>

<i18n lang="json">{}</i18n>
