<template>
  <button
    :id="id"
    type="button"
    :class="classes"
    tabindex="0"
    :name="name"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot>
      {{ text }}
    </slot>
    <span :class="$style.iconWrapper">
      <slot name="icon">
        <base-icon type="dropdown-arrow" width="10" height="6" />
      </slot>
    </span>
  </button>
</template>

<script>
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'ToggleButton',
  components: { BaseIcon },
  props: {
    text: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: undefined
    },
    invalid: Boolean,
    disabled: Boolean
  },
  emits: ['click'],
  computed: {
    classes() {
      return {
        [this.$style.button]: true,
        [this.$style.invalid]: this.invalid
      };
    }
  }
};
</script>

<style module>
.button {
  background: var(--toogle-button-background, #f2f2f2);
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  outline: 0 none;
  padding: 4px 25px 5px 15px;
  vertical-align: top;
  width: 100%;
  height: 30px;
  position: relative;
  display: block;
  cursor: default;
  user-select: none;
  text-align: left;
  @mixin ellipsis;
}
.iconWrapper {
  display: inline-flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
.button:focus:not(.invalid) {
  border-color: #2cc8df;
}
.button[disabled]:not(.invalid) {
  color: #cccccc;
  cursor: default;
}
.invalid {
  border-color: $errorColor;
}
</style>

<i18n lang="json">{}</i18n>
