import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

export function createPlugin({ text }) {
  const pluginKey = new PluginKey('placeholder');
  const plugin = new Plugin({
    props: {
      decorations(state) {
        const doc = state.doc;
        if (
          text &&
          doc.childCount === 1 &&
          doc.firstChild.isTextblock &&
          doc.firstChild.content.size === 0
        ) {
          const placeholder = document.createElement('span');
          placeholder.classList.add('wysiwyg-placeholder');
          placeholder.innerHTML = text;
          return DecorationSet.create(doc, [Decoration.widget(1, placeholder)]);
        }
      }
    }
  });

  return {
    pluginKey,
    plugin
  };
}
