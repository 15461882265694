<template>
  <two-column-block>
    <template #default>
      <div :class="$style.main">
        <slot v-for="name in grouppedFields.default" :name="name" />
        <div :class="$style.mainPortal">
          <portal-target :name="mainPortal" />
        </div>
      </div>
    </template>
    <template #side>
      <div :class="$style.side">
        <slot v-for="name in grouppedFields.side" :name="name" />
        <div :class="$style.sidePortal">
          <portal-target :name="sidePortal" />
        </div>
      </div>
    </template>
  </two-column-block>
</template>

<script>
import { nanoid } from 'nanoid';
import groupBy from 'lodash/groupBy';
import { PortalTarget } from 'portal-vue';
import TwoColumnBlock from '@/components/layout/two-column-block';

const zones = {
  side: new Set(['applicants_to_hire'])
};

export function getGrouppedFields(orderedProperties) {
  const groupFn = (name) => {
    for (const key in zones) {
      if (zones[key].has(name)) {
        return key;
      }
    }
    return 'default';
  };
  return groupBy(orderedProperties, groupFn);
}

export const VACANCY_REQUEST_MAIN_PORTAL = nanoid();
export const VACANCY_REQUEST_SIDE_PORTAL = nanoid();

export default {
  name: 'VacancyRequestFormLayout',
  components: {
    PortalTarget,
    TwoColumnBlock
  },
  props: {
    orderedProperties: {
      type: Array,
      required: true
    }
  },
  computed: {
    mainPortal: () => VACANCY_REQUEST_MAIN_PORTAL,
    sidePortal: () => VACANCY_REQUEST_SIDE_PORTAL,
    grouppedFields() {
      return getGrouppedFields(this.orderedProperties);
    }
  }
};
</script>

<style lang="less" module>
.main,
.side {
  padding-top: 15px;
  padding-bottom: 10px;
}
</style>

<i18n lang="json">{}</i18n>
