<template>
  <span :class="[$style.root]">
    <slot :tariffGroup="tariffGroup">{{ $trlMessage('tariffs.badge-label', tariffGroup) }}</slot>
  </span>
</template>

<script>
export default {
  name: 'TariffBadge',
  props: {
    tariffGroup: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="less" module>
.root {
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  height: 36px;
  padding: 0 20px;

  display: inline-block;
  white-space: nowrap;

  color: #ff8a00;
  border: 3px solid currentColor;
  border-radius: 18px;
}
</style>

<i18n lang="json">{}</i18n>
