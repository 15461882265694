<template>
  <form ref="form" novalidate @submit.prevent.stop="submit">
    <popup-layout :title="$trlMessage('vacancy.type-dialog.title')">
      <template #main>
        <div :class="$style.main">
          <base-select
            name="type"
            data-qa="new_or_existing_select"
            :value="form.type"
            @changeValue="form.type = $event"
          >
            <base-option v-for="type in typeValues" :key="type.id" :value="type.id">
              {{ type.name }}
            </base-option>
          </base-select>
          <div v-if="form.type === 'add'">
            <vacancy-dropdown-autocomplete
              id="vacancy-add-type-dialog-vacancies-autocomplete"
              v-model="form.vacancyId"
              data-qa="vacancy-dropdown"
              :parent="false"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <base-button data-qa="submit" :disabled="submitDisabled" type="submit">
          {{ $trlMessage('button.continue') }}
        </base-button>
        <base-button kind="light" data-qa="cancel" @click="cancel">
          {{ $trlMessage('button.cancel') }}
        </base-button>
      </template>
    </popup-layout>
  </form>
</template>

<script>
import BaseButton from '@/components/button/button';
import BaseSelect from '@/components/select/select';
import PopupLayout from '@/components/popup-layout/popup-layout';
import VacancyDropdownAutocomplete from '@/components/hf/autocomplete/vacancy-dropdown-autocomplete.vue';
import BaseOption from '@/components/select/base-option.vue';

const TYPE_VALUES = [
  { id: 'default', name: 'vacancy.type-dialog.select.default' },
  { id: 'add', name: 'vacancy.type-dialog.select.add' }
];

export default {
  name: 'VacancyAddTypeDialog',
  components: {
    BaseOption,
    BaseButton,
    BaseSelect,
    PopupLayout,
    VacancyDropdownAutocomplete
  },
  props: {
    props: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['submit', 'cancel'],
  data() {
    return {
      form: {
        type: 'default',
        vacancyId: undefined
      }
    };
  },
  computed: {
    typeValues() {
      return TYPE_VALUES.map((option) => ({
        ...option,
        // eslint-disable-next-line no-restricted-syntax
        name: this.$trlMessage(option.name)
      }));
    },
    submitDisabled() {
      if (this.form.type !== 'add') {
        return false;
      }

      return !this.form.vacancyId;
    }
  },
  methods: {
    submit() {
      const vacancyId = this.form.type === 'add' ? this.form.vacancyId : undefined;
      this.$emit('submit', vacancyId);
      if (typeof this.props?.handleSubmit === 'function') {
        const dialog = {
          type: this.form.type,
          vacancyId
        };
        this.props.handleSubmit(dialog);
      }
    },
    cancel() {
      this.$emit('cancel');
      if (typeof this.props?.handleCancel === 'function') {
        this.props.handleCancel();
      }
    }
  }
};
</script>

<style module>
.main > * {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>

<i18n lang="json">{}</i18n>
