<template>
  <button type="button" :class="[$style.itemBtn, $style[`kind-${kind}`]]" v-on="$listeners">
    <lock-icon-wrapper
      v-if="isShowIcon"
      :locked="locked"
      :height="12"
      :width="12"
      :class="$style.lockIconWrapper"
      color="white"
      v-bind="lockIconPosition"
    >
      <template #default>
        <slot name="icon">
          <base-icon :width="30" :class="$style.icon" :type="icon" />
        </slot>
      </template>
    </lock-icon-wrapper>
    <span data-qa="name" :class="$style.name">{{ caption }}</span>
  </button>
</template>

<script>
import { SlotChecker } from '@/util/slot-checker';
import { BaseIcon } from '@/components';
import LockIconWrapper from '@/components/ui/base-icon/lock-icon-wrapper';

export default {
  name: 'ListEntryButton',
  components: {
    BaseIcon,
    LockIconWrapper
  },
  props: {
    icon: {
      type: String,
      default: undefined
    },
    caption: {
      type: String,
      required: true
    },
    kind: {
      type: String,
      default: 'default'
    },
    locked: {
      type: Boolean,
      default: false
    },
    lockIconPosition: {
      type: Object,
      default: () => ({
        top: 9,
        left: 14
      })
    }
  },
  computed: {
    isShowIcon() {
      return this.icon || SlotChecker.isSlotNotEmpty(this.$slots.icon);
    }
  }
};
</script>

<style module>
.itemBtn {
  @mixin reset-button-styles;
  display: flex;
  align-items: center;
  max-width: 100%;
  text-align: left;
  cursor: pointer;
}

.name {
  @mixin ellipsis;
  max-width: 600px;
}

.kind-blue.itemBtn {
  color: $blueColor;
}

.lockIconWrapper {
  margin-right: 8px;
  flex: none;
  width: 30px;
  height: 30px;
}
</style>

<i18n lang="json">{}</i18n>
