<template>
  <span :class="$style.error">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'BaseError'
};
</script>

<style module>
.error {
  font-size: $secondaryFontSize;
  margin-top: 5px;
  line-height: $secondaryLineHeight;
  display: block;
  color: $errorColor;
  a {
    color: $errorColor;
  }
}
</style>

<i18n lang="json">{}</i18n>
