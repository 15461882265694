/*
  helper-функция для создания-монтирования вуёвого компонента в бэкбоновском коде
*/
import Vue from 'vue';
import { appConfig } from '@/services/config/app-config';
import ElixirButton from '../elixir-button.vue';

export default function makeElixirButton() {
  const elixirButtonMountPoint = document.getElementById('help-elixir-button');
  const isElixirEnabled = Boolean(appConfig.get('elixir') && elixirButtonMountPoint);
  if (isElixirEnabled === false) {
    return undefined;
  }

  const ElixirButtonClass = Vue.extend(ElixirButton);
  const elixirButton = new ElixirButtonClass();
  elixirButton.$mount();
  elixirButtonMountPoint.appendChild(elixirButton.$el);
  return elixirButton;
}
