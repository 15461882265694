<template>
  <div :class="$style.root">
    <slot name="default" />
  </div>
</template>

<style module>
.root {
  --title-font-weight: bold;
  --value-margin-left: 0;
  --value-font-weight: var(--font-regular-weight, normal);
  --field-gap: 2px;
  --value-display: inline;
  --label-gap: 0;
  --label-font-size: 11px;
  --label-display: inline;
  --title-text-transform: uppercase;
  --title-after-content: ':';
}
</style>

<i18n lang="json">{}</i18n>
