<template>
  <div :class="classes" data-qa="popup-layout-heading">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PopupHeading',
  props: {
    line: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes() {
      return {
        [this.$style.heading]: true,
        [this.$style.line]: this.line
      };
    }
  }
};
</script>

<style lang="less" module>
.heading {
  padding: 20px 0;
  font-size: 20px;
  line-height: 22px;
}
.line {
  border-bottom: 1px solid #ccc;
  word-break: break-word;
  overflow-wrap: anywhere;
}
</style>

<i18n lang="json">{}</i18n>
