<template>
  <div>
    <email-autocomplete-list
      ref="emailList"
      :title="$trlMessage('vacancy_request.approval.title')"
      :invalid="invalid"
      :invalid-value-indices="invalidValueIndices"
      :selected-items="selectedItems"
      @change="handleChange"
      @input="handleInput"
    >
      <template #listItemPrepend="{ index }">
        <span v-if="index > 0" :class="$style.delimiter">{{
          $trlMessage('coworker.list.next')
        }}</span>
      </template>
    </email-autocomplete-list>
    <div v-if="hint" :class="$style.hint" v-html="hintHtml" />
  </div>
</template>

<script>
import { DomPurify } from '@/services/dom-purify/dom-purify';
import EmailAutocompleteList from '@/components/email-autocomplete-list/email-autocomplete-list';

export default {
  name: 'AttendeeList',
  components: { EmailAutocompleteList },
  model: {
    prop: 'selectedItems',
    event: 'change'
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },
    invalid: {
      type: Boolean,
      default: false
    },
    invalidValueIndices: EmailAutocompleteList.props.invalidValueIndices,
    required: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    }
  },
  emits: ['change', 'typed-email', 'invalid'],
  computed: {
    isInvalidData() {
      return this.required && !this.selectedItems.length;
    },
    hintHtml() {
      if (!this.hint) {
        return undefined;
      }
      return DomPurify.sanitize(this.hint, {
        ALLOWED_TAGS: ['br', 'b', 'strong', 'p', 'a', 'ul', 'ol', 'li'],
        ADD_ATTR: ['target', 'href', 'rel'],
        USE_PROFILES: false
      });
    }
  },
  mounted() {
    this.handleInput();
  },
  methods: {
    handleChange(values) {
      this.$emit('change', values);
    },
    handleInput({ error, email } = { error: '', email: null }) {
      this.$emit('typed-email', email);
      this.$emit('invalid', !!error || (!email && this.isInvalidData));
    },
    focus() {
      this.$refs.emailList.focus();
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables';

.delimiter {
  display: block;
  color: @lightTextColor;
  font-weight: bold;
  font-size: 12px;
  margin: 6px 0;
  text-transform: uppercase;
}
.hint {
  color: #999;
  margin-top: 5px;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-position: outside;
    li::marker {
      font-variant-numeric: lining-nums;
    }
  }

  ul {
    padding-left: 13px;
  }
  ol {
    padding-left: 22px;
  }

  p {
    margin: 0;
    padding: 0;
  }

  ul + *,
  ol + * {
    margin-top: 10px;
  }

  * + ul,
  * + ol {
    margin-top: 5px;
  }

  a {
    padding: 3px 0;
    color: currentColor;
    &:hover {
      color: @hoverColor;
    }
  }
}
</style>

<i18n lang="json">{}</i18n>
