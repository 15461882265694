<template>
  <div id="form" class="container-fluid">
    <template v-if="isEnded && !isOwner">
      <div class="row">
        <div class="col-md-offset-2 col-md-2">
          <div class="getting-started-finished-icon getting-started-finished-icon_coworkers"></div>
        </div>
        <div class="col-md-6">
          <div class="getting-started-finished-content">
            <h1 class="page-title page-title_margin_10">
              {{ $trlMessage('paywall.recruiter_or_watcher.access_denied') }}
            </h1>
            <div
              class="content content_mt_small"
              v-html="$trlMessage('paywall.recruiter_or_watcher.message')"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="row">
        <div class="col-md-offset-2 col-md-8">
          <div class="getting-started-header">
            <h1 class="page-title" v-html="title" />
            <div class="page-subtitle">
              {{ permissionsName }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-offset-1 col-md-9">
          <div class="getting-started-separator"></div>
        </div>
      </div>

      <div class="row">
        <div v-if="values.success" class="col-xs-offset-2 col-xs-5">
          <div :class="$style.successMessage">
            <icon type="checkmark" width="110" :class="$style.icon" />
            {{ $trlMessage('paywall.success') }}
          </div>
        </div>
        <div v-else class="col-xs-offset-2 col-xs-5">
          <form method="post">
            <input type="hidden" name="_xsrf" :value="$options.xsrf" />

            <div :class="$style.header">
              <h3 v-html="formTitle" />
            </div>

            <div
              class="form-group"
              :class="{
                'form-group_invalid': errors.phone
              }"
            >
              <label for="paywall-phone" class="form-group__label">
                {{ $trlMessage('paywall.phone.label') }}
              </label>
              <input
                id="paywall-phone"
                v-model="values.phone"
                name="phone"
                type="tel"
                class="form-control form-control_size_full"
                required
                autocomplete="off"
              />
              <span class="form-group__error">
                <template v-if="errors.phone">
                  {{ errors.phone[0] }}
                </template>
              </span>
            </div>

            <div
              class="form-group"
              :class="{
                'form-group_invalid': errors.comment
              }"
            >
              <label for="paywall-comment" class="form-group__label">
                {{ $trlMessage('paywall.comment.label') }}
              </label>
              <textarea
                id="paywall-comment"
                v-model="values.comment"
                name="comment"
                class="form-control form-control_size_full"
                rows="3"
              />
              <span class="form-group__error">
                <template v-if="errors.comment">
                  {{ errors.comment[0] }}
                </template>
              </span>
            </div>

            <div v-if="errors.common" class="form-group form-group_invalid">
              <span class="form-group__error"
                ><!-- eslint-disable-next-line vue/no-restricted-syntax -->{{
                  $trlMessage(data.errors.common[0])
                }}</span
              >
            </div>

            <div class="form-group form-group_actions">
              <base-button kind="success" type="submit">
                {{ $trlMessage('paywall.submit') }}
              </base-button>
            </div>
          </form>
        </div>
        <div v-if="showSidebar" class="col-xs-3 col-xs-offset-1" :class="$style.sidebar">
          <div class="content content_mt_big">
            <div :class="$style.listHeader">{{ $trlMessage('paywall.info-saved') }}</div>
            <div :class="$style.list">
              <list-entry-button
                v-if="vacanciesCount !== null"
                :icon="infoSavedIcon"
                :class="$style.listItem"
                :caption="$trlMessage('count.vacancy', { count: vacanciesCount })"
              />
              <list-entry-button
                v-if="applicantsCount !== null"
                :icon="infoSavedIcon"
                :class="$style.listItem"
                :caption="$trlMessage('count.applicants', { count: applicantsCount })"
              />
            </div>
          </div>

          <div class="content content_mt_big">
            <div :class="$style.listHeader">{{ $trlMessage('paywall.coworkers') }}</div>
            <div :class="$style.list">
              <list-entry-button
                v-for="coworker in coworkers"
                :key="coworker.id"
                :class="$style.listItem"
                :caption="getCoworkerCaption(coworker)"
                icon="circle-profile"
              >
                <template #icon>
                  <base-userpic v-if="coworker.has_photo" :src="getCoworkerPhoto(coworker)" />
                </template>
              </list-entry-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-offset-1 col-md-9">
          <div
            class="getting-started-separator getting-started-separator_bottom30 getting-started-separator_top100"
          ></div>
        </div>
      </div>

      <div class="row" :class="$style.footer">
        <div class="col-md-offset-2 col-md-8">
          {{ $trlMessage('paywall.sales') }}:
          <a :href="'mailto:' + $options.salesEmail">{{ $options.salesEmail }}</a>
        </div>
      </div>
    </template>
    <div class="bottom-buttons bottom-buttons_right">
      <elixir-button v-if="isElixirEnabled" />
    </div>
  </div>
</template>

<script>
import { EntityType } from '@/api/photo';
import { appConfig } from '@/services/config/app-config';
import { userPermissions } from '@/services/config/user-permissions';
import tariffs, { isArchive } from '@/services/tariffs';
import { PhotoHelper } from '@/modules/applicant-photo-editor/helper';
import { DateTimeHelper } from '@/util/date-time-helper';
import { XSRFHelper } from '@/util/xsrf-helper';
import ElixirButton from '@/components/elixir-button/elixir-button.vue';
import BaseButton from '@/components/button/button.vue';
import Icon from '@/components/icon.vue';
import BaseUserpic from '@/components/userpic/userpic';
import ListEntryButton from '@/components/button/list-entry-button';

export default {
  name: 'VPaywall',
  xsrf: XSRFHelper.getToken(),
  salesEmail: appConfig.get('salesEmail'),
  components: {
    Icon,
    BaseButton,
    BaseUserpic,
    ElixirButton,
    ListEntryButton
  },
  props: {
    jsonData: {
      type: String,
      required: true
    },
    jsonValues: {
      type: String,
      required: true
    },
    jsonErrors: {
      type: String,
      required: true
    }
  },
  data() {
    const data = {
      values: {},
      errors: {}
    };

    try {
      data.values = JSON.parse(this.jsonValues);
    } catch (e) {} // eslint-disable-line

    try {
      data.errors = JSON.parse(this.jsonErrors);
    } catch (e) {} // eslint-disable-line

    return data;
  },
  computed: {
    account: () => appConfig.get('account'),
    permissionsName: () => userPermissions.get('name'),
    isOwner: () => userPermissions.isOwner,
    license() {
      return tariffs.licenseInfo.current_license || tariffs.licenseInfo.previous_license;
    },
    title() {
      if (this.isArchiveTariff) {
        const date = DateTimeHelper.toShortFormat({ date: this.license.scheduled_end_at });
        return this.$trlMessage('paywall.archive.title', { date });
      }
      if (this.isEnded) {
        return this.$trlMessage('paywall.title.expired');
      }

      if (this.expireSoon) {
        return this.$trlMessage('paywall.expire.soon.icu', {
          tariffName: this.license.tariff_group.name,
          days: this.daysTillEnd
        });
      }

      return this.$trlMessage('paywall.expire.until.icu', {
        tariffName: this.license.tariff_group.name,
        date: DateTimeHelper.toLongFormat({ date: this.license.scheduled_end_at })
      });
    },
    formTitle() {
      return this.isArchiveTariff
        ? this.$trlMessage('paywall.archive.request')
        : this.$trlMessage('paywall.form.title');
    },
    daysTillEnd() {
      if (this.license) {
        const date = DateTimeHelper.parse({ date: this.license.scheduled_end_at });
        const now = DateTimeHelper.now();
        return date.since(now).round({
          roundingMode: 'floor',
          smallestUnit: 'day'
        }).days;
      }

      return Infinity;
    },
    expireSoon() {
      return this.daysTillEnd <= 14;
    },
    showSidebar() {
      return this.isArchiveTariff || this.daysTillEnd >= -30;
    },
    isEnded() {
      return !tariffs.licenseInfo.current_license;
    },
    parsedData() {
      return JSON.parse(this.jsonData);
    },
    coworkers() {
      return this.parsedData.coworkers;
    },
    applicantsCount() {
      return this.parsedData.applicantsCount;
    },
    vacanciesCount() {
      return this.parsedData.vacanciesCount;
    },
    isElixirEnabled() {
      return Boolean(appConfig.get('elixir'));
    },
    infoSavedIcon() {
      return this.isEnded ? 'circle-lock' : 'circle-checkmark';
    },
    isArchiveTariff() {
      return isArchive(this.license?.tariff_group);
    }
  },
  methods: {
    getCoworkerCaption(coworker) {
      return coworker.member === this.account.id ? this.$trlMessage('Me') : coworker.name;
    },
    getCoworkerPhoto(coworker) {
      return PhotoHelper.getPhotoUrl({ entity: coworker, type: EntityType.USER });
    }
  }
};
</script>

<style module>
.header {
  margin: 50px 0 20px;

  h3 {
    font-size: 20px;
    line-height: 22px;
    margin: 0;
  }
}

.successMessage {
  position: relative;
  text-align: center;
  min-height: 210px;
  padding-top: 100px;
  font-weight: bold;
  font-size: 18px;
  color: #7ac016;
  line-height: $baseLineHeight;
}

.sidebar {
  padding-top: 25px;
}

.footer {
  a {
    text-decoration: none;
    color: $linkColor;

    &:hover {
      color: $redColor;
    }
  }
}

.icon {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -55px;
}

.listHeader {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 13px;
}

.list {
  display: inline-block;
  max-width: 100%;
}

.listItem {
  margin-top: 10px;
}
</style>

<i18n lang="json">{}</i18n>
