<template>
  <div>
    <button type="button" :class="$style.btn" data-qa="reset" @click="$emit('reset')">
      {{ $trlMessage('autocomplete.reset') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'AutocompleteReset',
  emits: ['reset']
};
</script>

<style module>
.btn {
  @mixin reset-button-styles;
  margin: 0 12px;
  font-weight: bold;
  font-size: 11px;
  color: #ccc;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;

  &:hover {
    color: $redColor;
  }
}
</style>

<i18n lang="json">{}</i18n>
