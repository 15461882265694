<template>
  <button type="button" :class="$style.btn" @click="$emit('click')">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ActionButton',
  emits: ['click']
};
</script>

<style module>
.btn {
  @mixin reset-button-styles;
  height: 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #666666;
  outline: none;
  cursor: pointer;
  &:not(:disabled):hover {
    color: $redColor;
  }
  &:disabled {
    color: #bfbfbf;
    cursor: default;
  }
}
</style>

<i18n lang="json">{}</i18n>
