<template>
  <a :href="href" :class="$style.link" data-qa="back_to_work">
    <template v-if="useIcon">&larr;</template>
    <slot />
  </a>
</template>

<script>
export default {
  name: 'BackLink',
  props: {
    href: {
      type: String,
      required: true
    },
    useIcon: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style module>
.link {
  line-height: var(--header-height);
  color: #929097;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  display: block;
  width: 100%;
  cursor: default;
  padding: 0 15px;
  height: var(--header-height);
  transition:
    color 0.1s linear,
    background-color 0.1s linear;
}
.link:visited {
  color: #929097;
}
.link:hover {
  color: white;
  background-color: #000;
}
</style>

<i18n lang="json">{}</i18n>
