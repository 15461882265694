<template>
  <div class="notifications-container">
    <transition
      name="fade"
      @before-enter="beforeEnter"
      @after-enter="afterEnter"
      @after-leave="afterLeave"
    >
      <div
        v-if="showCurrentNotification"
        class="notifications-item"
        data-ignore-outside-click
        :class="{
          'notifications-item-error': currentNotification.isError
        }"
        @mouseenter="handleMouseenter"
        @mouseleave="handleMouseleave"
      >
        <div
          class="content"
          :title="currentNotification.content"
          v-html="currentNotification.content"
        >
        </div>
        <div class="close" @click="handleClose" data-qa="close-popup-notification">
          <span>&times;</span>
        </div>
      </div>
    </transition>
    <slot name="poller">
      <notifier-message @notify="pollerNotifiers" />
    </slot>
  </div>
</template>

<script>
import { notification } from '@/services/notification/notification.ts';
import { trlMessage } from '@/services/i18n';
import NotifierMessage from '@/components/hf/notifier-message/notifier-message';

export default {
  name: 'SystemNotifications',
  components: { NotifierMessage },
  props: {
    duration: {
      type: Number,
      default: 5000
    },
    delay: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      notificationQueue: [],
      showCurrentNotification: false,
      canShowNextNotification: true
    };
  },
  computed: {
    currentNotification() {
      return this.notificationQueue[this.notificationQueue.length - 1];
    }
  },
  mounted() {
    this.waitForNext = true;
    this.unsubscribe = notification.subscribe(this.addItem);
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    pollerNotifiers(data) {
      if (!data.message) return;
      // у trlMessage payload не может быть вложенными значениями, только плоский объект, поэтому тут небольшой хак для мета информации
      const meta = Object.fromEntries(
        Object.entries(data.meta || {}).map(([key, value]) => [`meta_${key}`, value])
      );
      notification.notify({
        // eslint-disable-next-line no-restricted-syntax
        content: trlMessage(data.message, {
          ...data,
          ...meta
        }),
        isError: !data.success
      });
    },
    beforeEnter() {
      // "лочим" компонент для следующего сообщения
      // на время показа + задержку
      this.canHideCurrent = false;
      this.canShowNextNotification = false;
      this.hideTimeout = setTimeout(() => {
        this.showCurrentNotification = false;
      }, this.duration);
    },
    handleMouseenter() {
      if (this.canHideCurrent) {
        clearTimeout(this.hideTimeout);
      }
    },
    handleMouseleave() {
      this.hideTimeout = setTimeout(() => {
        this.showCurrentNotification = false;
      }, this.duration);
    },
    afterEnter() {
      this.canHideCurrent = true;
      if (this.preClose) {
        this.preClose = false;
        this.handleClose();
      }
    },
    afterLeave() {
      clearTimeout(this.hideTimeout);

      setTimeout(
        () => {
          this.waitForNext = true;
          // удаляем из очереди показанное сообщение
          this.notificationQueue.pop();

          // снимаем блокировку с тем чтобы показать следующее сообщение...
          this.canShowNextNotification = true;
          // ...если оно есть
          if (this.currentNotification) {
            this.showCurrentNotification = true;
          }
        },
        this.waitForNext ? this.delay : 0
      );
    },
    handleClose() {
      if (this.canHideCurrent) {
        this.showCurrentNotification = false;
      } else {
        this.preClose = true;
      }
    },
    addItem(notification) {
      if (notification.id) {
        this.notificationQueue = this.notificationQueue.filter(
          (oldNotification) =>
            notification.id !== oldNotification.id || oldNotification === this.currentNotification
        );

        if (this.currentNotification && this.currentNotification.id === notification.id) {
          this.waitForNext = false;
          this.handleClose(true);
        }
      }
      this.notificationQueue.unshift(notification);
      if (this.canShowNextNotification) {
        // немедля показать пришедшее сообщение
        this.showCurrentNotification = true;
      }
    }
  }
};
</script>

<style>
/* TODO: обновить лоадер для scoped-стилизации вложенных элементов */
/* see >>> combinator */
.notifications-item .content a {
  color: #ffffff;
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.notifications-container {
  position: fixed;
  bottom: 10px;
  left: 50%;
  margin-left: -275px;
  width: max-content;
  z-index: 999999;
}

.notifications-item {
  width: 550px;
  margin: 0 auto;
  display: flex;
  background: $btnGreen;
  border-radius: 3px;
}

.notifications-item-error {
  background: #db525e;
}

.notifications-item .content {
  flex-grow: 1;
  padding-left: 30px;
  padding-right: 30px;
  overflow: hidden;
  color: #ffffff;
  font-size: $secondaryFontSize;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  @mixin ellipsis;
}

.notifications-item .close {
  display: flex;
  width: 30px;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  cursor: default;
  user-select: none;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
  font-weight: bold;

  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
}
</style>

<i18n lang="json">{}</i18n>
