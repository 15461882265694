<template>
  <div :class="$style.wrapper">
    <slot></slot>
  </div>
</template>

<style lang="less" module>
.wrapper {
  padding: 0 35px;
  background: #ffffff;
}
</style>

<i18n lang="json">{}</i18n>
