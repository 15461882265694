<template>
  <div>
    <span data-qa="date" :class="$style.date">{{ createdDateStr }}</span>
    <span
      v-if="vacancyRequest.account_info.name"
      data-qa="watcher-name"
      :class="$style.watcherName"
    >
      {{ vacancyRequest.account_info.name }}
    </span>
    <span
      v-else
      :title="vacancyRequest.account_info.email"
      data-qa="watcher-email"
      :class="[$style.watcherName, $style.watcherNameEmail]"
    >
      {{ vacancyRequest.account_info.email }}
    </span>
  </div>
</template>

<script>
import { DateTimeHelper } from '@/util/date-time-helper';

export default {
  name: 'WatcherInfoModal',
  props: {
    vacancyRequest: {
      type: Object,
      required: true
    }
  },
  computed: {
    createdDateStr() {
      return `${this.$trlMessage('vacancy_request.popup.watcher')}, ${DateTimeHelper.toShortFormat({
        date: this.vacancyRequest.created
      })}`;
    }
  }
};
</script>

<style module>
.date {
  color: $vantaBlackColor;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
}

.watcherName {
  font-weight: bold;
}

.date,
.watcherName {
  display: block;
}

.watcherNameEmail {
  @mixin ellipsis;
}
</style>

<i18n lang="json">{}</i18n>
