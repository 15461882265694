<template>
  <ul :class="$style.root">
    <li :class="$style.parent"><slot name="parent" /></li>
    <slot v-for="child in children" name="item" :item="child">
      <li :key="child.id">{{ child }}</li>
    </slot>
  </ul>
</template>

<script>
export default {
  name: 'VacancyItemGroupItems',
  props: {
    children: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="less" module>
.root {
  padding: 0;
  margin: 0;
  list-style: none;
  border-left: 1px solid var(--sidebar-text-color, #929097);
  padding: 1px 0 6px 10px;
}
</style>

<i18n lang="json">{}</i18n>
