<template>
  <div
    class="autocomplete-list-item__link"
    :class="{ 'autocomplete-list-item__link_with-icon': hasIcon }"
    :title="item[displayField]"
  >
    <!-- TODO: use local class instead of vue-icon after autocomplete.less deletion -->
    <base-userpic v-if="photo" :src="photo" :class="$style.icon" />
    <base-icon v-else-if="hasIcon" :type="iconType" />
    <span :class="{ [$style.group]: item.isGroup }">
      <template v-if="prefix">{{ prefix }}</template>
      {{ item[displayField] || item[subDisplayField] }}
    </span>
    <div v-if="item[displayField] && item[subDisplayField]" class="autocomplete-list-item__sub">
      {{ item[subDisplayField] }}
    </div>
  </div>
</template>

<script>
import { EntityType } from '@/api/photo';
import { PhotoHelper } from '@/modules/applicant-photo-editor/helper';
import BaseIcon from '../icon.vue';
import BaseUserpic from '@/components/userpic/userpic.vue';

export default {
  name: 'VAccount',
  components: {
    BaseUserpic,
    BaseIcon
  },
  props: {
    item: { type: Object, required: true },
    displayField: { type: String, required: true },
    subDisplayField: { type: String, required: true }
  },
  computed: {
    photo() {
      const member = this.item.member;
      return PhotoHelper.getPhotoUrl({
        entity: {
          ...this.item,
          member: typeof member === 'string' ? member.replace('group:', '') : member
        },
        type: EntityType.USER
      });
    },
    hasIcon() {
      return Boolean(this.iconType);
    },
    iconType() {
      if (this.item.isHead) {
        return 'circle-head';
      }

      if (this.item.member) {
        return 'circle-profile';
      }

      // Недорозумение
      // почему то в autocomplete-input в email приходят
      // переговорки
      if (this.item.resource) {
        return 'circle-chair';
      }

      return '';
    },
    prefix() {
      let prefix;
      const { isMe, isMyTeam, isGroup } = this.item;
      if (isMe) {
        prefix = this.$trlMessage('Me') + ',';
      } else if (isMyTeam) {
        prefix = this.$trlMessage('my-team') + ',';
      } else if (isGroup) {
        prefix = this.$trlMessage('team') + ':';
      }
      const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
      return prefix && capitalize(prefix) + ' ';
    }
  }
};
</script>

<style module>
.photo {
  width: 18px;
  height: 18px;
  display: inline-block;
}

.group {
  font-weight: bold;
}

.icon {
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
}
</style>

<i18n lang="json">{}</i18n>
