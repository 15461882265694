<template>
  <page-layout>
    <template #header-title>
      <back-link href="/">
        {{ $trlMessage('header.applicants_and_vacancies') }}
      </back-link>
    </template>
    <template #content>
      <div :class="$style.wrapper">
        <base-title :title="title" :sub-title="subTitle" :show-back="!rootRoute" />
        <div v-if="rootRoute" :class="$style.tabs">
          <base-tabs :pages="orgPages" :is-orgs="true" />
          <base-delimiter top="0" bottom="0" />
          <base-tabs :pages="pages" />
        </div>
        <router-view />
      </div>
    </template>
  </page-layout>
</template>

<script>
import BackLink from '@/components/page-layout/back-link.vue';
import PageLayout from '@/components/page-layout/page-layout.vue';
import BaseTitle from './base-title.vue';
import BaseTabs from './base-tabs.vue';
import BaseDelimiter from '@/components/delimiter/delimiter';
import routesInfo from './constants';

export default {
  name: 'SettingsApp',
  components: { BaseTitle, BaseTabs, PageLayout, BackLink, BaseDelimiter },
  props: {
    pages: {
      type: Array,
      required: true
    },
    orgPages: {
      type: Array,
      required: true
    }
  },
  computed: {
    title() {
      return this.rootRoute ? this.$i18n('settings.title') : routesInfo[this.$route.name].title;
    },
    rootRoute() {
      return this.$route.path === '/';
    },
    subTitle() {
      return this.rootRoute ? null : this.$i18n('settings.subTitle');
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/mixins/font';

.wrapper {
  padding: 0 15px;
  max-width: 90%;
  position: relative;

  composes: col-xs-offset-2 col-xs-9 from global;
}
.tabs {
  display: flex;
  flex-direction: column;
  gap: 49px;
  padding-top: 3px;
  padding-bottom: 131px;
  min-width: 720px;
  max-width: 1100px;
}
</style>

<i18n lang="json">
{
  "settings.title": {
    "ru_RU": "Настройки Хантфлоу",
    "en_US": "Huntflow settings"
  },
  "settings.subTitle": {
    "ru_RU": "Мой Хантфлоу",
    "en_US": "My Huntflow"
  }
}
</i18n>
