<template>
  <div v-if="editor" :class="editorMenuStyle">
    <div>&nbsp;</div>
    <item-button
      name="bold"
      :class="$style.btn"
      :active="editor.isActive('bold')"
      @click="editor.chain().focus().toggleBold().run()"
    />
    <item-button
      name="italic"
      :class="$style.btn"
      :active="editor.isActive('italic')"
      @click="editor.chain().focus().toggleItalic().run()"
    />
    <item-button
      name="ul"
      :class="$style.btn"
      :active="editor.isActive('bulletList')"
      @click="editor.chain().focus().toggleBulletList().run()"
    />
    <item-button
      name="ol"
      :class="$style.btn"
      :active="editor.isActive('orderList')"
      @click="editor.chain().focus().toggleOrderedList().run()"
    />
    <link-menubar-item
      :class="$style.btn"
      :initial-href="getHrefAtCursor()"
      @change="handleInsertLink"
    >
      <template #button="{ onToggle, show }">
        <item-button name="link" :active="editor.isActive('link') || show" @click="onToggle" />
      </template>
    </link-menubar-item>
  </div>
</template>

<script>
import { Link as LinkMenubarItem, ItemButton } from '../components/menubar-items';

export default {
  name: 'VMenubar',
  components: {
    LinkMenubarItem,
    ItemButton
  },
  props: {
    editor: {
      type: Object,
      default: null
    },
    invalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    editorMenuStyle() {
      return {
        [this.$style.menubar]: true,
        [this.$style.menubarDisabled]: this.disabled,
        [this.$style.menubarInvalid]: this.invalid
      };
    }
  },
  methods: {
    getButtonClass(isActive = false) {
      return {
        [this.$style.menubarButtonActive]: isActive,
        [this.$style.menubarButton]: true
      };
    },
    getHrefAtCursor() {
      // этот метод нужен, для того, чтобы если у нас фокус на ссылке
      // при открытии дропдауна "Ссылка" был интерфейс редактирования, а не добавления
      if (!this.editor.isActive('link')) {
        return undefined;
      }
      return this.editor.getAttributes('link').href;
    },
    handleInsertLink(href) {
      if (href) {
        this.editor.chain().focus().extendMarkRange('link').setLinkCustom({ href }).run();
      } else {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
      }
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.menubar {
  display: flex;
  align-items: center;
  border: 1px solid #e3e3e3;
  border-radius: 3px 3px 0px 0px;
  padding: 4px;
  color: #444444;

  & :global(.dropdown) {
    font-size: 0;
    line-height: 0;
    & > * {
      line-height: initial;
    }
  }
}

:global(.form-group_invalid) .menubar, /* простите пожалуйста (уйдет после выброса старых форм) */
.menubar.menubarInvalid,
.menubar.menubarInvalid {
  border-left-color: @errorColor;
  border-top-color: @errorColor;
  border-right-color: @errorColor;
}

.menubar.menubarDisabled {
  pointer-events: none;
}

.btn:not(:last-child) {
  margin-right: 7px;
}
</style>

<i18n lang="json">{}</i18n>
