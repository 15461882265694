<template>
  <div :class="className">
    <div
      :class="[$style.title, showCounter && $style.titleWithCounter]"
      data-qa="sidebar-vacancy-title"
      @click="toggle"
    >
      <button
        type="button"
        data-qa="sidebar-vacancy-item-handle"
        :class="$style.toggleButton"
        @click.stop="toggle"
      />
      <span>{{ vacancy.position }}</span>
      <span v-if="showCounter">{{ countText }}</span>
    </div>
    <div v-show="isExpanded" :class="$style.content">
      <slot name="default" :children="children" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VacancyItemGroup',
  props: {
    vacancy: {
      type: Object,
      required: true
    },
    children: {
      type: Array,
      default: undefined
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    selectedVacancyId: {
      type: Number,
      default: undefined
    }
  },
  emits: ['toggle'],
  computed: {
    isActive() {
      const children = this.children ?? [];
      return (
        this.vacancy.id === this.selectedVacancyId ||
        children.some((child) => child.id === this.selectedVacancyId)
      );
    },
    count() {
      return this.children?.length;
    },
    className() {
      return {
        [this.$style.root]: true,
        [this.$style.expanded]: this.isExpanded,
        [this.$style.active]: this.isActive
      };
    },
    showCounter() {
      return !this.isExpanded;
    },
    countText() {
      if (this.count === 0) {
        return this.$trlMessage('left_menu.multivacancy.no_children');
      }
      return this.$trlMessage('left_menu.multivacancy.children', { count: this.count });
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    }
  }
};
</script>

<style module>
.title {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'button .';

  position: relative;
  left: -5px;

  font-size: $secondaryFontSize;
  line-height: $secondaryLineHeight;
  color: $sidebarTextColor;
  transition: color 0.1s linear;
}

.titleWithCounter {
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'button .'
    'button .';
}

.toggleButton {
  @mixin reset-button-styles;
  position: relative;
  top: 1px;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-area: button;
  color: currentColor;

  &::before {
    content: '';
    position: relative;
    left: 2px;
    border: 4px solid transparent;
    border-left-color: currentColor;
    width: 0;
    height: 0;
    transition: transform 0.2s;
    transform: rotate(0);
    transform-origin: 2px 4px;
  }
}

.root:hover .title {
  color: $sidebarTextColorHover;
}

.expanded .title {
  .toggleButton::before {
    transform: rotate(90deg);
  }
}

.active .title {
  color: $sidebarTextColorActive;
}

.content {
  padding-left: 13px;
}

.title + .content {
  margin-top: 5px;
}
</style>

<i18n lang="json">{}</i18n>
