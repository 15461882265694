<template>
  <ul :class="$style.tabs">
    <base-tab
      v-for="item in routes"
      :key="item.name"
      :class="$style.tab"
      :item="item"
      :is-side-bar="true"
    />
  </ul>
</template>

<script>
import BaseTab from './base-tab';

export default {
  name: 'BaseSidebar',
  components: { BaseTab },
  props: {
    routes: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="less" module>
.tabs {
  display: flex;
  flex-direction: column;
  margin: -18px 0 0 0;
  padding: 0;
}
.tab {
  width: 291px;
  height: 58px;
  display: flex;
  align-items: center;
  margin-left: -20px;
  position: relative;
  &::before {
    position: absolute;
    display: block;
    width: 81%;
    height: 1px;
    top: 57px;
    left: 52px;
    content: '';
    background-color: #e3e3e3;
  }
  &:nth-last-child(1) {
    &::before {
      display: none;
    }
  }
  @media screen and (max-width: 1040px) {
    width: 215px;
    height: 80px;
    &::before {
      top: 79px;
      width: 75%;
    }
  }
}
</style>

<i18n lang="json">{}</i18n>
