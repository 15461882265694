<template>
  <request-view
    :vacancy-request="vacancyRequest"
    :vacancy-request-schema="vacancyRequestSchema"
    :loading="loading"
    data-qa="vacancy_request"
  >
    <template #footer>
      <base-dropdown v-if="canReceiveVacancyRequests && isApproved">
        <template #default="{ toggle }">
          <base-button data-qa="start_work" @click="canCreateVacancies ? handleStart() : toggle()">
            {{ $trlMessage('button.start_work') }}
          </base-button>
        </template>
        <template #content="{ hide }">
          <div :class="$style.warning">
            <div :class="$style.warningText" v-html="$i18n('no_create_vacancy_permission')" />
            <base-button kind="light" data-qa="close" @click="hide">
              {{ $trlMessage('button.close') }}
            </base-button>
          </div>
        </template>
      </base-dropdown>
      <base-button v-if="isRejected" data-qa="retry" @click="handleRetry">
        {{ $trlMessage('button.retry_vacancy_request') }}
      </base-button>
      <base-button kind="light" data-qa="cancel" @click="handleHide">
        {{ $trlMessage('button.close') }}
      </base-button>
      <base-button
        v-if="canReceiveVacancyRequests && isApproved"
        kind="light"
        data-qa="edit"
        @click="handleEdit"
      >
        {{ $trlMessage('button.edit') }}
      </base-button>
      <base-button v-if="nextApproveLink" kind="light" data-qa="copy" @click="copyApproveLink">
        {{ $trlMessage('pending_request.modal.copy_link') }}
      </base-button>
      <rejection
        :id="vacancyRequestId"
        :is-decline="!isWatcher && isApproved"
        :class="$style.rejection"
        @decline="handleHide"
        @remove="handleHide"
      >
        <template #default="{ toggle }">
          <base-button kind="danger" data-qa="delete" @click="toggle">
            <template v-if="canReceiveVacancyRequests && isApproved">
              {{ $trlMessage('button.do_not_start_work') }}
            </template>
            <template v-else>
              {{ $trlMessage('button.remove') }}
            </template>
          </base-button>
        </template>
      </rejection>
    </template>
  </request-view>
</template>

<i18n lang="json">
{
  "no_create_vacancy_permission": {
    "ru_RU": "У&nbsp;вас нет прав создавать и&nbsp;управлять вакансиями. Обратитесь к&nbsp;управляющему рекрутеру",
    "en_US": "You do&nbsp;not have the rights to&nbsp;create and manage vacancies. Contact the managing recruiter"
  }
}
</i18n>

<script>
import { computed } from 'vue';

import { userPermissions } from '@/services/config/user-permissions';
import { VacancyAPI } from '@/api/vacancy';
import BaseButton from '@/components/button/button.vue';
import BaseDropdown from '@/components/ui/base-dropdown/base-dropdown.vue';
import { VacancyRequestStatus } from '@/types/vacancy-request-status';
import RequestView from '../../components/view/view.vue';
import Rejection from './rejection.vue';
import { notification } from '@/services/notification/notification';
import { trlMessage } from '@/services/i18n';

export default {
  name: 'VacancyRequestViewModal',
  components: {
    BaseDropdown,
    RequestView,
    BaseButton,
    Rejection
  },
  props: {
    vacancyRequest: {
      type: Object,
      required: true
    },
    vacancyRequestSchema: {
      type: Object,
      default: null
    },
    loading: Boolean
  },
  emits: ['start', 'retry', 'hide', 'edit'],
  setup(props, { emit }) {
    const { status, next_decision_link } = props.vacancyRequest;
    const isRejected = status === VacancyRequestStatus.REJECTED;
    const isApproved = status === VacancyRequestStatus.APPROVED;

    const vacancyRequestIdRef = computed(() => {
      if (!isRejected) {
        return props.vacancyRequest.id;
      }
      // если заявка отклонена, то в этом компоненте нет её id-шника
      // (он оммитится для логики "отправить ещё раз")
      // но его можно получить из массива states
      return props.vacancyRequest.states?.find((s) => s.vacancy_request_id)?.vacancy_request_id;
    });

    const copyApproveLink = () => {
      navigator.clipboard.writeText(next_decision_link).then(() => {
        notification.notify({
          content: trlMessage('pending_request.modal.notification.link_copied')
        });
      });
    };

    return {
      vacancyRequestId: vacancyRequestIdRef,
      canCreateVacancies: userPermissions.get('can_create_vacancy'),
      canReceiveVacancyRequests: userPermissions.get('can_receive_vacancy_requests'),
      isWatcher: userPermissions.isWatcher,
      nextApproveLink: next_decision_link,
      isRejected,
      isApproved,
      copyApproveLink,
      handleStart: () => {
        const checkHasVacancy = () =>
          VacancyAPI.searchListByFilter({ count: 1, parent: false }).then(
            ({ items }) => items.length > 0
          );
        checkHasVacancy().then((hasVacancy) => {
          emit('start', hasVacancy);
        });
      },
      handleRetry: () => emit('retry'),
      handleHide: () => emit('hide'),
      handleEdit: () => emit('edit')
    };
  }
};
</script>

<style lang="less" module>
.rejection {
  margin-left: auto;
}

.warning {
  width: 350px;
  padding: 10px 15px;
}

.warningText {
  margin-bottom: 15px;
}
</style>
