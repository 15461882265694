<template>
  <a :href="href" :class="className" :title="title" data-qa="sidebar-vacancy-title">
    <div :class="$style.title">
      <span v-if="hasIcons" :class="$style.icons">
        <base-icon
          v-if="isOnHold"
          :class="$style.pause"
          data-qa="icon-pause"
          type="pause"
          width="6"
          height="8"
        />
        <base-icon
          v-if="isHidden"
          data-qa="icon-eye-slash"
          type="eye-slash"
          width="16"
          height="13"
        />
        <base-icon
          v-if="isHighPriority && !isOnHold"
          data-qa="icon-flag"
          type="flag"
          width="16"
          height="13"
        />
        <base-icon v-if="vacancy.multiple" type="multivacancy" />
      </span>
      <span v-if="vacancy.multiple" :class="$style.titleText">
        {{ $trlMessage('title.multivacancy') }}
      </span>
      <span v-else-if="vacancy.parent && shortCompanyName" :class="$style.titleText">
        {{ shortCompanyName }}
      </span>
      <span v-else-if="vacancy.parent && !shortCompanyName" :class="$style.titleText">&nbsp;</span>
      <span v-else-if="!vacancy.multiple && !vacancy.parent" :class="$style.titleText">
        {{ vacancy.position }}
      </span>
    </div>
    <div v-if="!vacancy.multiple && !vacancy.parent && vacancy.company" :class="$style.subtitle">
      <span :class="$style.subtitleText">
        {{ vacancy.company }}
      </span>
    </div>
  </a>
</template>

<script>
import { DateTimeHelper } from '@/util/date-time-helper';
import BaseIcon from '@/components/icon.vue';

export default {
  name: 'VacancyItem',
  components: {
    BaseIcon
  },
  props: {
    vacancy: {
      type: Object,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    companyName: {
      type: String,
      default: ''
    },
    shortCompanyName: {
      type: String,
      default: ''
    }
  },
  computed: {
    className() {
      return {
        [this.$style.root]: true,
        [this.$style.active]: this.isActive,
        [this.$style.deadline]: this.isDead && !this.isOnHold
      };
    },
    title() {
      const { position, parent, company } = this.vacancy;
      return [position, parent ? this.companyName : company].filter(Boolean).join(' - ');
    },
    isDead() {
      if (!this.vacancy.earliest_quota_deadline) {
        return false;
      }
      const deadline = DateTimeHelper.parse({ date: this.vacancy.earliest_quota_deadline });
      return !this.isClosed && DateTimeHelper.now().isAfter(deadline, { unit: 'day' });
    },
    isClosed() {
      return this.vacancy.state === 'CLOSED';
    },
    isOnHold() {
      return this.vacancy.state === 'HOLD';
    },
    isHidden() {
      return this.vacancy.hidden;
    },
    isHighPriority() {
      return this.vacancy.priority;
    },
    hasIcons() {
      return this.isOnHold || this.isHidden || this.isHighPriority || this.vacancy.multiple;
    }
  }
};
</script>

<style module>
.root {
  display: block;
  white-space: nowrap;
  font-size: $secondaryFontSize;
  line-height: $secondaryLineHeight;
  text-decoration: none;
  cursor: default;
  color: $sidebarTextColor;
  transition: color 0.1s linear;
}

.active.active {
  color: $sidebarTextColorActive;
}

.deadline {
  color: $redColor;
}

.root:hover,
.deadline:hover {
  color: $sidebarTextColorHover;
}

.title,
.subtitle {
  display: flex;
}

.icons {
  position: relative;
  top: -1px;
  flex: none;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  margin-right: 7px;

  .pause {
    margin: 2.5px -2px 0 2px;
  }
}

.titleText,
.subtitleText {
  @mixin ellipsis;
}

.active .titleText,
.active .subtitleText {
  background: $sidebarTextBackgroundActive;
}
</style>

<i18n lang="json">{}</i18n>
