<template>
  <div
    :class="$style.news"
    :style="{
      background: theme.background,
      color: theme.textColor
    }"
    @click="$emit('open')"
  >
    <button type="button" :class="$style.openButton">{{ $trlMessage('news.toggle') }}</button>
    <div :class="$style.animate"></div>
    <div :class="$style.count">{{ totalCount }}</div>
    <div :class="$style.wrapper">
      <LightningIcon :class="$style.icon" :color="theme.iconColor" width="20" height="33" />
      <div :class="$style.content">
        <p :class="$style.title" v-html="currentNews.title"></p>
        <div :class="$style.controls">
          <p :class="$style.showMore">{{ $trlMessage('news.toggle') }}</p>
          <button
            type="button"
            :style="{
              color: hideButtonColor
            }"
            :class="$style.hideButton"
            @click.stop="$emit('close')"
            >{{ $trlMessage('button.hide') }}</button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LightningIcon from '@/modules/news/components/lightning-icon.vue';
import { colorRelativeLightness } from '@/modules/news/helpers';

export default {
  name: 'NewsThumbnail',
  components: { LightningIcon },
  props: {
    theme: {
      type: Object,
      required: true
    },
    currentNews: {
      type: Object,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    }
  },
  emits: ['open', 'close'],
  computed: {
    hideButtonColor() {
      return colorRelativeLightness(this.theme.background) > 0.5 ? '#000' : '#FFF';
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/mixins/font';
@import '~@less/common/variables';

.news {
  width: 390px;
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 5px;
  position: relative;
  perspective: 800px;
  z-index: 1;
  transition: perspective 0.5s;

  &:hover {
    perspective: 400px;
  }
}

.wrapper {
  position: relative;
  display: flex;
}

.openButton {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  background: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  text-indent: -9999px;
  z-index: 2;
  appearance: none;
  margin: 0;
}

.animate {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 5px;
  background: inherit;
  z-index: 0;
  cursor: pointer;
  animation: pulseAnimation 0.5s ease-in-out infinite alternate;
}

@keyframes pulseAnimation {
  from {
    transform: translateZ(0px);
  }
  to {
    transform: translateZ(25px);
  }
}

.count {
  position: absolute;
  width: 34px;
  line-height: 34px;
  border-radius: 50%;
  font-size: 16px;
  color: #fff;
  .font_bold();
  background: #ff0035;
  text-align: center;
  right: -17px;
  top: -17px;
  cursor: pointer;
}

.content {
  flex: 1;
}

.title {
  font-size: 18px;
  .font_bold();
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 10px;
}

.icon {
  margin-right: 20px;
  margin-top: 5px;
  display: block;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.showMore {
  font-size: @secondaryFontSize;
  line-height: @secondaryLineHeight;
  margin: 0;
}

.hideButton {
  appearance: none;
  background: none;
  border: none;
  font-size: @secondaryFontSize;
  line-height: @secondaryLineHeight;
  text-align: right;
  opacity: 0.5;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: opacity ease-in-out 150ms;
  position: relative;
  z-index: 4;
  &:hover {
    opacity: 1;
  }
  &::after {
    content: ' ';
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
  }
}
</style>

<i18n lang="json">{}</i18n>
