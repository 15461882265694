<template>
  <dynamic-scroller-item :item="item" active :index="index" :data-index="index">
    <slot :item="item" :highlight="highlight" :previous-item="previousItem" :next-item="nextItem" />
  </dynamic-scroller-item>
</template>

<script>
import { DynamicScrollerItem } from 'vue-virtual-scroller';

export default {
  name: 'BaseItemWrapper',
  components: { DynamicScrollerItem },
  props: {
    item: {
      type: Object,
      required: true
    },
    previousItem: {
      type: Object,
      default: () => ({})
    },
    nextItem: {
      type: Object,
      default: () => ({})
    },
    highlight: Boolean,
    index: {
      type: Number,
      default: undefined
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
