<template>
  <base-dropdown>
    <template #default="{ toggle }">
      <base-button kind="light" @click="toggle">
        {{ $trlMessage('button.customize_form_fields') }}
      </base-button>
    </template>
    <template #content>
      <dropdown-menu-layout size="big" :class="$style.dropdownContent">
        <template #heading>
          <img
            src="./icon/4sales-small-icon.svg"
            alt="customize form fields"
            :class="$style.image"
          />

          <h4 :class="$style.title">
            {{ $trlMessage('in-app-advertisement.vacancy-request-fields.title') }}
          </h4>

          <p :class="$style.item">
            {{ $trlMessage('in-app-advertisement.vacancy-request-fields.description.1') }}
          </p>
          <p :class="$style.item">
            {{ $trlMessage('in-app-advertisement.vacancy-request-fields.description.2') }}
          </p>
        </template>
        <template #footer-layout>
          <p :class="$style.footerInfo">
            {{ $trlMessage('in-app-advertisement.vacancy-request-fields.footer.title') }}
          </p>
        </template>
      </dropdown-menu-layout>
    </template>
  </base-dropdown>
</template>

<script>
import BaseButton from '@/components/button/button.vue';
import BaseDropdown from '@/components/ui/base-dropdown/base-dropdown.vue';
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout.vue';

export default {
  name: 'CustomizeFormButton',
  components: { BaseButton, BaseDropdown, DropdownMenuLayout }
};
</script>

<style module lang="less">
@import '~@less/common/variables';
@import '~@less/common/mixins/font';

.image {
  margin-bottom: 15px;
}
.title {
  color: @violetColor;
  .font_bold();
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}
.item {
  .font_regular();
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 5px;
}
.footerInfo {
  color: @textColor;
  .font_bold();
  font-size: @secondaryFontSize;
  line-height: @secondaryLineHeight;
  margin: 0;
}
.dropdownContent {
  padding: 10px 15px;
}
</style>

<i18n lang="json">{}</i18n>
