<template>
  <svg v-if="name === 'bold'" width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.2 6a.2.2 0 00-.2.2v15.6c0 .11.09.2.2.2h8.3c1.58 0 2.729-.451 3.478-1.252C21.722 19.954 22 19.079 22 18v-1c0-.871-.341-1.708-1.147-2.549-.5-.523-1.168-.95-2.02-1.202.192-.152.358-.323.499-.509.551-.726.668-1.74.668-2.209V9.5c0-.82-.196-1.706-.796-2.392C18.594 6.41 17.634 6 16.281 6H9.2zm2 8a.2.2 0 00-.2.2v6.6c0 .11.09.2.2.2h5.3c1.5 0 2.24-.392 2.748-.935.515-.55.752-1.144.752-2.065v-1c0-.625-.252-1.214-.869-1.857C18.525 14.51 17.5 14 15.578 14H11.2zm6.335-1.865c-.343.451-.984.865-2.254.865H11.2a.2.2 0 01-.2-.2V7.2c0-.11.09-.2.2-.2h4.081c1.147 0 1.797.34 2.17.767.385.44.549 1.054.549 1.733v1.031c0 .512-.102 1.127-.465 1.604z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'italic'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 6.5a.5.5 0 01.5-.5H19a.5.5 0 010 1h-3.36l-3.5 14h3.36a.5.5 0 010 1H8a.5.5 0 010-1h3.11l3.5-14H11.5a.5.5 0 01-.5-.5z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'font-size'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.136 21.686L12.262 17H4.6l-1.938 4.69a.5.5 0 11-.924-.38L7.803 6.626a.75.75 0 011.39.008l5.871 14.68a.5.5 0 11-.928.37zM8.305 8.032a.2.2 0 01.37.002L11.861 16H5.013l3.292-7.968zM21.5 11c-.698 0-1.4.117-1.981.291-.237.071-.493.199-.742.322-.473.236-.915.456-1.13.24-.711-.71 1.223-1.411 1.584-1.52A8.077 8.077 0 0121.5 10c2.661 0 4.5 1.839 4.5 4.5l.003 1.63c.003 1.115.006 2.231-.004 3.347l-.002.136c-.008.377-.019.878.243 1.147.087.088.206.15.322.211.217.114.423.221.383.487-.037.252-.247.393-.523.393-.402 0-.654-.144-.897-.393-.285-.29-.468-.756-.505-1.163a4.403 4.403 0 01-.805.683C23.177 21.67 21.825 22 20.5 22c-2.031 0-3.5-1.455-3.5-3.5 0-2.418 2.39-3.5 4.5-3.5 1.585 0 2.744.379 3.5.958V14.5c0-2.118-1.382-3.5-3.5-3.5zm3.616 7.097a2.053 2.053 0 01-.007-.144C25.11 16.681 23.632 16 21.5 16c-1.54 0-3.5.66-3.5 2.5 0 1.476 1.031 2.479 2.703 2.479 1.832 0 4.406-1.013 4.406-2.627.018-.091.012-.17.007-.255z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'text-color'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5 6.5A.5.5 0 015 6h12.5a.5.5 0 010 1H12v14.5a.5.5 0 01-1 0V7H5a.5.5 0 01-.5-.5z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.227 15.15c.127-.2.42-.2.546 0a31.111 31.111 0 001.15 1.666c.328.456.654.908.944 1.354.633.97 1.133 1.96 1.133 2.975 0 .905-.455 1.652-1.114 2.164-.655.51-1.522.797-2.386.797-.864 0-1.73-.287-2.386-.797C17.455 22.797 17 22.05 17 21.145c0-1.016.5-2.005 1.133-2.975a36.152 36.152 0 011.039-1.485c.361-.502.724-1.011 1.055-1.534z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M20.5 15.65c.298.455.613.897.923 1.327l.094.131c.33.458.648.9.931 1.335.625.959 1.052 1.842 1.052 2.702 0 .723-.36 1.333-.92 1.77-.563.437-1.32.691-2.08.691s-1.517-.254-2.08-.692c-.56-.436-.92-1.046-.92-1.77 0-.86.427-1.742 1.052-2.7.283-.435.601-.878.931-1.336l.094-.13c.31-.43.625-.873.923-1.328z"
      stroke="#000"
      stroke-opacity=".12"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="19"
        y1="15.5"
        x2="22.5"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7D05" />
        <stop offset=".295" stop-color="#FFC805" />
        <stop offset=".631" stop-color="#D1FF6D" />
        <stop offset=".938" stop-color="#6BBDD3" />
      </linearGradient>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'ul'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 7.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-.8 0a.7.7 0 11-1.4 0 .7.7 0 011.4 0z"
      fill="currentColor"
    />
    <path d="M10.5 7a.5.5 0 000 1h12a.5.5 0 000-1h-12z" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 21a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0-.8a.7.7 0 100-1.4.7.7 0 000 1.4zM8 13.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-.8 0a.7.7 0 11-1.4 0 .7.7 0 011.4 0z"
      fill="currentColor"
    />
    <path
      d="M10 13.5a.5.5 0 01.5-.5h12a.5.5 0 010 1h-12a.5.5 0 01-.5-.5zM10.5 19a.5.5 0 000 1h12a.5.5 0 000-1h-12z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'ol'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.724 5.169A.4.4 0 016.9 5.5v3.4c0 .11.09.2.2.2h.181a.4.4 0 110 .8H5.125a.4.4 0 110-.8H5.9a.2.2 0 00.2-.2V6.386a.2.2 0 00-.274-.186l-.896.359a.4.4 0 01-.297-.743L6.35 5.13a.4.4 0 01.373.04zM10.5 7a.5.5 0 100 1h12a.5.5 0 000-1h-12zM5.969 11.844c-.448 0-.823.27-.921.542a.4.4 0 01-.752-.272c.178-.494.699-1.03 1.532-1.03.475 0 .908.06 1.223.29.349.257.458.646.458 1.063 0 .455-.228.808-.495 1.08-.25.255-.572.479-.858.677l-.133.09c-.193.133-.391.267-.562.426-.118.11-.031.286.13.286h1.69a.4.4 0 010 .8h-2.61a.4.4 0 01-.4-.4c0-.464.222-.828.49-1.113.263-.277.604-.514.902-.72l.004-.003c.313-.217.58-.403.776-.602.19-.195.266-.358.266-.52 0-.368-.282-.594-.74-.594z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.095 18.199c.016-.045.061-.107.192-.163.132-.057.32-.092.541-.092.186 0 .4.06.557.164.15.1.215.217.215.345 0 .25-.054.38-.103.44-.037.046-.105.098-.278.098a.4.4 0 000 .8c.182 0 .318.004.425.019a.422.422 0 01.173.05c.012.008.095.064.095.453a.768.768 0 01-.096.41.59.59 0 01-.231.21c-.214.117-.505.151-.757.151-.2 0-.413-.062-.572-.146a.674.674 0 01-.146-.1.4.4 0 00-.791.084c0 .212.12.372.208.464.098.102.223.189.353.258.26.138.601.24.948.24.295 0 .747-.035 1.138-.247.202-.11.393-.27.533-.498.14-.23.213-.506.213-.826 0-.463-.093-.875-.447-1.115l-.014-.01a1.67 1.67 0 00.149-.735c0-.465-.263-.804-.57-1.01a1.85 1.85 0 00-1.002-.299c-.294 0-.596.045-.857.157-.263.113-.514.31-.628.625a.4.4 0 00.752.273z"
      fill="currentColor"
    />
    <path
      d="M10 13.5a.5.5 0 01.5-.5h12a.5.5 0 010 1h-12a.5.5 0 01-.5-.5zM10.5 19a.5.5 0 100 1h12a.5.5 0 000-1h-12z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'image'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.82 18.792a.1.1 0 00.18-.06v-1.173a.6.6 0 00-.119-.359l-2.5-3.355a1.1 1.1 0 00-1.762-.003l-1.827 2.436a.5.5 0 01-.754.053l-.685-.685a1.1 1.1 0 00-1.671.137l-2.799 3.9a.2.2 0 00.163.317h.533a.6.6 0 00.488-.25l2.427-3.384a.1.1 0 01.152-.013l.685.685a1.5 1.5 0 002.26-.16l1.828-2.436a.1.1 0 01.16 0l3.24 4.35z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 10.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-.8 0a.7.7 0 11-1.4 0 .7.7 0 011.4 0z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.7 5A1.7 1.7 0 005 6.7v13.6A1.7 1.7 0 006.7 22h14.6a1.7 1.7 0 001.7-1.7V6.7A1.7 1.7 0 0021.3 5H6.7zM6 6.7a.7.7 0 01.7-.7h14.6a.7.7 0 01.7.7v13.6a.7.7 0 01-.7.7H6.7a.7.7 0 01-.7-.7V6.7z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'link'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.157 6.55a1.9 1.9 0 012.686 0l1.606 1.607a1.9 1.9 0 010 2.687l-3.803 3.803a.207.207 0 01-.292 0l-.495.494c-.118.118-.119.312.023.397a1.208 1.208 0 001.472-.184l3.803-3.803a2.9 2.9 0 000-4.102L20.55 5.844a2.9 2.9 0 00-4.102 0l-3.803 3.802a1.207 1.207 0 00-.184 1.471c.085.143.28.142.397.024l.495-.495a.207.207 0 010-.292l3.803-3.803zM10.844 21.45a1.9 1.9 0 01-2.687 0L6.55 19.842a1.9 1.9 0 010-2.686l4.303-4.303c.08-.081.212-.081.293 0l.494-.495c.118-.118.119-.312-.024-.396a1.207 1.207 0 00-1.47.184l-4.303 4.302a2.9 2.9 0 000 4.102l1.605 1.606a2.9 2.9 0 004.102 0l4.303-4.303c.399-.4.46-1.008.184-1.471-.085-.143-.28-.142-.397-.024l-.495.495c.081.08.081.212 0 .293l-4.303 4.302z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.702 11.995a1.7 1.7 0 00-2.404 0l-1.303 1.303a1.7 1.7 0 000 2.404l.303.303a1.7 1.7 0 002.404 0l1.303-1.303a1.7 1.7 0 000-2.404l-.303-.303zm-1.697.707a.7.7 0 01.99 0l.303.303a.7.7 0 010 .99l-1.303 1.303a.7.7 0 01-.99 0l-.303-.303a.7.7 0 010-.99l1.303-1.303z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'quote'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 8.7A1.7 1.7 0 0011.3 7H6.7A1.7 1.7 0 005 8.7V16c0 2.81 2.26 5 5.5 5a.5.5 0 000-1C7.74 20 6 18.19 6 16v-1.15c.213.096.45.15.7.15h4.6a1.7 1.7 0 001.7-1.7V8.7zm-7 4.6a.7.7 0 00.7.7h4.6a.7.7 0 00.7-.7V8.7a.7.7 0 00-.7-.7H6.7a.7.7 0 00-.7.7v4.6zM21.3 7A1.7 1.7 0 0123 8.7v4.6a1.7 1.7 0 01-1.7 1.7h-4.6c-.25 0-.486-.054-.7-.15V16c0 2.19 1.74 4 4.5 4a.5.5 0 010 1c-3.24 0-5.5-2.19-5.5-5V8.7A1.7 1.7 0 0116.7 7h4.6zM16 13.3a.7.7 0 00.7.7h4.6a.7.7 0 00.7-.7V8.7a.7.7 0 00-.7-.7h-4.6a.7.7 0 00-.7.7v4.6z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'hr'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 14.5a.5.5 0 01.5-.5h17a.5.5 0 010 1h-17a.5.5 0 01-.5-.5z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'table'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.7 5A1.7 1.7 0 006 6.7v13.6A1.7 1.7 0 007.7 22h13.6a1.7 1.7 0 001.7-1.7V6.7A1.7 1.7 0 0021.3 5H7.7zM7 14v6.3a.7.7 0 00.7.7H14v-7H7zm8 0v7h6.3a.7.7 0 00.7-.7V14h-7zm7-1h-7V6h6.3a.7.7 0 01.7.7V13zm-8 0H7V6.7a.7.7 0 01.7-.7H14v7z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'table-del'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 24L22.72 3H24L6.28 24H5zM19.24 5a.2.2 0 01.153.328l-.5.6A.2.2 0 0118.74 6H15v4.328a.2.2 0 01-.046.128l-.6.72a.2.2 0 01-.354-.128V6H7.7a.7.7 0 00-.7.7V13h5.406a.2.2 0 01.154.328l-.5.6a.2.2 0 01-.154.072H7v5.728a.2.2 0 01-.046.128l-.63.755c-.099.119-.285.083-.307-.07A1.715 1.715 0 016 20.3V6.7A1.7 1.7 0 017.7 5h11.54zM22 7.272a.2.2 0 01.046-.128l.63-.755c.099-.119.285-.083.307.07.011.079.017.16.017.241v13.6a1.7 1.7 0 01-1.7 1.7H9.76a.2.2 0 01-.153-.328l.5-.6A.2.2 0 0110.26 21H14v-4.328a.2.2 0 01.046-.128l.6-.72a.2.2 0 01.354.128V21h6.3a.7.7 0 00.7-.7V14h-5.406a.2.2 0 01-.154-.328l.5-.6a.2.2 0 01.154-.072H22V7.272z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'table-col-before'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.3 22a1.7 1.7 0 001.7-1.7V6.7A1.7 1.7 0 0022.3 5h-5.6A1.7 1.7 0 0015 6.7v13.9a1.4 1.4 0 001.4 1.4h5.9zM16.7 6a.7.7 0 00-.7.7V13h7V6.7a.7.7 0 00-.7-.7h-5.6zM16 20.3V14h7v6.3a.7.7 0 01-.7.7h-5.6a.7.7 0 01-.7-.7z"
      fill="currentColor"
    />
    <path
      d="M9 17.5a.5.5 0 00-1 0V19H6.5a.5.5 0 000 1H8v1.5a.5.5 0 001 0V20h1.5a.5.5 0 000-1H9v-1.5z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 14a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM4 19.5a4.5 4.5 0 109 0 4.5 4.5 0 00-9 0z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'table-col-after'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.7 22A1.7 1.7 0 014 20.3V6.7A1.7 1.7 0 015.7 5h5.6A1.7 1.7 0 0113 6.7v13.9a1.4 1.4 0 01-1.4 1.4H5.7zm5.6-16a.7.7 0 01.7.7V13H5V6.7a.7.7 0 01.7-.7h5.6zm.7 14.3V14H5v6.3a.7.7 0 00.7.7h5.6a.7.7 0 00.7-.7z"
      fill="currentColor"
    />
    <path
      d="M19 17.5a.5.5 0 011 0V19h1.5a.5.5 0 010 1H20v1.5a.5.5 0 01-1 0V20h-1.5a.5.5 0 010-1H19v-1.5z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5 14a5.5 5.5 0 110 11 5.5 5.5 0 010-11zm4.5 5.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'table-del-col'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.038 7l-13.5 16h1.28l13.5-16h-1.28zM10.7 5A1.7 1.7 0 009 6.7v11.338a.1.1 0 00.175.066l.751-.862a.3.3 0 00.074-.197V14h2.614a.3.3 0 00.226-.103l.637-.731A.1.1 0 0013.4 13H10V6.7a.7.7 0 01.7-.7h5.6a.7.7 0 01.7.7v1.681a.1.1 0 00.175.066l.751-.862A.3.3 0 0018 7.388V6.7A1.7 1.7 0 0016.3 5h-5.6zm-.3 17a.006.006 0 01-.005-.01l.74-.883a.3.3 0 01.23-.107H16.3a.7.7 0 00.7-.7v-6.075a.3.3 0 01.07-.193l.753-.898a.1.1 0 01.177.064V20.3a1.7 1.7 0 01-1.7 1.7h-5.9z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'table-row-after'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 17.5a.5.5 0 00-1 0V19H6.5a.5.5 0 000 1H8v1.5a.5.5 0 001 0V20h1.5a.5.5 0 000-1H9v-1.5z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 14a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM4 19.5a4.5 4.5 0 109 0 4.5 4.5 0 00-9 0zM9.7 13A1.7 1.7 0 018 11.3V5.7A1.7 1.7 0 019.7 4h13.6A1.7 1.7 0 0125 5.7v5.9a1.4 1.4 0 01-1.4 1.4H9.7zM9 11.3a.7.7 0 00.7.7H16V5H9.7a.7.7 0 00-.7.7v5.6zm14.3.7H17V5h6.3a.7.7 0 01.7.7v5.6a.7.7 0 01-.7.7z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'table-row-before'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 11a.5.5 0 00.5-.5V9h1.5a.5.5 0 000-1H9V6.5a.5.5 0 00-1 0V8H6.5a.5.5 0 000 1H8v1.5a.5.5 0 00.5.5z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 8.5a5.5 5.5 0 1011 0 5.5 5.5 0 00-11 0zM8.5 4a4.5 4.5 0 100 9 4.5 4.5 0 000-9zM9.7 15A1.7 1.7 0 008 16.7v5.6A1.7 1.7 0 009.7 24h13.6a1.7 1.7 0 001.7-1.7v-5.9a1.4 1.4 0 00-1.4-1.4H9.7zM9 16.7a.7.7 0 01.7-.7H16v7H9.7a.7.7 0 01-.7-.7v-5.6zm8 6.3v-7h6.3a.7.7 0 01.7.7v5.6a.7.7 0 01-.7.7H17z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'table-del-row'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 22L18.5 6h1.281l-13.5 16H5zM13.054 10a.1.1 0 01.076.165l-.662.782a.15.15 0 01-.114.053H7.7a.7.7 0 00-.7.7v5.6c0 .095.019.186.053.268a.11.11 0 01-.014.114l-.509.6c-.06.07-.167.072-.22-.004A1.692 1.692 0 016 17.3v-5.6A1.7 1.7 0 017.7 10h5.354zM14 14.855a.1.1 0 01.024-.065l.8-.945a.1.1 0 01.176.064V18h6.3a.7.7 0 00.7-.7v-5.6a.7.7 0 00-.7-.7h-3.854a.1.1 0 01-.076-.165l.677-.8a.1.1 0 01.076-.035H21.3a1.7 1.7 0 011.7 1.7v5.9a1.4 1.4 0 01-1.4 1.4H10.946a.1.1 0 01-.076-.165l.677-.8a.1.1 0 01.076-.035H14v-3.145z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'table-join-cells'"
    width="28"
    height="28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 20h1v-2h-1v2zM14 16h1v-2h-1v2zM14 12h1v-2h-1v2z" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 6.7A1.7 1.7 0 017.7 5h13.6A1.7 1.7 0 0123 6.7v13.6a1.7 1.7 0 01-1.7 1.7H7.7A1.7 1.7 0 016 20.3V6.7zm1 13.6V6.7a.7.7 0 01.7-.7H14v2h1V6h6.3a.7.7 0 01.7.7v13.6a.7.7 0 01-.7.7H7.7a.7.7 0 01-.7-.7z"
      fill="currentColor"
    />
  </svg>

  <svg
    v-else-if="name === 'table-cell-border'"
    width="28"
    height="28"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M13.5 9.5h1v2h-1v-2zm1 6h-1v2h1v-2zM10 14v-1h2v1h-2zm6-1v1h2v-1h-2zm-1.5 0h-1v1h1v-1z"
    />
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M5.5 6.7A1.7 1.7 0 017.2 5h13.6a1.7 1.7 0 011.7 1.7v13.6a1.7 1.7 0 01-1.7 1.7H7.2a1.7 1.7 0 01-1.7-1.7V6.7zm9-.7h6.3a.7.7 0 01.7.7V13h-2v1h2v6.3a.7.7 0 01-.7.7h-6.3v-2h-1v2H7.2a.7.7 0 01-.7-.7V14h2v-1h-2V6.7a.7.7 0 01.7-.7h6.3v2h1V6z"
      clip-rule="evenodd"
    />
  </svg>

  <span v-else></span>
</template>

<script>
export default {
  name: 'VIcon',
  props: {
    name: {
      type: String,
      required: true
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
