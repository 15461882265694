<template>
  <base-row :class="className">
    <div :class="$style.main">
      <slot name="default" />
    </div>
    <div :class="$style.side">
      <slot name="side" />
    </div>
  </base-row>
</template>

<script>
import BaseRow from './row';

export default {
  name: 'LayoutTwoColumnBlock',
  components: {
    BaseRow
  },
  props: {
    withSeparator: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    className() {
      return {
        [this.$style.root]: true,
        [this.$style.withSeparator]: this.withSeparator
      };
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.root {
  display: flex;
  gap: 40px;
}

.main {
  flex: 1;
  min-width: 0;
}

.side {
  flex-basis: 240px;
  flex-grow: 0;
  min-width: 0;
  position: relative;
}

.withSeparator .side {
  &::before {
    position: absolute;
    left: -21px;
    top: 0;
    bottom: 0;
    border-right: 1px solid @borderColor;
    content: '';
  }
}
</style>

<i18n lang="json">{}</i18n>
