<template>
  <div data-qa="pin-code">
    <div :class="$style.pinCodeContainer">
      <input
        v-for="(_, index) in pinArray"
        :key="index"
        ref="digitInput"
        v-model="pinArray[index]"
        type="text"
        :disabled="disabled"
        :class="classes"
        @input="handleInput($event, index)"
        @paste="handlePaste($event, index)"
        @keydown="handleKeydown($event, index)"
        @focus="handleFocus($event, index)"
        @keydown.enter="handleEnter"
      />
    </div>
    <div v-if="invalidate" :class="$style.wrong" data-qa="error" v-html="errorMessage" />
  </div>
</template>

<script>
export default {
  name: 'PinCodeInput',
  props: {
    initialPinCode: {
      type: String,
      default: ''
    },
    length: {
      type: Number,
      required: false,
      default: 6,
      validator(value) {
        return value > 0;
      }
    },
    invalidate: {
      type: Boolean,
      required: false
    },
    timeout: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    pinErrorMessage: {
      type: String,
      default: ''
    }
  },
  emits: ['complete', 'not-complete'],
  data() {
    return {
      pinArray: Array(this.length).fill(''),
      focusField: 0
    };
  },
  computed: {
    errorMessage() {
      if (this.pinErrorMessage) {
        return this.$i18n(this.pinErrorMessage);
      }
      if (this.timeout) {
        return this.$i18n('pin.wrong.time', { time: this.timeout });
      }
      return this.pinArray.includes('')
        ? this.$i18n('required')
        : this.$i18n('wrong_verification_code');
    },
    classes() {
      return {
        [this.$style.digitInput]: true,
        [this.$style.invalidate]: this.invalidate,
        [this.$style.withTimeout]: Boolean(this.timeout)
      };
    }
  },
  watch: {
    disabled: {
      handler(value) {
        if (value) {
          return;
        }
        setTimeout(() => {
          this.$refs.digitInput[0].focus();
        }, 100);
      },
      immediate: true
    },
    timeout(value) {
      if (value) {
        this.pinArray.fill('');
      }
    },
    invalidate() {
      if (this.invalidate) {
        this.pinArray = Array(this.length).fill('');
      }
    }
  },
  mounted() {
    if (this.initialPinCode && !this.invalidate) {
      this.pinArray = this.initialPinCode.split('');
      this.focusField = this.pinArray.length - 1;
      this.emitInput();
    }
  },
  methods: {
    handleInput(event, index) {
      const value = event.data ? event.data.replace(/[^0-9]/g, '') : '';
      const key = event.inputType;

      if (value) {
        this.pinArray.splice(index, 1, value[0]);
        this.setFocus(index);
      } else if (key !== 'deleteContentBackward') {
        this.pinArray.splice(index, 1, '');
      }
      this.emitInput();
    },
    handlePaste(event, index) {
      event.preventDefault();
      if (this.disabled) {
        return;
      }
      const pasteData = (event.clipboardData || window.clipboardData)
        .getData('text')
        .replace(/[^0-9]/g, '')
        .slice(0, this.length - index);

      for (let i = 0; i < pasteData.length; i += 1) {
        this.pinArray.splice(index + i, 1, pasteData[i]);
      }

      this.$nextTick(() => {
        const nextIndex = index + pasteData.length - 1;
        if (!pasteData.length) {
          return;
        }
        if (nextIndex >= this.length - 1) {
          this.$refs.digitInput[index].blur();
        } else {
          this.$refs.digitInput[nextIndex].focus();
        }
        this.emitInput(nextIndex >= this.length - 1);
      });
    },
    setFocus(index) {
      if (index < this.length - 1) {
        this.$refs.digitInput[index + 1].focus();
      } else if (index >= this.length - 1) {
        this.$refs.digitInput[index].blur();
      }
    },
    handleKeydown(event, index) {
      const key = event.key;

      if (key === 'Backspace' && index > 0) {
        event.preventDefault();
        if (this.pinArray[index] === '') {
          this.$refs.digitInput[index - 1].focus();
          this.pinArray.splice(index - 1, 1, '');
        } else {
          this.pinArray.splice(index, 1, '');
        }
      } else if (key === 'ArrowLeft' && index > 0) {
        this.$refs.digitInput[index - 1].focus();
      } else if (key === 'ArrowRight' && index < this.length - 1) {
        this.$refs.digitInput[index + 1].focus();
      }
    },
    /**
     * Принудительно отправлять событие
     * @param emit
     */
    emitInput(emit = false) {
      if (
        (this.pinArray.length === this.length &&
          !this.pinArray.includes('') &&
          this.focusField === this.length - 1) ||
        emit
      ) {
        this.$emit('complete', this.pinArray.join(''));
        return;
      }
      this.$emit(
        'not-complete',
        this.pinArray.length === this.length && !this.pinArray.includes('')
          ? this.pinArray.join('')
          : ''
      );
    },
    handleFocus(event, index) {
      const target = event.target;
      this.focusField = index;
      setTimeout(() => {
        target.setSelectionRange(1, 1);
      }, 10);
    },
    handleEnter() {
      this.$emit('complete', this.pinArray.join(''));
    }
  }
};
</script>

<style module>
.pinCodeContainer {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.digitInput {
  width: 43px;
  height: 62px;
  text-align: center;
  font-size: 32px;
  border: 1px solid #d8d7e0;
  border-radius: 6px;
  background-color: #f6f6f8;
  font-weight: 400;
  color: $vantaBlackColor;
  padding: 16px 0 14px;

  &:focus {
    outline: none;
    border-color: #29a0ba;
    box-shadow: 0 0 0 3px #c4edfa;
  }
}

.invalidate {
  border-color: $redColor;

  &:focus {
    border-color: $redColor;
    box-shadow: 0 0 0 3px #f4cbcf;
  }
}

.withTimeout {
  border-color: transparent;
}

.wrong {
  color: $redColor;
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  span {
    min-width: 50px;
    text-align: left;
    display: inline-block;
  }
}
</style>

<i18n lang="json">
{
  "wrong_verification_code": {
    "ru_RU": "Неправильный код",
    "en_US": "Invalid code"
  },
  "pin.wrong.time": {
    "ru_RU": "Превышено число&nbsp;попыток.<br\/>Следующая попытка через '<span>'{ time }'</span>'",
    "en_US": "The maximum number of login attempts <br\/>has been reached. Next attempt in '<span>'{ time }'</span>'"
  },
  "verification_code_expired": {
    "ru_RU": "Срок действия кода истек",
    "en_US": "The code has expired"
  },
  "required": {
    "ru_RU": "Обязательное поле",
    "en_US": "This field is required"
  }
}
</i18n>
