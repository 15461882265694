<template>
  <link-button
    v-if="showPriceLink"
    :to="`${cloudRegionHost}/price`"
    target="_blank"
    kind="light"
    data-qa="tariff-description"
  >
    {{ $trlMessage('promo.details') }}
  </link-button>
</template>

<script>
import { appConfig } from '@/services/config/app-config';
import tariffs from '@/services/tariffs';
import { LinkButton } from '@/components';

export default {
  name: 'PriceLink',
  components: {
    LinkButton
  },
  props: {
    serviceKindCode: {
      type: String,
      required: true
    }
  },
  computed: {
    cloudRegionHost: () => appConfig.cloudRegionHost,
    isDemo() {
      return tariffs.isDemo;
    },
    isTariffDisabled() {
      return !tariffs.isTariffAvailable(this.serviceKindCode);
    },
    showPriceLink() {
      return this.isDemo || this.isTariffDisabled;
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
