export enum VirtualStatusId {
  WORKON = 'workon',
  RESPONSE = 'response',
  RECOMMENDATION = 'recommendation',
  CLOSED = 'closed',
  ALL = 'all'
}

export enum StatusType {
  USER = 'user',
  HIRED = 'hired',
  TRASH = 'trash'
}

interface VirtualStatus {
  readonly id: VirtualStatusId;
  readonly name: string;
  readonly order: number;
  readonly virtual: boolean;
}

interface RealStatus {
  readonly id: number;
  readonly account: number;
  readonly name: string;
  readonly order: number;
  readonly removed: null | string;
  readonly stay_duration: null | number;
  readonly type: StatusType;
}

type Status = VirtualStatus | RealStatus;

function isVirtualStatus(status: Status): status is VirtualStatus {
  if ((status as any).virtual) {
    return true;
  }
  return false;
}

export class StatusService {
  #items: Status[];

  constructor(items: Status[]) {
    this.#items = items;
  }

  public getStatusById(id: VirtualStatusId | number) {
    return this.#findStatus((item) => item.id === id);
  }

  get trashStatus() {
    return this.#getFirstOfType(StatusType.TRASH);
  }

  get hiredStatus() {
    return this.#getFirstOfType(StatusType.HIRED);
  }

  get list() {
    return this.#items
      .filter((item) => (isVirtualStatus(item) ? true : !item.removed))
      .sort((a, b) => a.order - b.order);
  }

  #getFirstOfType(type: StatusType) {
    return this.#findStatus((item) => {
      return isVirtualStatus(item) ? false : (item as RealStatus).type === type;
    });
  }

  #findStatus(predicate: (any: Status) => boolean) {
    return this.#items.find(predicate);
  }
}

declare global {
  interface Window {
    AccountVacancyStatusesData: Status[];
    AccountApplicantStatusesData: Status[];
  }
}

// По факту жизни, статусы кандидатов отличаются от статусов на вакансии (если не применен набор этапов)
// только виртуальными этапами (all / response and workon).
// Но пока не мёржу в один список - вдруг разойдутся в перспективе
// (например будет решение не выводить Hired на странице всех кандидатов)

export const applicantStatus = new StatusService(window.AccountApplicantStatusesData);
export const vacancyStatus = new StatusService(window.AccountVacancyStatusesData);
