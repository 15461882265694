import { ApiLayer } from '@/api/utils/api-layer';
import axios, { AxiosClient, AxiosRequestConfig } from '@/api/utils/client/axios';
import { Delete } from '@/api/utils/default-responses';

type Tag = 'followups' | 'email_tracking' | 'scheduled_emails' | 'email_stats';

class ActiveCompaign extends ApiLayer<AxiosClient> {
  send(tag: Tag) {
    return this.methods.post(`/promo/tracking/${tag}`);
  }
}
// TODO: переделать на именованный экспорт, в соответствии с README
export default new ActiveCompaign(axios);
