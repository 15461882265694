<template>
  <svg :width="sizeInPx" :height="sizeInPx" class="loader-next" viewBox="-30 -30 60 60">
    <circle cx="0" cy="0" r="27" class="loader-next__circle" />
  </svg>
</template>

<script>
import { lightTextColor } from '@/config/css-variables';

export default {
  name: 'VLoader',
  props: {
    size: {
      type: [String, Number, null],
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: lightTextColor
    }
  },
  computed: {
    sizeInPx() {
      return this.size || (this.small ? 30 : 60);
    }
  }
};
</script>

<style lang="less">
// TODO: избавиться от использования less и перевести на module
// но сначала нужно будет в тестах использовать data-qa вместо селектора

@keyframes loader-next {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: 200px;
  }
  60% {
    stroke-dashoffset: 300px;
  }
  100% {
    transform: rotate(360deg);
    stroke-dashoffset: 200px;
  }
}

.loader-next {
  display: block;

  &__circle {
    fill: none;
    stroke: v-bind(color);
    stroke-width: 1px;
    stroke-dasharray: 170px;
    animation-name: loader-next;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-timing-function: linear;
  }
}
</style>

<i18n lang="json">{}</i18n>
