<template>
  <div :class="$style.root">
    <slot name="default" />
  </div>
</template>

<style module>
.root {
  --title-font-weight: bold;
  --value-margin-left: 0;
  --value-font-weight: var(--font-regular-weight, normal);
  --label-gap: 0;
}
</style>

<i18n lang="json">{}</i18n>
