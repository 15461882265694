<template>
  <dictionary-resource
    ref="resource"
    :dictionary-id="dictionaryId"
    :multiple="multiple"
    :value="value"
    :auto-update-outdated="false"
    @structure-info-changed="structureChangeInfo = $event"
    @structure-changed="handleStructureChanged"
  >
    <template
      #default="{
        items,
        loading,
        structureLoading,
        disabled: resourceDisabled,
        query,
        setQuery,
        changeInactiveToggle,
        hasInactive,
        showInactive,
        hierarchyMap,
        hierarchyMapWithInactive,
        searchMode,
        loadingByLevel,
        canLoadMoreByLevel,
        cursorByLevel,
        next,
        showInModal
      }"
    >
      <component
        :is="component(showInModal)"
        :id="id"
        :items="items"
        :loading="loading"
        :page-mode="pageMode"
        :multiple="multiple"
        :value="value"
        :max-height="maxHeight"
        :hierarchy-map="hierarchyMap"
        :hierarchy-map-with-inactive="hierarchyMapWithInactive"
        :show-inactive="showInactive"
        :only-leaf="onlyLeaf"
        :max-values="maxValues"
        :activity-toggle="hasInactive"
        :query="query"
        :name="name"
        :placeholder="placeholder"
        :required="required"
        :clearable="!required"
        :disabled="disabled || resourceDisabled"
        :invalid="invalid"
        :collapsible="!searchMode"
        :loading-by-level="loadingByLevel"
        :title="modalTitle"
        :dropdown-size="dropdownSize"
        :show="visibilityState"
        :disable-auto-compress="disableAutoCompress"
        @search="setQuery"
        @reset="$emit('reset')"
        @toggle-activity-toggle="changeInactiveToggle"
        @change="$emit('change', $event)"
        @visibility-state="visibilityState = $event"
      >
        <template #content>
          <slot name="content">
            <structure-updated
              v-if="structureChangeInfo"
              :info="structureChangeInfo"
              @update="forceUpdateStructure"
            />
          </slot>
        </template>
        <template #toggle="scopedProps">
          <toggle-text
            :text="scopedProps.text"
            :show-text="scopedProps.showText"
            :count="scopedProps.count"
            :value="scopedProps.value"
            :loading="structureLoading"
            :multiple="scopedProps.multiple"
            :multiple-text="multipleLabel"
            :dictionary-id="dictionaryId"
            :items="items"
            :hierarchy-map="hierarchyMapWithInactive"
          >
            <template #default="{ text, count }">
              <slot name="toggle" v-bind="scopedProps" :text="text" :count="count">
                <toggle-button
                  :id="id"
                  :invalid="invalid"
                  :disabled="disabled || structureLoading"
                  :name="name"
                  :text="text"
                  @click="scopedProps.toggle"
                >
                  <template #icon>
                    <slot name="toggle-icon"></slot>
                  </template>
                  <select-button-text
                    :multiple="!structureLoading && scopedProps.multiple"
                    :default-unselected="multipleLabelDefaultUnselected"
                    :title="text"
                    :count="count"
                  />
                </toggle-button>
              </slot>
            </template>
          </toggle-text>
        </template>
        <template #item-title="{ item }">
          <span :title="item.name" v-html="item.name_highlight || item.name" />
        </template>
        <template #item-subtitle="{ item }">
          <span v-if="item.meta" :title="item.meta.subTitle" v-html="item.meta.subTitle" />
        </template>
        <template #after-level="{ id }">
          <intersection-observer
            v-if="canLoadMoreByLevel[id]"
            :key="cursorByLevel[id]"
            @update="log($event, id)"
            @appear="next(id)"
          />
        </template>
      </component>
    </template>
  </dictionary-resource>
</template>

<script>
import ResourceAction from '@/components/resource-action/resource-action';
import IntersectionObserver from '@/components/intersection-observer/intersection-observer';
import ToggleButton from '@/components/base-autocomplete/toggle-button';
import NotifierMessage from '@/components/hf/notifier-message/notifier-message';
import SelectButtonText from '@/components/dictionary/select-button-text.vue';
import ToggleText from './toggle-text';
import CursorTreePaginator from './cursor-tree-paginator';
import PartialModalAutocomplete from './partial-modal-autocomplete';
import PartialDropdownAutocomplete from './partial-dropdown-autocomplete';
import StructureUpdated from './structure-updated';
import DictionaryResource from './dictionary-resource.vue';

export default {
  name: 'PartialDictionaryAutocomplete',
  components: {
    DictionaryResource,
    SelectButtonText,
    StructureUpdated,
    ToggleButton,
    CursorTreePaginator,
    PartialModalAutocomplete,
    PartialDropdownAutocomplete,
    ResourceAction,
    IntersectionObserver,
    ToggleText,
    NotifierMessage
  },
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    multiple: PartialDropdownAutocomplete.props.multiple,
    pageMode: PartialDropdownAutocomplete.props.pageMode,
    value: PartialDropdownAutocomplete.props.value,
    maxHeight: PartialDropdownAutocomplete.props.maxHeight,
    onlyLeaf: PartialDropdownAutocomplete.props.onlyLeaf,
    maxValues: PartialDropdownAutocomplete.props.maxValues,
    placeholder: PartialDropdownAutocomplete.props.placeholder,
    required: PartialDropdownAutocomplete.props.required,
    disabled: PartialDropdownAutocomplete.props.disabled,
    invalid: PartialDropdownAutocomplete.props.invalid,
    dropdownSize: PartialDropdownAutocomplete.props.dropdownSize,
    disableAutoCompress: PartialDropdownAutocomplete.props.disableAutoCompress,
    id: PartialDropdownAutocomplete.props.id,
    showInDropdown: {
      type: Boolean,
      default: false
    },
    dictionaryId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    multipleLabel: {
      type: String,
      default: ''
    },
    multipleLabelDefaultUnselected: Boolean,
    modalTitle: PartialModalAutocomplete.props.title
  },
  emits: ['reset', 'change'],
  data() {
    return {
      structureChangeInfo: null,
      visibilityState: false,
      persistentVisibilityState: false
    };
  },
  computed: {
    structureShouldUpdate() {
      return !this.visibilityState && !!this.structureChangeInfo;
    }
  },
  watch: {
    structureShouldUpdate(flag) {
      if (flag) {
        this.$refs.resource.updateStructure();
      }
    }
  },
  methods: {
    forceUpdateStructure() {
      this.persistentVisibilityState = this.visibilityState;
      this.$refs.resource.updateStructure();
    },
    component(showInModal) {
      if (this.showInDropdown) {
        return PartialDropdownAutocomplete;
      }
      return showInModal ? PartialModalAutocomplete : PartialDropdownAutocomplete;
    },
    handleStructureChanged() {
      this.$nextTick(() => {
        this.visibilityState = this.persistentVisibilityState;
        this.persistentVisibilityState = false;
      });
    },
    log(entry, id) {
      console.log(
        'intersection-update',
        {
          isIntersecting: entry.isIntersecting,
          intersectionRatio: entry.intersectionRatio
        },
        {
          boundingClientRect: entry.boundingClientRect,
          intersectionRect: entry.intersectionRect,
          rootBounds: entry.rootBounds
        },
        id
      );
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
