<template>
  <div
    :class="$style.root"
    :style="{
      background: theme.background
    }"
  >
    <div :class="$style.box">
      <div :class="$style.header">
        <Logo :color="logoColor" />
        <LightningIcon :color="theme.iconColor" width="22" height="37" />
      </div>
      <div :class="$style.body">
        <h3 :class="$style.title" v-html="currentNews.title" />
        <div :class="$style.content" v-html="currentNews.text" />
        <div class="control-group control-group_block">
          <button
            v-if="hasNextNews"
            type="button"
            class="button button_green"
            @click="$emit('next')"
            >{{ $trlMessage('news.next') }}</button
          >
          <button type="button" class="button button_white" @click="$emit('close')">{{
            $trlMessage('button.close')
          }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from './logo.vue';
import LightningIcon from './lightning-icon.vue';
import { colorRelativeLightness } from '../helpers';

export default {
  name: 'NewsText',
  components: { LightningIcon, Logo },
  props: {
    hasNextNews: {
      type: Boolean,
      required: true
    },
    theme: {
      type: Object,
      required: true
    },
    currentNews: {
      type: Object,
      required: true
    }
  },
  emits: ['next', 'close'],
  computed: {
    logoColor() {
      return colorRelativeLightness(this.theme.background) > 0.5 ? '#000' : '#FFF';
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/mixins/font';
@import '~@less/common/variables';

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 144px;
  padding-bottom: 150px;
}
.box {
  margin: auto;
  width: 560px;
}
.header {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
}

.body {
  border-radius: 50px;
  background-color: #ffffff;
  padding: 30px 50px;
  margin-top: 20px;
}
.title {
  font-size: 22px;
  .font_bold();
  line-height: 26px;
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 15px;
}
.content {
  margin-bottom: 20px;
}

.content video,
.content img {
  margin-bottom: 20px;
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.content :global img.screenshot {
  margin-left: 0;
  max-width: 404px;
}

.content p,
.content h4,
.content ul,
.content ol {
  margin-top: 10px;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  color: #151319;
}
.content strong,
.content h4,
.content a:not(.lite) {
  .font_bold();
}

.content a {
  color: @linkColor;
  text-decoration: none;
  &:hover {
    color: @hoverColor;
  }
}

.content :global a.in-text-link {
  color: @blueColor;
  &:hover {
    color: @hoverColor;
  }
}

.content ul,
.content ol {
  padding: 0;
}

.content ul {
  list-style: none;
}

.content ul > li {
  padding-left: 34px;
  position: relative;
  margin-top: 10px;

  &::before,
  &::after {
    position: absolute;
    background-color: currentColor;
    content: '';
    transform-origin: 50% 0;
    width: 2px;
  }

  &::before {
    left: 5px;
    top: 8px;
    height: 12px;
    transform: rotate(-40deg);
  }

  &::after {
    left: 25px;
    top: 1px;
    height: 20px;
    transform: rotate(40deg);
  }
}

.content :global ul.simple-list {
  list-style: disc;
  padding-left: 15px;
}

.content :global ol.simple-list {
  padding-left: 25px;
}

.content :global ol {
  ::marker {
    font-variant-numeric: lining-nums;
  }
}

.content :global ol.simple-list > li,
.content :global ul.simple-list > li {
  padding-left: 0;

  &::before,
  &::after {
    display: none;
  }
}

.content p:last-child,
.content img:last-child,
.content video:last-child,
.content h4:last-child {
  margin-bottom: 0;
}

.content ul,
.content ol {
  margin: 16px 0;
  padding: 0;
}

.content ul {
  list-style: none;
}

.content li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
  &::before {
    content: '';
    width: 23px;
    height: 19px;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.498.814l1.533 1.285L8.532 18.186l-1.455-1.221-.006.005-.028-.033L7 16.9l.006-.008L0 8.543l1.532-1.285 6.78 8.08L20.499.813z' fill='%23151319'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.content .list-title {
  display: block;
}

.content * + .list-title {
  margin-top: 15px;
}

.content picture + .list-title {
  margin-top: 30px;
}

.content .list-title + ul,
.content .list-title + ol {
  margin-top: 0;
}

.content a.action:nth-last-child(1) {
  display: block;
  width: fit-content;
  margin-bottom: 30px;
}
</style>

<i18n lang="json">{}</i18n>
