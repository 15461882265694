<template>
  <span :class="rootClassName">
    <img
      v-if="shouldShowImage"
      :src="src"
      data-qa="userpic"
      @load="handleLoad"
      @error="handleError"
    />
    <slot name="default"></slot>
  </span>
</template>

<script>
export default {
  name: 'BaseUserpic',
  props: {
    src: {
      type: String,
      default: null
    },
    squared: {
      type: Boolean,
      default: false
    },
    hiddenLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['load', 'error'],
  data() {
    return {
      isLoaded: !this.src,
      isBroken: false
    };
  },
  computed: {
    shouldShowImage() {
      return this.src && !this.isBroken;
    },
    rootClassName() {
      const isVisible = this.isLoaded || this.isBroken || !this.hiddenLoading;
      const isDefault = !this.isLoaded || this.isBroken || !this.src;
      return {
        [this.$style.root]: true,
        [this.$style.squared]: this.squared,
        [this.$style.hidden]: isVisible === false,
        [this.$style.default]: isDefault
      };
    }
  },
  watch: {
    isLoaded: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$emit('load');
        }
      }
    },
    isBroken: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$emit('error');
        }
      }
    },
    src(value) {
      // Сбрасываем на дефолтное состояние
      this.isLoaded = !value;
      this.isBroken = false;
    }
  },
  methods: {
    handleLoad() {
      this.isLoaded = true;
    },
    handleError() {
      this.isBroken = true;
    }
  }
};
</script>

<style lang="less" module>
.root {
  aspect-ratio: 1/1;
  display: block;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  img {
    object-fit: cover;
  }
}

.root.hidden {
  visibility: hidden;
}

.squared {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--userpic-border-radius, 4px);
}

.default {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTgiIGhlaWdodD0iNTgiIHZpZXdCb3g9IjAgMCA1OCA1OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+bm9waG90bzwvdGl0bGU+PHBhdGggZD0iTTI5LjAyNSA0MC43NjVjNC4xMTcgMCA4Ljk1My0uMjkgOS44MTYtMi41NTYuMjY4LS42OTQuMTQ4LTEuODEzLjAwNC0yLjI0NC0uNjA2LTEuODI1LTMuMTYtMi45Ni00LjUzLTMuNTctLjEtLjA0Ni0uMjI4LS4wOTQtLjM3Mi0uMTVsLS4xMjYtLjA0NmMtLjc4LS4yOS0xLjk1Ny0uNzMtMi4xMjUtMS4zNzQtLjIzNS0uOTEzLjM0Mi0xLjY3Mi45MTItMi40MjIuMjIzLS4yOTQuNDM0LS41NzMuNTkzLS44Ni42MzQtMS4xNDIgMS4xMDMtMi42MTcgMS4xOTQtMy43NTYuMTY0LTIuMDU3LS4zMi0zLjc3My0xLjQwMy00Ljk1OC0uOTUtMS4wNDQtMi4yOTItMS41OTUtMy44OC0xLjU5NS0uMjc1IDAtLjU1Ni4wMTctLjgzNC4wNS0yLjY1NS4zMDgtNC40MiAyLjI2NC00LjYwNyA1LjEwMy0uMTUgMi4yMjguNDY4IDQuMjcgMS44ODcgNi4yNC4wODguMTI0LjE4Mi4yNDIuMjY3LjM1NC4zNTYuNDU3LjY2NC44NTMuNjA1IDEuNDctLjA3Ny43OTMtLjg1OCAxLjA5Ni0xLjQ4NSAxLjMzNWwtLjI1NS4xYy0uNzU1LjMwNy0xLjU3NS43NjctMi4wMjcgMS4wMmwtLjExLjA2MmMtMS40Ni44MTctMy4wNDggMS43OTgtMy40MDQgMy4xMjYtLjI1LjkzNi0uMTc1IDEuNzA2LjIyNyAyLjM1NC43NjIgMS4yMjYgMi41NzcgMS42OSAzLjg3NSAxLjkzNCAxLjg4Ny4zNTIgMy45NzMuMzggNS43OC4zOHoiIGZpbGw9IiNDMUMxQzEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
  background-color: #eeeeee;
  background-size: cover;

  img {
    display: none;
  }
}
</style>

<i18n lang="json">{}</i18n>
