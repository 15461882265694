<template>
  <base-item>
    <span :class="$style.text">
      <slot />
    </span>
  </base-item>
</template>

<script>
import BaseItem from './base-item';

export default {
  name: 'HeaderItem',
  components: { BaseItem }
};
</script>

<style module lang="less">
@import '~@less/common/mixins/font';

.text {
  .font_bold();
  font-size: 11px;
  line-height: 18px;
  color: #929097;
  position: relative;
  bottom: -6px;
  text-transform: uppercase;
}
</style>

<i18n lang="json">{}</i18n>
