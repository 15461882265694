import memoize from 'lodash/memoize';
import { config, AccountSettingDataType } from '@/services/config/config';
import { MultiVacancySchemaField, MultiVacancySchemaDictionaryField } from '@/types/schema-type';

type MultiVacancyFields = Record<string, MultiVacancySchemaField>;

type VacancyNamingConfig = Array<{
  field: string;
  type?: string;
  dictionary?: string;
  deep?: number;
}>;

interface Options {
  customNamingConfig?: AccountSettingDataType['custom-vacancy-company'];
}

type Vacancy = any; // TODO: описать бы структуру вакансии...

const DIVIDER = ' · ';
const UNBEARABLE_DIVIDER = ' · ';

const getChildVacancyNamingConfig = ({ customNamingConfig }: Options = {}): VacancyNamingConfig => {
  const multivacancyFields = config.settings.get('use-multivacancy')?.fields || {};
  const dictionaryDeepLevel = config.settings.get('division-level-view');

  const uniqSchema = Object.fromEntries(
    Object.entries(multivacancyFields).filter(([, field]) => field.unique)
  ) as MultiVacancyFields;

  return processFields(uniqSchema);

  function processFields(fields: MultiVacancyFields) {
    return Object.entries(fields)
      .sort(([, a], [, b]) => a.order - b.order)
      .reduce<VacancyNamingConfig>((acc, [key, field]) => {
        if (field.type === 'dictionary') {
          const entry = {
            field: key,
            dictionary: (field as MultiVacancySchemaDictionaryField).dictionary,
            type: field.type
          };
          acc.push(dictionaryDeepLevel ? { ...entry, deep: dictionaryDeepLevel } : entry);
          return acc;
        }

        if (field.type === 'division') {
          const entry = customNamingConfig
            ? { ...customNamingConfig, type: 'dictionary' }
            : { field: key, dictionary: 'account_division', type: 'dictionary' };
          acc.push(dictionaryDeepLevel ? { ...entry, deep: dictionaryDeepLevel } : entry);
          return acc;
        }

        if (field.type === 'region') {
          const entry = { field: key, dictionary: 'account_region', type: 'dictionary' };
          acc.push(dictionaryDeepLevel ? { ...entry, deep: dictionaryDeepLevel } : entry);
          return acc;
        }

        if ('fields' in field && field.fields) {
          if (field.type === 'income' || field.type === 'compensation') {
            // TODO: сомнительно что эти поля будут в названии вакансии, но мало ли..
            // по аналогии из getDisplayData
            const entry = { field: key, type: field.type };
            acc.push(entry);
          } else {
            acc.push(...processFields(field.fields));
          }
        } else {
          const entry = { field: key, type: field.type };
          acc.push(entry);
        }

        return acc;
      }, []);
  }
};

const getChildVacancyNamingConfigMemoized = memoize(
  getChildVacancyNamingConfig,
  ({ customNamingConfig } = {}) => {
    const multivacancyFields = config.settings.get('use-multivacancy')?.fields || {};
    const dictionaryDeepLevel = config.settings.get('division-level-view');

    const uniqSchema = Object.fromEntries(
      Object.entries(multivacancyFields).filter(([, field]) => field.unique)
    );

    return (
      String(JSON.stringify(uniqSchema)) +
      String(JSON.stringify(dictionaryDeepLevel)) +
      String(customNamingConfig)
    );
  }
);

const getVacancyNamingConfig = ({ customNamingConfig }: Options = {}): VacancyNamingConfig => {
  const dictionaryDeepLevel = config.settings.get('division-level-view');

  if (customNamingConfig) {
    const entry = { ...customNamingConfig, type: 'dictionary' };
    return dictionaryDeepLevel ? [{ ...entry, deep: dictionaryDeepLevel }] : [entry];
  }

  const entry = {
    field: 'account_division',
    dictionary: 'account_division',
    type: 'dictionary'
  };
  return dictionaryDeepLevel ? [{ ...entry, deep: dictionaryDeepLevel }] : [entry];
};

const getVacancyNamingConfigMemoized = memoize(
  getVacancyNamingConfig,
  ({ customNamingConfig } = {}) => {
    const dictionaryDeepLevel = config.settings.get('division-level-view');

    return String(JSON.stringify(dictionaryDeepLevel)) + String(customNamingConfig);
  }
);

function computeName(
  vacancy: Vacancy,
  config: VacancyNamingConfig,
  { dictionaryDivider = UNBEARABLE_DIVIDER, valueDivider = UNBEARABLE_DIVIDER } = {}
) {
  type Result = {
    name: {
      short: string;
      full: string | undefined;
    };
  } & VacancyNamingConfig[number];

  const result = config.reduce<Result[]>((acc, entry) => {
    const value = vacancy[entry.field];
    if (entry.type === 'dictionary' && vacancy.custom_name_data[entry.field]) {
      const dictionaryFields = [vacancy.custom_name_data[entry.field]];

      acc.push({
        ...entry,
        name: {
          short: dictionaryFields
            .map((val) => val.slice(0, entry.deep || undefined).join(dictionaryDivider))
            .join(valueDivider),
          full: dictionaryFields.map((val) => val.join(dictionaryDivider)).join(valueDivider)
        }
      });
      return acc;
    }

    // см. коммент здесь же, в processFields :)
    if (entry.type === 'income' && value?.income) {
      acc.push({
        ...entry,
        name: {
          short: `${value.income} (${value.type})`,
          full: undefined
        }
      });
      return acc;
    }
    if (entry.type === 'compensation' && (value?.compensation_from || value?.compensation_to)) {
      acc.push({
        ...entry,
        name: {
          short: [value.compensation_from, value.compensation_to].filter(Boolean).join(' - '),
          full: undefined
        }
      });
      return acc;
    }

    if (value === undefined || value === null) {
      return acc;
    }

    acc.push({
      ...entry,
      name: {
        short: String(value),
        full: undefined
      }
    });
    return acc;
  }, []);
  // дополнительная логика на случай если не было ни одного сокращения => тултип не должен выводиться
  return result.some((entry) => entry.name.full && entry.name.full !== entry.name.short)
    ? result
    : result.map((entry) => ({ ...entry, name: { ...entry.name, full: undefined } }));
}

function computeCompany(vacancy: Vacancy) {
  const customVacancyCompany = config.settings.get('custom-vacancy-company');
  const namingConfig = getVacancyNamingConfigMemoized({ customNamingConfig: customVacancyCompany });
  const result = computeName(vacancy, namingConfig);
  const shortParts = result.map((item) => item.name.short);
  const fullParts = result.map((item) => item.name.full).filter(Boolean);

  return (fullParts.length > 0 ? fullParts : shortParts).join(DIVIDER);
}

function computeVacancyName(vacancy: Vacancy, { useCustomConfig = true } = {}) {
  const customNamingConfig = config.settings.get('custom-vacancy-company');
  const vacancyNamingConfig = getVacancyNamingConfigMemoized({
    customNamingConfig: undefined
  });
  const vacancyNamingConfigCVN = getVacancyNamingConfigMemoized({ customNamingConfig });
  const childVacancyNamingConfig = getChildVacancyNamingConfigMemoized({
    customNamingConfig: undefined
  });
  const childVacancyNamingConfigCVN = getChildVacancyNamingConfigMemoized({
    customNamingConfig
  });
  // TODO: пофиксить названия проп
  const isChild = Boolean(vacancy.parent || vacancy.parent_id);

  let namingConfig;
  if (isChild) {
    namingConfig = useCustomConfig ? childVacancyNamingConfigCVN : childVacancyNamingConfig;
  } else {
    namingConfig = useCustomConfig ? vacancyNamingConfigCVN : vacancyNamingConfig;
  }

  const result = computeName(vacancy, namingConfig);
  const shortForm = result.map((item) => item.name.short);
  const fullForm = result.map((item) => item.name.full).filter(Boolean);
  return {
    nameInfo: result,
    namingConfig,
    shortForm,
    fullForm
  };
}

type NameParts = Array<string>;

interface NameInfo {
  shortForm: NameParts;
  fullForm: NameParts;
}

function computeCommonPrefixCount(namesInfos: Array<NameInfo>): number {
  if (namesInfos.length < 2) {
    return 0;
  }
  const namesParts: Array<NameParts> = namesInfos.map(({ shortForm, fullForm }) =>
    fullForm.length ? fullForm : shortForm
  );
  const firstNameParts = namesParts[0];

  const length = Math.min(...namesParts.map((parts) => parts.length));

  let count = 0;
  while (count < length && namesParts.every((form) => form[count] === firstNameParts[count])) {
    count += 1;
  }

  if (count === length) {
    return 0;
  }
  return count;
}

function removePrefix(nameParts: NameParts, count: number): NameParts {
  const lastIndexForDelete = count - 1;
  return nameParts.filter((part, index) => index > lastIndexForDelete);
}

export const VacancyNamingHelper = {
  DIVIDER,
  UNBEARABLE_DIVIDER,
  getChildVacancyNamingConfig,
  getChildVacancyNamingConfigMemoized,
  getVacancyNamingConfig,
  getVacancyNamingConfigMemoized,
  computeName,
  computeCompany,
  computeVacancyName,
  computeCommonPrefixCount,
  removePrefix
};
