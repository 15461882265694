<template>
  <div :class="$style.wrapper">
    <span :class="$style.subTitle">{{ subTitle }}</span>
    <div :class="$style.flex">
      <div v-if="isOrg" :class="$style.button">
        <base-icon type="settings-arrow" :class="$style.icon" @click="$emit('back')" />
      </div>
      <router-link v-if="showBack && !isOrg" :class="$style.button" :to="{ path: '/' }">
        <base-icon type="settings-arrow" :class="$style.icon" />
      </router-link>
      <h1 :class="$style.title" v-html="title" />
    </div>
    <div :class="$style.delimiter" />
  </div>
</template>

<script>
import BaseIcon from '@/components/icon.vue';
export default {
  name: 'SettingTitle',
  components: {
    BaseIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      default: null
    },
    showBack: {
      type: Boolean,
      default: false
    },
    isOrg: {
      type: Boolean,
      default: false
    }
  },
  emits: ['back']
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.wrapper {
  margin: 40px 0 40px;
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin: 15px 0 22px 0;
}
.button {
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    border-left: 1px solid #dcdcdc;
    position: absolute;
    left: 34.52px;
    height: 25px;
    top: 0px;
    z-index: 1;
  }
}
.icon {
  width: 19.52px;
  height: 16px;
  color: #151319;
  &:hover {
    color: #db525e;
  }
}
.title {
  font-size: 25px;
  line-height: 28px;
  margin: 0;
}
.delimiter {
  border-bottom: 3px solid @borderSetting;
}
.subTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #999999;
}
</style>

<i18n lang="json">{}</i18n>
