<template>
  <validation-field :name="name">
    <template #default="{ modelValue, onChange }">
      <base-file :class="$style.file" @change="handleChange($event, onChange)">
        <template #default="{ remove, open }">
          <base-attachment
            v-if="modelValue"
            :value="modelValue"
            :title="modelValue.name"
            icon="clip"
            can-remove
            update-on-mount
            @remove="remove"
          />
          <button v-else :class="$style.button" type="button" @click="open">
            <base-icon type="clip" /><span>{{ label }}</span>
          </button>
        </template>
      </base-file>
    </template>
  </validation-field>
</template>

<script>
import { ValidationField } from '@/components/ui/validate-form';
import BaseAttachment from '@/components/hf/base-attachment/base-attachment';
import BaseFile from '@/components/ui/base-file/base-file';
import BaseIcon from '@/components/icon';

export default {
  name: 'FileUpload',
  components: {
    BaseAttachment,
    BaseFile,
    BaseIcon,
    ValidationField
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    handleChange(files, onChange) {
      onChange(files?.[0]);
    }
  }
};
</script>

<style module>
.button {
  @mixin reset-button-styles;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.button span {
  color: $blueColor;
}

.button:hover span {
  color: $redColor;
}

.file {
  max-width: 100%;
}
</style>

<i18n lang="json">{}</i18n>
