<template>
  <div>
    <content-loader v-if="loading || !ready" />
    <div v-if="!loading" v-show="ready">
      <start-vacancy
        :vacancy="preparedVacancy"
        :multiple="isMultipleMode"
        :child-id="childId"
        :vacancy-request="vacancyRequest"
        :offers="offers"
        :reopen="reopen"
        :handle-submit="handleSubmit"
        v-on="$listeners"
        @cancel="handleCancel"
        @ready="ready = true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep, omit } from 'lodash';
import { poller, MessageEvent } from '@/poller-instance';

import ContentLoader from '@/components/loader/content-loader.vue';
import { VacancyState } from '@/types/vacancy-state';
import OffersAPI from '@/api/offers';
import { VacancyAPI } from '@/api/vacancy';
import { VacancyQuotaAPI } from '@/api/vacancy-quota';
import { QuotaState } from '@/types/quota-state';
import { DictionaryStore } from '@/services/dictionary/dictionary';
import {
  fixVisibility,
  fixVacancyFieldsTypes,
  transformErrors,
  enrichWithFillQuotas
} from './helpers';

function preloadComponents() {
  return Promise.all([
    import('@/modals/start-vacancy.vue'),
    import('@/components/wysiwyg/default-wysiwyg/default-wysiwyg.vue')
  ]).then(([StartVacancy, DefaultWysiwyg]) => ({
    StartVacancy,
    DefaultWysiwyg
  }));
}

export default {
  name: 'VacancyFormWrapper',
  components: {
    ContentLoader,
    StartVacancy: () => preloadComponents().then(({ StartVacancy }) => StartVacancy)
  },
  props: {
    vacancy: {
      type: Object,
      default: () => ({})
    },
    multiple: Boolean,
    reopen: Boolean,
    vacancyRequest: {
      type: Object,
      default: null
    },
    childId: {
      type: Number,
      default: null
    }
  },
  emits: ['saved', 'cancel'],
  data() {
    return {
      loading: true,
      preparedVacancy: null,
      offers: null,
      ready: false
    };
  },
  computed: {
    ...mapGetters('account', {
      vacancyFields: 'vacancy_fields'
    }),
    isMultipleMode() {
      return Boolean(this.vacancy.multiple || this.multiple);
    },
    vacancyState() {
      if (this.isMultipleMode && this.childId) {
        const child = this.vacancy.blocks.find((b) => b.id === this.childId);
        if (child) {
          return child.state;
        }
      }

      return this.vacancy.state;
    }
  },
  mounted() {
    Promise.all([
      this.loadFillQuotas(),
      OffersAPI.getOfferTemplates(),
      this.preloadDictionaries(),
      preloadComponents()
    ])
      .then(([fillQuotas, offers]) => {
        this.preparedVacancy = this.prepareVacancy(fillQuotas);
        this.offers = offers.filter((offer) => offer.active);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    loadFillQuotas() {
      if (this.vacancy.id && this.vacancyState !== VacancyState.closed) {
        return VacancyQuotaAPI.getListByVacancy(this.vacancy.id, {
          status: QuotaState.ALL,
          skip_pagination: true
        });
      }

      return Promise.resolve({});
    },
    preloadDictionaries() {
      return Promise.all(
        Object.values(this.vacancyFields)
          .filter((field) => field.type === 'dictionary')
          .map((field) => field.dictionary)
          .map((name) => DictionaryStore.loadDictionary(name))
      );
    },
    prepareVacancy(fillQuotas) {
      fillQuotas = Object.fromEntries(
        Object.entries(fillQuotas).map(([vacancyId, { items }]) => {
          return [
            vacancyId,
            items.map(({ vacancy_request, ...item }) => ({
              ...item,
              vacancy_request: vacancy_request?.id
            }))
          ];
        })
      );
      return fixVisibility(
        fixVacancyFieldsTypes(
          enrichWithFillQuotas(cloneDeep(this.vacancy), fillQuotas, {
            childId: this.childId,
            multiple: this.isMultipleMode,
            reopen: this.reopen,
            vacancyRequest: this.vacancyRequest
          }),
          Object.values(this.vacancyFields)
        )
      );
    },
    handleSubmitSuccess(values, response) {
      const isNewChild = this.vacancyRequest && this.multiple && !this.childId;
      let formResult = response;
      if (this.isMultipleMode) {
        if (response.job) {
          const promise = new Promise((resolve, reject) => {
            const stop = poller.onMessage((message) => {
              if (message.event === MessageEvent.job && message.id === response.job) {
                stop();
                const { result, error } = message.data;

                if (error) {
                  reject({
                    response: {
                      status: 400,
                      body: result
                    }
                  });
                } else {
                  resolve(result);
                }
              }
            });
          });

          return promise.then(
            (result) => this.handleSubmitSuccess(values, result),
            (errors) => Promise.reject(errors)
          );
        } else {
          if (isNewChild) {
            values.blocks = values.blocks.filter((b) => b.id);
            values.blocks.push(response);
            formResult = values;
          } else if (this.childId) {
            const index = values.blocks.findIndex((b) => b.id === this.childId);
            values.blocks[index] = response;
            values.state = response.state;
            formResult = values;
          }
        }
      }

      this.$emit('saved', {
        ...formResult,
        members: values.recruiters
      });
      return response;
    },
    handleSubmit({ visibility, ...values }) {
      values.hidden = Boolean(visibility);
      if (this.reopen) {
        values.state = VacancyState.open;
      }
      let promise;
      const isNewChild = this.vacancyRequest && this.multiple && !this.childId;
      if (isNewChild) {
        // новая дочка
        const vacancy = values.blocks[0];
        promise = VacancyAPI.create({
          ...omit(values, ['id', 'mutiple', 'blocks']),
          ...vacancy
        });
      } else if (this.childId) {
        // редактируем дочку
        const child = values.blocks.find((b) => b.id === this.childId);
        promise = VacancyAPI.patch(child.id, child);
      } else if (values.id) {
        // редактируем родительскую/обычную
        promise = VacancyAPI.update(values.id, values);
      } else {
        // создаем новую
        promise = VacancyAPI.create(values);
      }

      return promise.then(
        (result) => this.handleSubmitSuccess(values, result),
        ({ response }) => {
          return Promise.reject({
            response: {
              status: response.status,
              statusText: response.statusText,
              body: transformErrors(response.data)
            }
          });
        }
      );
    },
    handleCancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
