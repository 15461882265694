<template>
  <div
    :class="$style.item"
    :style="{ minHeight: minHeightComputed }"
    data-qa="list-item"
    @click="handleClick"
  >
    <div v-if="icon" :class="$style.icon">
      <slot name="icon" />
    </div>
    <div :class="classes">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseItem',
  props: {
    disabled: Boolean,
    active: {
      type: Boolean,
      default: true
    },
    bordered: Boolean,
    icon: {
      type: Boolean,
      default: true
    },
    deep: {
      type: Number,
      default: 0
    },
    textGapY: {
      type: String,
      default: '11px'
    },
    minHeight: {
      type: Number,
      default: 42
    }
  },
  emits: ['click'],
  computed: {
    minHeightComputed() {
      return `${this.minHeight}px`;
    },
    classes() {
      return {
        [this.$style.wrapper]: true,
        [this.$style.disabled]: this.disabled,
        [this.$style.border]: this.bordered,
        [this.$style.archive]: this.active === false
      };
    }
  },
  methods: {
    handleClick(e) {
      if (this.disabled) {
        e.stopPropagation();
        return;
      }
      this.$emit('click');
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables';
@import '~@less/common/mixins/font.less';

.item {
  color: @textColor;
  align-items: center;
  display: flex;
  width: 100%;
  padding-left: calc(24px * v-bind(deep));
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 35px;
  color: #38343f;
}
.wrapper {
  flex: 1 0 0;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: normal;
  padding: v-bind(textGapY) 20px v-bind(textGapY) 0;
}
.wrapper b {
  background: #fff6da;
  border-radius: 4px;
  .font_regular();
}
.disabled {
  color: #bfbfbf;
}
.border {
  border-bottom: @borderColor 1px solid;
}
.archive {
  composes: disabled;
  text-decoration: line-through;
}
</style>

<i18n lang="json">{}</i18n>
