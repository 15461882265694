<template>
  <div :class="{ [$style.item]: true, [$style.activeItem]: isActive }" v-on="$listeners">
    <div :class="$style.checkmarkPlaceholder">
      <icon v-if="isChecked" type="checked-item-mark" />
    </div>
    <div :class="$style.content">
      <span data-qa="item-title" :class="[$style.itemTitle, $style.itemPrimaryTitle]">{{
        item.title
      }}</span>
      <span v-if="item.secondaryTitle" :class="[$style.itemTitle, $style.itemSecondaryTitle]">
        {{ item.secondaryTitle }}
      </span>
    </div>
  </div>
</template>

<script>
import Icon from '../../icon.vue';

export default {
  name: 'VItem',
  components: {
    Icon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isChecked: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style module>
.item {
  user-select: none;
  letter-spacing: 0;
  display: flex;
  align-items: center;

  &.activeItem {
    background-color: #f6f6f6;
  }
}

.checkmarkPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 35px;
  color: #38343f;
}

.content {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
  @mixin ellipsis;
}

.content {
  border-bottom: 1px solid $borderColor;
}

.itemPrimaryTitle,
.itemSecondaryTitle {
  @mixin ellipsis;
  display: block;
  flex: none;
}

.itemPrimaryTitle {
  color: $textColor;
  line-height: 22px;
  font-size: 16px;
}

.itemSecondaryTitle {
  color: #929097;
  line-height: 18px;
  font-size: 14px;
}

.itemTitle:first-child {
  margin-top: 9px;
}

.itemTitle:last-child {
  margin-bottom: 10px;
}
</style>

<i18n lang="json">{}</i18n>
