<template>
  <div v-show="showTitle || showBanner">
    <div v-if="showTitle" :class="$style.title">
      <strong data-qa="email">{{ emailInfo.email || '—' }}</strong>
      <template v-if="isShowIcon">
        <template v-if="isVerified">
          <base-tooltip :distance="4" :shown="true" :shift="8">
            <template #content>
              {{ $i18n('email.confirmed') }}
            </template>
            <template #default="{ trigger }">
              <img
                :ref="trigger"
                :class="$style.icon"
                data-qa="verified"
                :src="require('../assets/verified.svg')"
              />
            </template>
          </base-tooltip>
        </template>
        <img
          v-else
          :class="$style.icon"
          data-qa="not-verified"
          :src="require('../assets/attention.svg')"
        />
      </template>
    </div>
    <slot />
    <email-status-banner v-if="showBanner" :type="type" @click="handleClick" />
    <email-verification-modal
      v-if="emailInfo.email && showModal"
      :email-info="emailInfo"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import { userEmails } from '@/services/user-emails/user-emails';
import { EmailType } from '@/types/email-user';
import BaseTooltip from '@/components/ui/base-tooltip/base-tooltip.vue';
import EmailStatusBanner from '../parts/email-status-banner.vue';
import EmailVerificationModal from './email-verification-modal.vue';

export default {
  name: 'EmailStatus',
  components: {
    BaseTooltip,
    EmailStatusBanner,
    EmailVerificationModal
  },
  props: {
    showTitle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(EmailType).includes(value);
      }
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    emailInfo() {
      return userEmails.getByType(this.type) || {};
    },
    isLogin() {
      return this.type === EmailType.LOGIN;
    },
    isVerified() {
      return this.emailInfo.verified;
    },
    isDifferentEmail() {
      const loginEmail = userEmails.getByType(EmailType.LOGIN);
      return loginEmail?.email !== this.emailInfo?.email;
    },
    isShowIcon() {
      if (this.isLogin) {
        return true;
      }
      return userEmails.getByType(EmailType.LOGIN)?.verified || this.isDifferentEmail;
    },
    showBanner() {
      if (this.isLogin && !this.isVerified) {
        return true;
      }
      if (!this.isVerified) {
        return this.isDifferentEmail;
      }
      return !this.isVerified;
    }
  },
  methods: {
    handleClick() {
      this.showModal = true;
    }
  }
};
</script>

<style module>
.title {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  height: 23px;

  strong {
    display: inline-block;
    line-height: 18px;
  }
}

.icon {
  width: 32px;
  height: 25px;
  padding: 7px 7px 0;
  margin-top: -2px;
}
</style>

<i18n lang="json">
{
  "email.confirmed": {
    "ru_RU": "Почта подтверждена",
    "en_US": "Email confirmed"
  }
}
</i18n>
