<template>
  <div :class="className">
    <loader />
  </div>
</template>

<script>
import Loader from './loader.vue';

export default {
  name: 'ContentLoader',
  props: {
    transparent: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Loader
  },
  computed: {
    className() {
      return {
        [this.$style.loaderWrapper]: true,
        [this.$style.transparent]: this.transparent
      };
    }
  }
};
</script>

<style module>
.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  margin-top: var(--content-loader-offset, 0);
  background: $whiteColor;
}

.transparent {
  background: none;
}
</style>

<i18n lang="json">{}</i18n>
