import stringify from 'fast-json-stable-stringify';
import cloneDeep from 'lodash/cloneDeep';
import { Message } from '@/libs/poller/poller';
import { poller } from '@/poller-instance';
import { ApiClient } from './client';
import { ApiLayer } from './api-layer';

type Cache<R, P> = {
  result: R;
  params: P;
};

export type ApiMethod = (...args: Array<any>) => PromiseLike<unknown>;

type Context<F extends ApiMethod> = {
  invalidate: () => boolean;
  update: (result: ReturnType<F>) => void;
  method: F;
  message: Message;
};

export type ResolverGeneric<F extends ApiMethod> = (
  context: Context<F>,
  ...params: Parameters<F>
) => void;

const LOG_APPEND = '[ApiMemoize]:';

function cloneResult<Result extends PromiseLike<unknown>>(result: Result) {
  return result.then((res) => cloneDeep(res)) as Result;
}

export class ApiMemoize<F extends ApiMethod> {
  private cache: Map<string, Cache<ReturnType<F>, Parameters<F>>>;
  private readonly callback: F;
  private readonly resolver?: ResolverGeneric<F>;

  constructor(fn: F, resolver?: ResolverGeneric<F>) {
    this.cache = new Map();
    this.callback = fn;
    this.resolver = resolver;

    if (this.resolver) {
      this.subscribe(this.resolver);
    }
  }

  invalidate(params: Parameters<F>) {
    const hash = stringify(params);
    return this.cache.delete(hash);
  }

  update(params: Parameters<F>, result: Cache<ReturnType<F>, Parameters<F>>['result']) {
    const hash = stringify(params);
    const cache = this.cache.get(hash);
    if (!cache) {
      throw new Error(`${LOG_APPEND} cache update failed, hash '${hash}' not found`);
    }
    this.cache.set(hash, {
      ...cache,
      result
    });
  }

  dispatch(...params: Parameters<F>): Cache<ReturnType<F>, Parameters<F>>['result'] {
    return this.dispatchWithContext(undefined, ...params);
  }

  dispatchWithContext(
    context: unknown,
    ...params: Parameters<F>
  ): Cache<ReturnType<F>, Parameters<F>>['result'] {
    const hash = stringify(params);
    const cache = this.cache.get(hash);
    if (cache) {
      console.log(
        `${LOG_APPEND} result of function '${
          this.callback.name || this.callback
        }' is taken from the cache`,
        params
      );
      return cloneResult(cache.result);
    }
    const result = this.callback.apply(context, params) as ReturnType<F>;
    this.cache.set(hash, {
      result,
      params
    });
    return cloneResult(result);
  }

  private subscribe(resolver: ResolverGeneric<F>) {
    poller.onMessage((message) => {
      this.cache.forEach(({ params }, hash) => {
        resolver(
          {
            invalidate: () => this.invalidate(params),
            update: (result) => this.update(params, result),
            method: this.callback,
            message
          },
          ...params
        );
      });
    });
  }
}

export function memoizeApiMethod<F extends ApiMethod, Resolver extends ResolverGeneric<F>>(
  fn: F,
  resolver?: Resolver
): (...params: Parameters<F>) => ReturnType<F> {
  const memoize = new ApiMemoize(fn, resolver as never);

  return (...params: Parameters<F>) => {
    return memoize.dispatch(...params);
  };
}

export function memoizeApiLayerMethod<
  Ctx extends ApiLayer<ApiClient>,
  M extends ApiMethod,
  C extends ResolverGeneric<M>
>(context: Ctx, method: M, callback?: C): (...params: Parameters<M>) => ReturnType<M> {
  const memoize = new ApiMemoize(method, callback as never);
  return function (...params: Parameters<M>) {
    return memoize.dispatchWithContext(context, ...params);
  };
}
