<script>
import { useFeatureAvailability } from './use-feature-availability';

export default {
  name: 'FeatureAvailabilityWrapper',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const isEnabled = useFeatureAvailability(props.name);

    return { isEnabled };
  },
  render() {
    if (this.$slots.default) {
      return this.$slots.default({
        enabled: this.isEnabled
      });
    }
    if (this.isEnabled) {
      return this.$slots.enabled({});
    }
    return this.$slots.disabled({});
  }
};
</script>

<i18n lang="json">{}</i18n>
