<template>
  <applicant-limit-data-provider>
    <template
      #default="{
        ready: applicantsCountReady,
        count: applicantsCount,
        limit: applicantsLimit,
        shouldWarn: isApplicantsLimitWarnVisible
      }"
    >
      <div
        v-if="applicantsCountReady && (showExpire || showNY || isApplicantsLimitWarnVisible)"
        data-qa="demo"
      >
        <base-dropdown v-if="showExpire" data-qa="licence-expiration-warning">
          <template #default="{ toggle }">
            <base-button kind="danger" :class="$style.demoButton" @click="toggle">
              <template v-if="expireIn === 0">
                {{ $i18n('plan.ends.today') }}
              </template>
              <template v-else>
                {{ $i18n('plan.finish.btn', { count: expireIn }) }}
              </template>
            </base-button>
          </template>

          <template #content>
            <dropdown-menu-layout :title="expireTitle" size="larger">
              <template #main>
                <template v-if="isDemo">
                  <p v-html="$i18n('demo-finish.dropdown.text.1')" />
                  <p v-html="$i18n('demo-finish.dropdown.text.2')" />
                </template>
                <template v-else>
                  <p>
                    {{ $i18n('plan-finish.dropdown.text', { date: formattedEndDate }) }}
                  </p>
                </template>
              </template>
              <template #footer>
                <a
                  :href="expireButtonHref"
                  :class="['button', isDemo ? 'button_green' : 'button_white']"
                >
                  <template v-if="isDemo">
                    {{ $i18n('demo-finish.btn.action') }}
                  </template>
                  <template v-else>
                    {{ $i18n('plan.finish.btn.action') }}
                  </template>
                </a>
              </template>
            </dropdown-menu-layout>
          </template>
        </base-dropdown>

        <base-dropdown v-else-if="isApplicantsLimitWarnVisible" data-qa="applicant-limit-warning">
          <template #default="{ toggle }">
            <base-button kind="warn" data-qa="warning-button" @click="toggle">
              {{
                $i18n('applicants-limit.warn-button', {
                  count: applicantsLimit - applicantsCount
                })
              }}
            </base-button>
          </template>
          <template #content>
            <applicant-limit-promo />
          </template>
        </base-dropdown>

        <base-dropdown v-else-if="showNY" data-qa="new-year-yeah">
          <template #default="{ toggle }">
            <base-button class="button_ny" :class="$style.demoButton" @click="toggle">
              <i class="button__tree" />
              {{ nyButtonTitle }}
            </base-button>
          </template>
          <template #content>
            <div :class="$style.ny">
              <div class="content content_mb_micro">
                <img :src="stikerImg" class="g-width-50" />
              </div>
              <div class="content content_mb_small">
                <div>{{ $i18n('ny.sticker.title') }}</div>
              </div>
              <div class="content content_mb_middle">
                <a
                  href="tg://addstickers?set=NewYearHF"
                  class="button button_green"
                  target="_blank"
                  >{{ $i18n('ny.sticker.take') }}</a
                >
              </div>
              <div class="content">
                <div>{{ $i18n('ny.sticker.send') }}</div>
                <a href="tg://addstickers?set=NewYearHF" class="link link_break" target="_blank"
                  >tg://addstickers?set=NewYearHF</a
                >
              </div>
            </div>
          </template>
        </base-dropdown>
      </div>
    </template>
  </applicant-limit-data-provider>
</template>

<script>
import { appConfig } from '@/services/config/app-config';
import { userPermissions } from '@/services/config/user-permissions';
import { DateTimeHelper } from '@/util/date-time-helper';
import { NumberHelper } from '@/util/number-helper';
import tariffs from '@/services/tariffs';
import ApplicantLimitDataProvider from '@/components/hf/applicant-limit-data-provider/applicant-limit-data-prodiver.vue';
import ApplicantLimitPromo from '@/components/hf/applicant-limit-promo/applicant-limit-promo.vue';
import BaseButton from '@/components/button/button.vue';
import BaseDropdown from '@/components/ui/base-dropdown/base-dropdown';
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout.vue';

export default {
  name: 'HeaderWarnings',
  components: {
    ApplicantLimitPromo,
    BaseButton,
    BaseDropdown,
    DropdownMenuLayout,
    ApplicantLimitDataProvider
  },
  props: {
    untilNy: {
      type: Object,
      required: true
    }
  },
  computed: {
    account: () => appConfig.get('account'),
    formattedEndDate() {
      return DateTimeHelper.toLongFormat({
        date: tariffs.licenseInfo.current_license.scheduled_end_at
      });
    },
    showExpire() {
      if (
        !tariffs.licenseInfo.current_license ||
        !tariffs.licenseInfo.current_license.scheduled_end_at
      ) {
        return false;
      }

      const showShowExpire = this.expireIn <= 20 && userPermissions.isOwner;

      if (tariffs.licenseInfo.next_license) {
        return (
          ['new', 'cancelled'].includes(tariffs.licenseInfo.next_license.payment_status) &&
          showShowExpire
        );
      }

      return this.isDemo || showShowExpire;
    },
    showNY() {
      return this.untilNy && this.untilNy.total_days <= 14;
    },
    expireIn() {
      if (!tariffs.licenseInfo.current_license) {
        return Infinity;
      }

      return DateTimeHelper.parse({
        date: tariffs.licenseInfo.current_license.scheduled_end_at
      }).since(DateTimeHelper.now(), {
        smallestUnit: 'day'
      }).days;
    },
    expireTitle() {
      if (this.isDemo) {
        return this.$i18n('demo-finish.dropdown.title');
      }

      if (this.expireIn === 0) {
        return this.$i18n('plan.ends.today');
      }

      return this.$i18n('plan.finish.btn', { count: this.expireIn });
    },
    isDemo() {
      return tariffs.isDemo;
    },
    expireButtonHref() {
      const msgBody = encodeURIComponent(
        [
          `${this.$i18n('demo-finish.mail.body.num')}:`,
          '',
          userPermissions.get('name'),
          this.account.name,
          this.account.phone || '',
          `${this.$i18n('demo-finish.mail.body.order')}: ${userPermissions.get('id')}`,
          ''
        ].join('\r\n')
      );
      return `mailto:${appConfig.get('salesEmail')}?subject=${this.$i18n(
        'demo-finish.mail.subject'
      )}&body=${msgBody}`;
    },
    nyButtonTitle() {
      const workingDays = this.untilNy.total_days - this.untilNy.not_working_days;
      if (this.untilNy.total_days <= 0 || !workingDays) {
        return this.$i18n('ny.happy_ny', {
          year: DateTimeHelper.toCustomFormat(
            { date: this.untilNy.deadline, isPlainDate: true },
            {
              year: 'numeric'
            }
          )
        });
      }
      return this.untilNy.production_calendar
        ? this.$i18n('ny.until.working', {
            count: workingDays
          })
        : this.$i18n('ny.until.no_working', {
            count: workingDays
          });
    },
    stikerImg() {
      return require('./sticker.png');
    }
  },
  methods: {
    conversion: NumberHelper.conversion
  }
};
</script>

<style lang="less" module>
.demoButton {
  white-space: nowrap;
  margin: 12px 15px;
}

.ny {
  padding: 10px 15px;
  width: 300px;
}
</style>
<i18n lang="json">
{
  "plan.finish.btn": {
    "ru_RU": "{count, plural, one {Остался # день доступа} other {Осталось # дня доступа}}",
    "en_US": "{count, plural, one {# day of access left} other { # days of access left}}"
  },
  "plan.ends.today": {
    "ru_RU": "Доступ истекает сегодня",
    "en_US": "Your access ends today"
  },
  "demo-finish.dropdown.text.1": {
    "ru_RU": "Оставьте заявку и наш менеджер свяжется с вами для обсуждения условий приобретения Хантфлоу.",
    "en_US": "Leave your contacts and our manager will reach you to discuss conditions of purchasing Huntflow."
  },
  "demo-finish.dropdown.text.2": {
    "ru_RU": "Все ваши данные из&nbsp;демо-доступа будут сохранены.",
    "en_US": "All your data within trial period would be saved."
  },
  "plan-finish.dropdown.text": {
    "ru_RU": "Ваш оплаченный доступ к Хантфлоу истекает {date} года.",
    "en_US": "Your access to Huntflow expires on {date} year."
  },
  "demo-finish.btn.action": {
    "ru_RU": "Оставить заявку",
    "en_US": "Leave contacts"
  },
  "plan.finish.btn.action": {
    "ru_RU": "Отправить заявку на продление",
    "en_US": "Request subscription renewal"
  },
  "applicants-limit.warn-button": {
    "ru_RU": "Лимит базы: {count, plural, one {остался # кандидат} few {осталось # кандидата} other {осталось # кандидатов}}",
    "en_US": "Applicants database limit: {count} left"
  },
  "ny.sticker.title": {
    "ru_RU": "Создайте новогоднее настроение с праздничными стикерами Хантфлоу для Телеграма!",
    "en_US": "Create a New Year mood using Huntflow’s festive Telegram stickers"
  },
  "ny.sticker.take": {
    "ru_RU": "Забрать стикеры себе",
    "en_US": "Pick up stickers"
  },
  "ny.sticker.send": {
    "ru_RU": "Отправьте стикеры друзьям:",
    "en_US": "Send stickers to your friends"
  },
  "demo-finish.dropdown.title": {
    "ru_RU": "Станьте клиентом Хантфлоу",
    "en_US": "Become Huntflow client"
  },
  "demo-finish.mail.subject": {
    "ru_RU": "Заявка менеджеру Хантфлоу",
    "en_US": "Contacts for Huntflow manager"
  },
  "ny.happy_ny": {
    "ru_RU": "С Новым { year } Годом!",
    "en_US": "Happy New Year!"
  },
  "ny.until.working": {
    "ru_RU": "{count, plural, one {Остался { count } рабочий день} few {Осталось { count } рабочих дня} other {Осталось { count } рабочих дней} }",
    "en_US": "{ count } working {count, plural, one {day} other {days}} remain"
  },
  "ny.until.no_working": {
    "ru_RU": "{count, plural, one {Остался { count } день} few {Осталось { count } дня} other {Осталось { count } дней} } до Нового Года!",
    "en_US": "{ count } {count, plural, one {day} other {days}} left until New Year!"
  },
  "demo-finish.mail.body.num": {
    "ru_RU": "Число рекрутеров",
    "en_US": "Number of recruiters"
  },
  "demo-finish.mail.body.order": {
    "ru_RU": "Номер заявки",
    "en_US": "Order number"
  }
}
</i18n>
