<template>
  <div :class="className">
    <content-loader v-if="isLoaderVisible" />
    <template v-else>
      <h2 v-if="title" :class="[$style.heading, $style.title]">
        {{ title }}
      </h2>
      <div v-else :class="$style.heading"><slot name="heading"></slot></div>

      <slot name="prepend"></slot>
      <div :class="$style.main">
        <slot name="main">{{ message }}</slot>
      </div>
      <slot name="content"></slot>
      <slot name="append"></slot>

      <div v-if="hasFooter" :class="[$style.footer, { [$style.footerPadding]: footerWithPadding }]">
        <render-slot :slot-content="footerLayoutSlot">
          <error-wrapper>
            <slot name="error" />
          </error-wrapper>
          <layout-buttons-line>
            <render-slot :slot-content="footerSlot" />
          </layout-buttons-line>
        </render-slot>
      </div>
    </template>
  </div>
</template>

<script>
import widthStyles from '../../shared-css-modules/widths.module.css';
import LayoutButtonsLine from '@/components/layout/buttons-line';
import ContentLoader from '@/components/loader/content-loader.vue';
import { SlotChecker } from '@/util/slot-checker';
import ErrorWrapper from '@/components/popup-layout/error-wrapper.vue';
import { computed } from 'vue';
import RenderSlot from '@/components/render-slot/render-slot.vue';

const MIN_LOADING_TIME_MS = 400;

export default {
  name: 'DropdownMenuLayout',
  components: { RenderSlot, ErrorWrapper, ContentLoader, LayoutButtonsLine },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'auto',
      validator(value) {
        return Boolean(widthStyles[value]) || value === 'auto';
      }
    },
    footerWithPadding: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const footerSlot = SlotChecker.useSlotContent('footer');
    const footerLayoutSlot = SlotChecker.useSlotContent('footer-layout');

    const hasFooter = computed(() => Boolean(footerSlot.value) || Boolean(footerLayoutSlot.value));
    return {
      footerSlot,
      footerLayoutSlot,
      hasFooter
    };
  },
  data() {
    return {
      startTime: undefined,
      isLoaderVisible: this.loading
    };
  },
  computed: {
    className() {
      const sizeClassName = widthStyles[this.size];
      return {
        [this.$style.container]: true,
        [sizeClassName]: Boolean(sizeClassName)
      };
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(loading) {
        clearTimeout(this.timeout);
        if (loading) {
          this.isLoaderVisible = true;
          this.startTime = Date.now();
          return;
        }
        const deltaTime = Date.now() - this.startTime;
        if (deltaTime < MIN_LOADING_TIME_MS) {
          this.timeout = setTimeout(() => {
            this.isLoaderVisible = false;
          }, MIN_LOADING_TIME_MS - deltaTime);
        } else {
          this.isLoaderVisible = false;
        }
      }
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';
.container {
  border-radius: 5px;
}
.heading {
  padding: 10px 15px;
  background-color: @whiteColor;
  border-bottom: 1px solid @borderColor;
  position: sticky;
  top: 0;
  z-index: 1;
}

.title {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
}

.main {
  padding: 10px 15px;
  margin: 0;
}
.footer {
  background-color: @whiteColor;
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.footerPadding {
  padding: 10px 15px;
}

.heading,
.main,
.footer {
  &:empty {
    display: none;
  }
}
</style>

<i18n lang="json">{}</i18n>
