<template>
  <button
    type="button"
    :disabled="disabled"
    class="form-control form-control_size_full form-control_white"
    style="text-align: left; color: darkgray"
    data-qa="add-button"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonInput',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click']
};
</script>

<i18n lang="json">{}</i18n>
