<template>
  <div :class="$style.wrapper" v-bind="$attrs">
    <div :class="$style.labelSection">
      <base-label v-if="label || description" :class="$style.label" :for="id">
        <span :class="$style.title">
          <slot name="label" :label="label">
            <span v-html="label" />
          </slot>
        </span>
        <slot name="hint"></slot>
        <slot name="description" :description="description">
          <div v-if="description" :class="$style.description" v-html="description" />
        </slot>
      </base-label>
      <slot name="additional-control" />
    </div>
    <slot :id="id" />
    <template v-if="!hideErrors && invalid">
      <base-error v-for="(error, index) in showOnlyOneError ? [errors[0]] : errors" :key="index">
        <slot name="error" :error="error">
          {{ error }}
        </slot>
      </base-error>
    </template>
    <template v-else>
      <div :class="$style.warning">
        <slot name="warning" />
      </div>
      <div :class="$style.info">
        <slot name="info" />
      </div>
    </template>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';

import BaseLabel from '@/components/label/label.vue';
import BaseError from '@/components/error/error.vue';

export default {
  name: 'FormField',
  components: { BaseLabel, BaseError },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    invalid: Boolean,
    hideErrors: Boolean,
    showOnlyOneError: Boolean,
    errors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      id: nanoid(10)
    };
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables.less';

.delimiter {
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid @borderColor;
}

.labelSection {
  display: flex;
  justify-content: space-between;
}

.label {
  margin-bottom: 3px;
  display: inline-flex;
  align-items: center;
  width: max-content;
}

.title {
  font-weight: var(--title-font-weight, normal);
}
.description {
  color: #999;
  font-size: 14px;
  line-height: 18px;
  white-space: pre-wrap;
  word-break: break-word;
  margin: 5px 0 10px;
}
.warning:not(:empty) {
  padding-top: 5px;
  font-size: 14px;
  line-height: 18px;
  color: @yellowColor;
}
.info:not(:empty) {
  padding-top: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
}
</style>

<i18n lang="json">{}</i18n>
