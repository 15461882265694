<template>
  <base-item :deep="deep" bordered :icon="false">
    <base-loader :class="$style.loader" />
  </base-item>
</template>

<script>
import BaseItem from './base-item';
import { BaseLoader } from '@/components/loader';

export default {
  name: 'SpinnerItem',
  components: { BaseItem, BaseLoader },
  props: {
    deep: BaseItem.props.deep
  }
};
</script>

<style module>
.loader {
  width: 18px;
  height: auto;
  margin: 0 0 0 32px;
}
</style>

<i18n lang="json">{}</i18n>
