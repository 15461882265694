<template>
  <base-row>
    <div :class="$style.content">
      <slot name="default" :title-class="$style.primaryTitle" :title="title">
        <span :class="$style.primaryTitle" data-qa="title">{{ title }}</span>
      </slot>
    </div>
  </base-row>
</template>

<script>
import BaseRow from './row';

export default {
  name: 'LayoutHeader',
  components: {
    BaseRow
  },
  props: {
    title: {
      type: String,
      default: undefined
    }
  }
};
</script>

<style lang="less" module>
.content {
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #cccccc;
  word-break: break-word;
}

.primaryTitle {
  font-size: 20px;
  line-height: 1.1;
}
</style>

<i18n lang="json">{}</i18n>
