<template>
  <div :class="$style.root">
    <view-field
      v-for="additionalField in additionalFields"
      :key="additionalField.key"
      :label="getAdditionalFieldLabel(additionalField)"
    >
      <widget-value :value="additionalField.value" />
    </view-field>

    <vjsf-form
      :schema="schema"
      :ui-schema="resolvedUiSchema"
      :form-data="formData"
      :widgets="$options.widgets"
      view-mode
    />
  </div>
</template>

<script>
import { VjsfForm } from '@/components/vjsf';
import ViewField from '@/components/vjsf/view-widgets/view-field';
import WidgetValue from '@/components/vjsf/view-widgets/base-widget/widget-value';
import CompensationTemplate from '@/components/hf/request-list-item/widgets/compensation-template';
import { setObjectTemplateComponents } from '@/components/vjsf/helpers/set-object-template-components';
import { widgets } from './widgets';
import { AdditionalSchemaKeys } from './additional-schema-keys';

export default {
  name: 'RequestListItemBody',
  components: { VjsfForm, ViewField, WidgetValue },
  widgets,
  props: {
    schema: {
      type: Object,
      required: true
    },
    uiSchema: {
      type: Object,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    additionalFields: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    resolvedUiSchema() {
      return setObjectTemplateComponents(this.uiSchema, {
        [CompensationTemplate.name]: CompensationTemplate
      });
    }
  },
  methods: {
    getAdditionalFieldLabel(field) {
      const { key, title } = field;
      if (title) {
        return title;
      }
      if (key === AdditionalSchemaKeys.watcher) {
        return this.$trlMessage('member_type.watcher');
      }
      if (key === AdditionalSchemaKeys.takenBy) {
        return this.$trlMessage('vacancy_request.popup.taken');
      }
      console.error('Missing addtional field title');
      return '';
    }
  }
};
</script>

<style lang="less" module>
.root {
  --field-margin: 0;
}
.root :global(fieldset) {
  margin: 0;
}
.root :global(.form-group) {
  margin: 0;
}
</style>

<i18n lang="json">{}</i18n>
