import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import find from 'lodash/find';
import jsonPointer from 'json-pointer';
import jsonSchemaTraverse from 'json-schema-traverse';
import { DateTimeHelper } from '@/util/date-time-helper';
import { WIDGET_TYPE } from '@/components/vjsf/vjsf';

export function mergeVacancies(vacancyFields, candidateFields) {
  const whitelist = ['deadline', 'applicants_to_hire', 'hide-vacancy-deadline', 'position_shtat'];
  return {
    ...vacancyFields,
    ...pick(candidateFields, whitelist)
  };
}

export function getChildVacancyByUniqueFields(vacancies, values, multivacancyFields) {
  const uniqFieldKeys = Object.keys(pickBy(multivacancyFields, (field) => field.unique));
  return find(vacancies, pick(values, uniqFieldKeys));
}

export function getVacancyFields(vacancyRequest, vacancyRequestSchema) {
  const fieldsMapping = getVacancyFieldMapping(vacancyRequestSchema);
  const fieldValues = Array.from(fieldsMapping).reduce(
    (acc, [vacancyRequestPointer, vacancyPointers]) => {
      const isDateWidget =
        jsonPointer.get(vacancyRequestSchema.ui_schema, vacancyRequestPointer)?.['ui:widget'] ===
        WIDGET_TYPE.DATE;
      const value = jsonPointer.get(vacancyRequest.values, vacancyRequestPointer);
      vacancyPointers.forEach((vacancyPointer) => {
        jsonPointer.set(acc, vacancyPointer, isDateWidget ? fixDate(value) : value);
      });
      return acc;
    },
    {}
  );

  return {
    ...fieldValues,
    position: vacancyRequest.position,
    account_division: fieldValues.account_division
      ? parseInt(fieldValues.account_division, 10)
      : null,
    vacancy_request: vacancyRequest.id
  };

  function fixDate(date) {
    if (date) {
      try {
        return DateTimeHelper.parse({ date }).toServerFormat({
          includeTime: false
        });
      } catch {
        return DateTimeHelper.parseLegacyDate({ date }).toServerFormat({
          includeTime: false
        });
      }
    }
  }
}

// не оч понятное название..
// вощем результат - словарь, где
// ключ - jsonpointer в данных заявки
// значение - массив jsonpointer-ов в данных вакансии
// (т.к. одно значение из заявки можно подставить в несколько полей вакансии)
//
// TODO:
// в эпике
// https://huntflow.atlassian.net/browse/PROD-4516
// будет переделано, т.к. обхода одной схемы заявок недостаточно
// для переноса данных из между разными типами полей
// (напр. словарь из заявки в строку в вакансии)
//
// нужно будет обходить схемы вакансии и заявки И ui-cхемы вакансии и заявки
function getVacancyFieldMapping(vacancyRequestSchema) {
  const schemaFieldsMap = new Map();

  const normalizeSchemaPointer = (str) =>
    str.replaceAll(/\/allOf\/\d+\/(if|then)/g, '').replaceAll('/properties', '');

  const traverseCb = (...args) => {
    const [schema, pointer, , , parentKeyword] = args;
    // иначе обходить будет всякое лишнее (allOf, if, then)
    if (parentKeyword !== 'properties') {
      return;
    }

    const normalizedPointer = normalizeSchemaPointer(pointer);
    if (!schemaFieldsMap.has(normalizedPointer) && schema.metadata?.vacancy_field) {
      schemaFieldsMap.set(
        normalizedPointer,
        schema.metadata.vacancy_field.map((field) => `/${field}`)
      );
    }
  };

  jsonSchemaTraverse(vacancyRequestSchema.schema, { cb: { pre: traverseCb } });
  return schemaFieldsMap;
}
