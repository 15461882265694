import { defineAsyncComponent, Component, h } from 'vue';
import { migrateHelper } from '@/util/migrate-helper';

interface Options {
  hideLoader?: boolean;
}

function lazyLoadView<T extends Component>(
  AsyncView: Promise<T>,
  options: Options = {}
): Promise<Component> {
  const AsyncHandler = defineAsyncComponent({
    loader: () => AsyncView,
    loadingComponent: options.hideLoader
      ? require('@/components/vue-void.vue').default
      : require('@/components/loader/content-loader.vue').default
  });

  return Promise.resolve({
    inheritAttrs: false,
    render() {
      return h(AsyncHandler, {
        ...this.$attrs,
        ...migrateHelper.convertListeners(this.$listeners)
      });
    }
  });
}

export const PageHelper = {
  lazyLoadView
};
