<template>
  <a :href="link" :class="`button button_${color}`" data-qa="apply-tariff-upgrade">
    <slot>{{ $trlMessage('promo.upgrade.apply') }}</slot>
  </a>
</template>

<script>
import { appConfig } from '@/services/config/app-config';
import mailto from '@/util/mailto-helper';

export default {
  name: 'ApplyButton',
  props: {
    color: {
      type: String,
      default: 'green'
    },
    mailText: {
      type: String,
      default: ''
    },
    reason: {
      type: String,
      default: ''
    },
    reasonText: {
      type: String,
      default: ''
    }
  },
  computed: {
    link() {
      const accountingEmail = appConfig.get('accountingEmail');
      const orgName = window.Organization?.account.name;
      if (this.mailText) {
        return mailto(this.mailText, accountingEmail, {
          orgName
        });
      }
      const tariffName = window.LicenseInfo?.current_license.tariff_group.name;
      const reason = this.reason || this.reasonText;

      const subjectAppend = reason
        ? // eslint-disable-next-line no-restricted-syntax
          `: ${this.$trlMessage(reason)} (${orgName} ${this.$trlMessage(
            'promo.tariff_upgrade.now'
          )} ${tariffName})`
        : ` (${orgName})`;
      return mailto('promo.tariff_upgrade', accountingEmail, {
        subject: subjectAppend
      });
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
