<template>
  <div :class="$style.hint">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FieldHint'
};
</script>

<style module lang="less">
@import '~@less/common/variables.less';

.hint {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #999999;
}
.hint a {
  color: inherit;
}
.hint a:hover {
  color: @hoverColor;
}
</style>

<i18n lang="json">{}</i18n>
