<template>
  <div :class="[$style.buttonWrapper, buttonClass]">
    <div v-if="loading" :class="[$style.loader, loadingClass]">
      <base-loader small />
    </div>
    <button v-else type="button" :class="classes" v-bind="$attrs" @click="handleClick">
      <span>
        <slot />
      </span>
    </button>
  </div>
</template>

<script>
import BaseLoader from '../loader/loader.vue';

export default {
  name: 'MoreButton',
  components: {
    BaseLoader
  },
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'm',
      validator(value) {
        return ['m', 'l', 'full'].includes(value);
      }
    },
    loadingClass: {
      type: String,
      default: ''
    },
    buttonClass: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    classes() {
      const classes = [this.$style.button];
      switch (this.size) {
        case 'l':
          classes.push(this.$style.large);
          break;
        case 'full':
          classes.push(this.$style.full);
          break;
      }
      return classes;
    }
  },
  methods: {
    handleClick(event) {
      event.preventDefault();
      this.state = !this.state;
      this.$emit('click');
    }
  }
};
</script>

<style module>
.buttonWrapper {
  padding-top: 8px;
}

.button {
  @mixin reset-button-styles;

  font-size: 11px;
  letter-spacing: 1px;
  white-space: nowrap;
  text-transform: uppercase;

  display: inline-flex;
  vertical-align: middle;
  padding: 0;
  outline: none;
  align-items: center;

  cursor: pointer;
  color: var(--more-button-text-color, #8f999c);
  border: 0;
  background-color: transparent;

  max-width: 100%;

  &::before,
  &::after {
    border-bottom: solid 1px var(--more-button-line-color, #8f999c);
    opacity: 0.25;
    content: '';
    display: block;
    height: 0;
    position: relative;
    vertical-align: middle;
    width: 30px;
    min-width: 6px;
    flex: 1;
  }

  &.large {
    font-size: 18px;
    line-height: 22px;
    text-transform: none;
    letter-spacing: 0;
  }

  &.full {
    width: 100%;
  }

  span {
    margin: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

<i18n lang="json">{}</i18n>
