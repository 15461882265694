<template>
  <label :class="$style.container">
    <slot />
  </label>
</template>

<script>
export default {
  name: 'BaseLabel'
};
</script>

<style module>
.container {
  display: block;
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
</style>

<i18n lang="json">{}</i18n>
