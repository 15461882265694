<template>
  <div :class="className" data-qa="popup-layout-footer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VFooter',
  props: {
    hasDelimiter: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    className() {
      return {
        [this.$style.footer]: true,
        [this.$style.delimiter]: this.hasDelimiter
      };
    }
  }
};
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 0;
}

.delimiter {
  border-top: 1px solid @borderColor;
}
</style>

<i18n lang="json">{}</i18n>
