<template>
  <base-item-layout
    :disabled="!canToggle"
    :bordered="bordered"
    :deep="deep"
    :active="active"
    :highlight="highlight"
    :selected="selected"
    :icon="icon"
    :text-gap-y="textGapY"
    :min-height="minHeight"
    @click="$emit('click')"
  >
    <template #icon>
      <slot name="icon">
        <base-icon
          v-if="selected"
          :class="{ [$style.disabled]: !canToggle }"
          type="checked-item-mark"
        />
      </slot>
    </template>
    <template #before-content>
      <slot name="before-content" />
    </template>
    <template #before-caption>
      <slot name="before-caption" />
    </template>
    <template #default>
      <slot />
    </template>
    <template #hint>
      <slot name="hint" />
    </template>
    <template #after-caption>
      <slot name="after-caption" />
    </template>
    <template #subtitle>
      <slot name="subtitle" />
    </template>
    <template #after-content>
      <slot name="after-content" />
    </template>
  </base-item-layout>
</template>

<script>
import BaseIcon from '@/components/icon';
import BaseItemLayout from './base-item-layout';

export default {
  name: 'SelectItem',
  components: { BaseItemLayout, BaseIcon },
  props: {
    disabled: BaseItemLayout.props.disabled,
    active: BaseItemLayout.props.active,
    bordered: BaseItemLayout.props.bordered,
    icon: BaseItemLayout.props.icon,
    deep: BaseItemLayout.props.deep,
    highlight: BaseItemLayout.props.highlight,
    textGapY: BaseItemLayout.props.textGapY,
    minHeight: BaseItemLayout.props.minHeight,
    selected: Boolean,
    available: {
      type: Boolean,
      default: true
    }
  },
  emits: ['click'],
  computed: {
    canToggle() {
      return !this.disabled && this.available;
    }
  }
};
</script>

<style module>
.disabled {
  color: #bfbfbf;
}
</style>

<i18n lang="json">{}</i18n>
