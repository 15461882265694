<template>
  <local-items-filter highlight :items="selectItems" :fields="{ name: 'name', email: 'email' }">
    <template #default="{ items: filteredItems, query, setQuery }">
      <dropdown-autocomplete
        :id="id"
        :hierarchy-map="hierarchyMap"
        :items="filteredItems"
        :query="query"
        :value="dropdownAutocompleteValue"
        :dropdown-size="dropdownProps.size"
        :max-height="dropdownProps.maxHeight"
        :multiple="multiple"
        :only-leaf="onlyLeaf"
        ignore-same-value-check
        @search="setQuery"
        @change="handleChange"
      >
        <template #content>
          <slot name="content" />
        </template>
        <template #toggle="slotProps">
          <slot
            name="toggle"
            v-bind="slotProps"
            :innerValue="innerValue"
            :label="selectedItemName"
            :count="publicValue?.length"
          >
            <toggle-button
              :id="id"
              :invalid="invalid"
              :disabled="disabled"
              :text="selectedItemName"
              @click="slotProps.toggle"
            >
              <template #icon>
                <slot name="toggle-icon" />
              </template>
              <select-button-text
                :multiple="multiple"
                :title="multiple ? selectedItemName : publicValue?.name"
                :count="value?.length"
              />
            </toggle-button>
          </slot>
        </template>
        <template #item="{ item, selected, highlight }">
          <colleague-head-item
            v-if="item.isHead"
            :item="item"
            :selected="selected"
            :highlight="highlight"
            user-id-field="member"
            bordered
          />
          <colleague-item
            v-else
            :item="item"
            :selected="selected"
            :highlight="highlight"
            user-id-field="member"
            bordered
          />
        </template>
      </dropdown-autocomplete>
    </template>
  </local-items-filter>
</template>

<script>
import flattenDeep from 'lodash/flattenDeep';
import uniqBy from 'lodash/uniqBy';
import { mapGetters } from 'vuex';
import { makeHierarchyMap } from '@/components/hierarchy/hierarchy-map';
import ToggleButton from '@/components/base-autocomplete/toggle-button';
import SelectButtonText from '@/components/dictionary/select-button-text.vue';
import LocalItemsFilter from '@/components/local-items-filter/local-items-filter';
import DropdownAutocomplete from '@/components/base-autocomplete/dropdown-autocomplete';
import ColleagueItem from '@/components/list-item/colleague-item';
import ColleagueHeadItem from '@/components/list-item/colleague-head-item';
import { normalizeValue } from '@/components/base-list/normalize-value';

export default {
  name: 'RecruitersSelect',
  components: {
    ToggleButton,
    SelectButtonText,
    DropdownAutocomplete,
    LocalItemsFilter,
    ColleagueItem,
    ColleagueHeadItem
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  dropdownDefaults: {
    size: 'larger',
    block: true
  },
  props: {
    id: {
      type: String,
      default: undefined
    },
    value: {
      type: [Array, Number],
      default: null
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    exclude: {
      type: Array,
      default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    bosses: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'full'
    },
    buttonClass: {
      type: String,
      default: null
    },
    dropdownProps: {
      type: Object,
      default: () => ({})
    },
    invalid: {
      type: Boolean,
      default: false
    },
    onlyLeaf: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'show'],
  computed: {
    innerValue() {
      const normalizedValue = normalizeValue(this.value, { multiple: this.multiple });
      if (this.multiple) {
        const valueItems = this.selectItems.filter((item) =>
          normalizedValue.includes(item[this.valueKey])
        );
        return uniqBy([...valueItems, ...this.getParentItems(valueItems)], ({ id }) => id);
      }
      return this.selectItems.find((item) => item[this.valueKey] === normalizedValue) || null;
    },
    dropdownAutocompleteValue() {
      if (Array.isArray(this.innerValue)) {
        return this.innerValue.map((item) => item.id);
      }
      return this.innerValue?.id;
    },
    ...mapGetters('members', {
      recruiterFlatTreeWithGroup: 'recruiterFlatTreeWithGroup',
      potentialBosses: 'potentialBosses'
    }),
    selectedItemName() {
      return this.$trlMessage('count.recruiter.select');
    },
    publicValue() {
      if (!this.multiple) {
        return this.innerValue;
      }
      return this.innerValue.filter((el) => {
        return !el.isGroup;
      });
    },
    buttonClassName() {
      if (this.buttonClass) {
        return this.buttonClass;
      }

      return {
        'form-control': true,
        'form-control_select-like': true,
        'form-control_invalid': this.invalid,
        [`form-control_size_${this.size}`]: this.size
      };
    },
    recruiters() {
      const multiple = this.multiple;

      const list = this.bosses
        ? this.potentialBosses(this.exclude, this.valueKey)
        : this.recruiterFlatTreeWithGroup(this.exclude, this.valueKey);

      return list.map((v) => ({
        ...v,
        disabled: v.isGroup && !multiple
      }));
    },
    withoutHeadItem() {
      return {
        id: null,
        name: this.$trlMessage('coworkers.no_head')
      };
    },
    selectItems() {
      if (this.bosses) {
        return [this.withoutHeadItem, ...this.recruiters];
      }
      return this.recruiters;
    },
    hierarchyMap() {
      const map = makeHierarchyMap(
        this.recruiters.reduce((acc, item) => {
          acc[item.id] = item.parent || null;
          return acc;
        }, {})
      );
      return map;
    }
  },
  methods: {
    // если у родителя выбраны все дочки, то считаем родителя выбранным
    getParentItems(items) {
      const selectedIds = items.map(({ id }) => id);
      const parentIds = Object.entries(this.hierarchyMap.deepChildrenMap).reduce(
        (acc, [parentId, childIds]) => {
          if (
            childIds.every((id) => {
              if (typeof id === 'string') {
                return true;
              }
              return selectedIds.includes(id) && !acc.includes(parentId);
            })
          ) {
            acc.push(parentId);
          }
          return acc;
        },
        []
      );
      return this.selectItems.filter((item) => parentIds.includes(item.id));
    },
    handleChange(value) {
      const mapFn = (v) => this.selectItems.find((item) => item.id === v);
      const item = Array.isArray(value) ? value.map(mapFn) : mapFn(value);
      if (this.multiple) {
        let value = item.map((item) => {
          return item.isGroup
            ? [item, ...this.hierarchyMap.deepChildrenMap[item.id].map(mapFn)]
            : item;
        });
        value = flattenDeep(value);
        value = value.filter(({ isGroup }) => !isGroup);
        value = uniqBy(value, ({ id }) => id);
        this.$emit('input', value.map((i) => i[this.valueKey]).filter(Boolean));
        return;
      }
      this.$emit('input', item[this.valueKey]);
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
