<template>
  <dropdown-menu-layout :size="dropdownSize">
    <template #content>
      <div :class="$style.container" :style="{ maxHeight }">
        <slot name="before" :down="next" :up="prev" :enter="enter" />
        <div v-if="!items.length" :class="$style.item">
          <slot name="not-found">
            <empty-item />
          </slot>
        </div>
        <virtual-scroller
          v-else
          ref="scroller"
          tabindex="0"
          :class="$style.scroller"
          :items="items"
          :min-item-size="itemSize"
          @scroll="handleScroll"
          @keydown.down.exact.native.prevent="next"
          @keydown.up.exact.native.prevent="prev"
          @keydown.enter.exact.native.prevent="enter"
          @item-mouseenter="handleCursorSelect"
        >
          <template #before>
            <slot name="before-list" />
          </template>
          <template #default="{ item, index }">
            <base-item-wrapper
              :class="$style.item"
              :item="item"
              :index="index"
              :highlight="index === pointer"
            >
              <template #default="{ item: currentItem, highlight }">
                <select-item-wrapper :value="value" @click="select(index)">
                  <slot
                    name="item"
                    :item="currentItem"
                    :index="index"
                    :highlight="highlight"
                    :caption-field="captionField"
                  >
                    <select-item :bordered="index !== items.length - 1" :highlight="highlight">
                      <slot name="item-title" :item="currentItem">
                        <span
                          :title="currentItem[captionField]"
                          v-html="
                            currentItem[captionField + '_highlight'] || currentItem[captionField]
                          "
                        ></span>
                      </slot>
                      <template #hint>
                        <slot name="item-hint" :item="currentItem" />
                      </template>
                    </select-item>
                  </slot>
                </select-item-wrapper>
              </template>
            </base-item-wrapper>
          </template>
          <template #after>
            <slot name="after-list" />
          </template>
        </virtual-scroller>
        <slot name="after" :down="next" :up="prev" :enter="enter" />
      </div>
    </template>
  </dropdown-menu-layout>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, ref, toRefs, watch } from 'vue';

import EmptyItem from '@/components/list-item/empty-item.vue';
import DropdownMenuLayout from '@/components/dropdown-menu-layout/dropdown-menu-layout.vue';
import VirtualScroller from '@/components/ui/virtual-scroller/virtual-scroller.vue';
import BaseItemWrapper from '@/components/base-list/base-item-wrapper.vue';
import SelectItem from '@/components/list-item/select-item.vue';
import SelectItemWrapper from '@/components/base-list/select-item-wrapper.vue';
import { useListCursor } from '@/components/ui/base-timepicker/use-list-cursor';

export default defineComponent({
  name: 'ItemList',
  components: {
    SelectItemWrapper,
    SelectItem,
    BaseItemWrapper,
    VirtualScroller,
    DropdownMenuLayout,
    EmptyItem
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    dropdownSize: DropdownMenuLayout.props.size,
    items: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: undefined
    },
    scrollTo: {
      type: String,
      default: undefined
    },
    itemSize: {
      type: [Number, String],
      default: 42
    },
    maxHeight: {
      type: String,
      default: '210px'
    },
    captionField: {
      type: String,
      default: 'name'
    }
  },
  emits: ['change', 'click', 'enter', 'scroll'],
  setup(props, { emit }) {
    const { items, value, scrollTo } = toRefs(props);

    const scroller = ref(null);
    const disableSelection = ref(false);
    const {
      next: cursorNext,
      prev: cursorPrev,
      select: cursorSelect,
      pointer,
      reset: cursorReset
    } = useListCursor(items, {
      selected: value
    });

    const indexToScroll = computed(() =>
      items.value.findIndex((item) => item.id === scrollTo.value)
    );

    function next() {
      if (pointer.value === undefined) {
        const index = items.value.findIndex((item) => item.id > value.value);
        cursorSelect(index);
      } else {
        cursorNext();
      }
      select();
    }
    function prev() {
      if (pointer.value === undefined) {
        const index = items.value.findLastIndex((item) => item.id < value.value);
        cursorSelect(index);
      } else {
        cursorPrev();
      }
      select();
    }

    let timeout;
    function onValueChange() {
      nextTick(() => {
        disableSelectionTemporally();
        cursorReset();
        scroller.value.scrollToIndex(indexToScroll.value);
      });
    }

    function disableSelectionTemporally() {
      clearTimeout(timeout);
      disableSelection.value = true;
      timeout = setTimeout(() => {
        disableSelection.value = false;
      }, 100);
    }

    watch(value, (selected) => {
      if (!selected) {
        return;
      }
      onValueChange();
    });
    onMounted(() => {
      nextTick(() => {
        onValueChange();
      });
    });

    function select(index) {
      emit('change', items.value[index ?? pointer.value]?.id);
      if (index !== undefined) {
        emit('click');
      }
    }

    return {
      pointer,
      handleCursorSelect: (index) => {
        if (!disableSelection.value) {
          cursorSelect(index);
        }
      },
      handleScroll: () => {
        disableSelectionTemporally();
        emit('scroll');
      },
      next,
      prev,
      enter: () => {
        emit('enter');
        if (pointer.value === undefined) {
          return;
        }
        select();
      },
      scroller,
      select
    };
  }
});
</script>

<style module>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.scroller {
  outline: none;
  flex-grow: 1;
  text-align: left;
}
.item {
  display: flex;
  width: 100%;
  justify-content: center;
  cursor: default;
  user-select: none;
}
</style>

<i18n lang="json">{}</i18n>
