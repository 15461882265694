<template>
  <div :class="{ [$style.item]: true, [$style.activeItem]: isActive }" v-on="$listeners">
    <div :class="$style.content">
      <base-userpic v-if="hasPhoto(item)" :src="getPhoto(item)" :class="$style.icon" />
      <base-icon v-else-if="hasIcon(item)" :type="getIconType(item)" :class="$style.icon" />
      <div :class="$style.itemTitles">
        <span :class="[$style.itemTitle, $style.itemPrimaryTitle]">
          {{ primaryTitle }}
        </span>
        <span v-if="secondaryTitle" :class="[$style.itemTitle, $style.itemSecondaryTitle]">
          {{ secondaryTitle }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { EntityType } from '@/api/photo';
import { PhotoHelper } from '@/modules/applicant-photo-editor/helper';
import { useGlobal } from '@/composables/use-global';
import { useStore } from 'vuex';
import BaseUserpic from '@/components/userpic/userpic';
import BaseIcon from '../icon.vue';

export default {
  name: 'EmailAutocompleteItem',
  components: {
    BaseUserpic,
    BaseIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { $trlMessage } = useGlobal();
    const store = useStore();

    const accountRef = computed(() => store.getters['config/account']);
    const isMe = computed(() => props.item.member === accountRef.value.id);

    const primaryTitle = computed(() =>
      isMe.value ? $trlMessage('Me') : props.item.name || props.item.email
    );
    const secondaryTitle = computed(() =>
      primaryTitle.value !== props.item.email ? props.item.email : null
    );

    const getPhoto = (item) => {
      return PhotoHelper.getPhotoUrl({ entity: item, type: EntityType.USER });
    };

    return {
      getPhoto,
      getIconType,
      hasPhoto: (item) => Boolean(item.member) && getPhoto(item),
      hasIcon: (item) => Boolean(getIconType(item)),
      primaryTitle,
      secondaryTitle
    };
  }
};

function getIconType(item) {
  if (item.member) {
    return 'circle-profile';
  }
  if (item.resource) {
    return 'circle-chair';
  }
  return undefined;
}
</script>

<style module>
.item {
  user-select: none;
  letter-spacing: 0;
  padding-left: 35px;

  &.activeItem {
    background-color: #f6f6f6;
  }
}

.icon {
  width: 22px;
  height: 22px;
  margin-right: 7px;
  flex: none;
}

.content {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $borderColor;
  height: 42px;
}

.itemTitles {
  @mixin ellipsis;
  flex: 1;
}

.itemTitle {
  font-size: 16px;
  @mixin ellipsis;
}

.itemPrimaryTitle {
  color: $textColor;
}

.itemSecondaryTitle {
  color: #929097;
  margin-left: 4px;
}
</style>

<i18n lang="json">{}</i18n>
