<template>
  <div :class="$style.tooltip">
    <base-tooltip :distance="5">
      <template #content>
        <!-- eslint-disable-next-line vue/no-restricted-syntax -->
        {{ $trlMessage('wysiwyg.button.' + name) }}
      </template>
      <template #default="{ trigger }">
        <button :ref="trigger" type="button" v-bind="$attrs" :class="className" v-on="$listeners">
          <icon :name="name" />
        </button>
      </template>
    </base-tooltip>
  </div>
</template>

<script>
import Icon from './icon.vue';
import BaseTooltip from '@/components/ui/base-tooltip/base-tooltip.vue';

export default {
  name: 'ItemButton',
  components: {
    BaseTooltip,
    Icon
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    className() {
      return {
        [this.$style.menubarButton]: true,
        [this.$style.menubarButtonActive]: this.active
      };
    }
  }
};
</script>

<style module>
@define-mixin buttonSize {
  height: 32px;
  width: 32px;
}

.tooltip {
  @mixin buttonSize;
  line-height: 0;
  flex: none;
  display: block;
}

.menubarButton {
  @mixin reset-button-styles;
  @mixin buttonSize;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: default;

  &:hover {
    background-color: #f5f5f5;
  }
}

.menubarButtonActive {
  background-color: #444444;
  color: #ffffff;
  &:hover {
    background-color: #151319;
  }
}
</style>

<i18n lang="json">{}</i18n>
