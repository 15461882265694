<template>
  <popup-layout :title="$trlMessage('title.vacancy_request.sent')">
    <template #main>
      <p :class="$style.message">
        {{ $trlMessage('body.vacancy_request.sent') }}
      </p>
      <base-button kind="light" @click="$emit('close')">
        {{ $trlMessage('button.close') }}
      </base-button>
    </template>
  </popup-layout>
</template>

<script>
import BaseButton from '@/components/button/button.vue';
import PopupLayout from '@/components/popup-layout/popup-layout.vue';

export default {
  name: 'RequestSuccess',
  components: {
    BaseButton,
    PopupLayout
  },
  emits: ['close']
};
</script>

<style lang="less" module>
.message {
  margin: 0 0 20px 0;
}
</style>

<i18n lang="json">{}</i18n>
