import pickBy from 'lodash/pickBy';
import { LocationQueryRaw } from 'vue-router';

import { ApiLayer } from '@/api/utils/api-layer';
import axios, { AxiosClient } from '@/api/utils/client/axios';
import { PaginatedList, ItemList } from '@/api/utils/types';
import { ApplicantExternalAPI } from '@/api/applicant-external';
import { CancelablePromise } from '@/api/utils/cancelable-promise';
import { Action } from '@/modules/search/types/action';
import { MessageEvent } from '@/types/poller-message';
import { AgreementState } from '@/types/agreement-state';
import { Applicant, ApplicantLink, ApplicantResponse, ApplicantSocial } from '@/types/applicant';
import { ApplicantVacancyLog } from '@/types/applicant-log';
import { ApplicantExternal } from '@/types/applicant-external';
import { Attendee } from '@/types/attendee';

interface IApplicantListFilter {
  vacancy?: number;
  status: number | 'all';
  page: number;
  count: number;
  applicants?: number[];
  short_info?: boolean;
}

interface ApplicantFetchParams {
  with_resolved_doubles?: boolean;
}

interface SearchFilter {
  info?: boolean | 'short';
  page: number;
  count: number;
  action?: `${Action}`;
  recruiter?: number[];
  vacancy?: number[];
  status?: number[];
  rejection_reason?: number[];
  only_current_status?: 0 | 1;
  period?: {
    start: string;
    end: string;
  };
  account_source?: number[];
  agreement_state?: `${AgreementState}`[];
  operator?: string;
  field?: string;
  tag?: number[];
  q: string;
}

interface ApplicantSearchResultItem {
  account: number | null;
  account_source: number | null;
  birthday: string | null;
  company: string | null;
  created: string;
  email: string | null;
  first_name: string;
  id: number;
  is_viewed_by_me: boolean;
  last_name: string;
  middle_name: string | null;
  money: string | null;
  order: number;
  phone: string | null;
  photo: string | null;
  position: string | null;
  questionary: string | null;
  removed: string | null;
  skype: string | null;
  source: string | null;
}

interface ViewedApplicant {
  applicant_id: number;
  vacancies_ids: number[];
}

interface ApplicantsStatsFilter {
  vacancy: number;
  forAllVacancies: boolean; // В таком виде передаёт фронт
  for_all_vacancies: boolean; // В таком виде ждёт бэк
}

interface ModifyFields {
  birthday_day: string;
  birthday_month: string;
  birthday_year: string;
  company: string;
  email: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  money: string;
  phone: string;
  photo: null;
  position: string;
  skype: string;
  social: ApplicantSocial[];
  externals: ApplicantExternal[];
}

interface SendPayload {
  vacancy: number | null;
  description: string;
  external: number;
  resume: 'hide_salary' | '';
  copy_me: boolean;
  attendees: Attendee[];
}

interface ItemsResponse<T> {
  bloat_limit: number | null;
  items: T[];
}

interface ApplicantViewedStatus {
  id: number;
  is_viewed_by_me: boolean;
}

interface ApplicantStatusStats {
  status: string | number;
  count: number;
}

export enum ApplicantFilter {
  MINE = 0,
  ALL = 1
}

export enum ApplicantViewField {
  FULL_NAME = 'full_name',
  ACTIVE_VACANCIES_COUNT = 'active_vacancies_count',
  WANTED_POSITION = 'wanted_position',
  WANTED_SALARY = 'wanted_salary',
  BIRTHDAY = 'birthday',
  WORK_EXPERIENCE = 'work_experience',
  LAST_POSITION = 'last_position',
  LAST_WORKPLACE = 'last_workplace',
  SOURCE = 'source',
  TAGS = 'tags'
}

interface ApplicantViewSettings {
  // 'applicant-filter' отсутствует для заказчиков
  'applicant-filter'?: ApplicantFilter;
  items: Array<{
    id: ApplicantViewField;
    checked: boolean;
    can_change: boolean;
    display_order: number;
  }>;
}

class ApplicantLayer extends ApiLayer<AxiosClient> {
  constructor(client: AxiosClient) {
    super(client);

    this.fetchAllCount = this.memoizeMethod(
      this.fetchAllCount,
      ({ invalidate, message: { event } }) => {
        switch (true) {
          case [MessageEvent.applicantAdd, MessageEvent.applicantRemove].includes(event):
            invalidate();
        }
      }
    );

    this.fetchByIdMemo = this.memoizeMethod(
      this.fetchByIdMemo,
      ({ invalidate, message: { event, data } }, id) => {
        if ([MessageEvent.vacancyApplicantAdd, MessageEvent.vacancyApplicantEdit].includes(event)) {
          const payload = data as ApplicantLink;
          if (payload.applicant === id) {
            invalidate();
          }
        }

        if ([MessageEvent.applicantEdit, MessageEvent.applicantRemove].includes(event)) {
          const payload = data as Applicant;
          if (payload.id === id) {
            invalidate();
          }
        }
      }
    );
  }

  create(data: ModifyFields) {
    return this.methods.post<Applicant>('/applicants', data).then((applicant) => {
      applicant.links = applicant.links || [];
      return applicant;
    });
  }

  update(applicantId: number, data: ModifyFields) {
    return this.methods.put<Applicant>(`/applicants/${applicantId}`, data);
  }

  patch(applicantId: number, data: Partial<ModifyFields>) {
    return this.methods.patch<Applicant>(`/applicants/${applicantId}`, data);
  }

  delete(applicantId: number) {
    return this.methods.delete(`/applicants/${applicantId}`);
  }

  send(applicantId: number, payload: SendPayload) {
    return this.methods.post<ApplicantVacancyLog>(`/applicant/${applicantId}/send`, payload);
  }

  fetchStats() {
    return this.methods
      .get<ItemsResponse<ApplicantStatusStats>>('/applicants/status/stats')
      .then((data) => ({
        limit: data.bloat_limit,
        stats: data.items
      }));
  }

  fetchStatsByFilter(rawQuery: Partial<ApplicantsStatsFilter> = {}) {
    const { forAllVacancies, ...other } = rawQuery;
    const params = { ...other };

    if (forAllVacancies) {
      params.for_all_vacancies = true;
    }

    return this.methods.get('/applicants/status/stats', { params });
  }

  fetchAllCount() {
    return this.fetchStats().then(({ stats }) => {
      const status = stats.find(({ status }) => status === 'all');
      return status?.count ?? 0;
    });
  }

  export(fields: string[], params: LocationQueryRaw) {
    return this.methods.post('/applicants/search/xslx', { fields }, { params });
  }

  fetchListByFilter(filter: IApplicantListFilter) {
    const params = pickBy(filter, (val) => !(val === null || val === undefined));
    return this.methods
      .get<ItemList<unknown>>('/applicants', { params })
      .then((response) => response.items);
  }

  fetchByFilter({ only_current_status = 1, ...params }: SearchFilter) {
    return this.methods.get<PaginatedList<ApplicantSearchResultItem>>('/search', {
      params: {
        ...params,
        only_current_status
      }
    });
  }

  fetchShortById(applicantId: number, params: ApplicantFetchParams = {}) {
    return this.methods.get<Applicant | ApplicantResponse>(`/applicants/${applicantId}`, {
      params
    });
  }

  fetchById(applicantId: number) {
    return this.fetchShortById(applicantId).then((applicant) => {
      if (applicant.id !== applicantId) {
        // Пропускаем получение external кандидатов,
        // потому что в текущем случае будет получен
        // новый кандидат, с актуальным id
        return applicant;
      }
      const applicantPromises = applicant.external.map((external) => {
        if (external.is_full === false) {
          return external;
        }
        return ApplicantExternalAPI.fetchById(applicantId, external.id)
          .then((additionalInfo) => {
            // бекенд возвращает нихера... поэтому нужно объединять старое и новое
            return {
              ...external,
              ...additionalInfo
            };
          })
          .catch((err) => {
            console.log(err);
            return external;
          });
      });
      return Promise.all(applicantPromises).then((external) => {
        return { ...applicant, external };
      });
    });
  }

  fetchByIdMemo(applicantId: number) {
    return this.fetchById(applicantId);
  }

  fetchExternal(applicantId: number, externalId: number) {
    return this.methods.get(`/applicant/${applicantId}/external/${externalId}`);
  }

  fetchEmail(applicantId: number, logId: number) {
    return this.methods.get(`/applicant/${applicantId}/log/email/${logId}`);
  }

  fetchViewedStatus(applicantId: number, vacancyId?: number): CancelablePromise<boolean> {
    return this.methods
      .get<ItemsResponse<ApplicantViewedStatus>>(`/applicants/${applicantId}/logs/view/status`, {
        params: vacancyId ? { vacancyId } : null
      })
      .then(({ items }) => items[0]?.is_viewed_by_me);
  }

  updateViewedStatus(applicants: ViewedApplicant[]) {
    return this.methods.post('/applicants/logs/view/status', { applicants });
  }

  updateTags(applicantId: number, tags: string[]) {
    return this.methods.post(`/applicant/${applicantId}/tag`, tags);
  }

  fetchViewSettings() {
    return this.methods.get<ApplicantViewSettings>('/member_applicants_view_settings');
  }

  updateViewSettings(visibleFields: Array<ApplicantViewField>, appicantFilter: ApplicantFilter) {
    const payload = {
      checked: visibleFields,
      'applicant-filter': appicantFilter
    };
    return this.methods.put('/member_applicants_view_settings', payload);
  }
}

export const ApplicantAPI = new ApplicantLayer(axios);
