<template>
  <div :class="$style.editor">
    <slot name="menubar" :editor="editor"></slot>
    <editor-content :editor="editor" :class="editorContentStyle" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import { TextSelection } from '@tiptap/pm/state';
import { Dropcursor } from '@tiptap/extension-dropcursor';
import { Gapcursor } from '@tiptap/extension-gapcursor';
import { createPlaceholderPlugin } from './plugins';

export default {
  name: 'BaseWysiwyg',
  components: {
    EditorContent
  },
  model: {
    prop: 'content',
    event: 'update'
  },
  props: {
    shouldFocusOnMount: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    invalid: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    extensions: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    }
  },
  emits: ['update', 'focus', 'blur'],
  data() {
    return {
      editor: null
    };
  },
  computed: {
    editorContentStyle() {
      return {
        [this.$style.editorContent]: true,
        [this.$style.editorContentDisabled]: this.disabled,
        [this.$style.editorContentInvalid]: this.invalid
      };
    },
    editorAttributes() {
      return {
        name: this.name,
        'aria-invalid': this.invalid ? 'true' : 'false'
      };
    }
  },
  watch: {
    disabled(isDisabled) {
      this.editor.options.editable = !isDisabled;
    },
    editorAttributes(attributes) {
      this.editor.view.setProps({
        attributes
      });
    }
  },
  mounted() {
    this.editor = new Editor({
      editable: !this.disabled,
      autofocus: this.shouldFocusOnMount,
      extensions: [...this.extensions, Dropcursor, Gapcursor],
      content: fixContent(this.content),
      editorProps: {
        attributes: this.editorAttributes
      },
      onUpdate: () => {
        const html = fixGetHtml(this.editor.getHTML());
        this.$emit('update', html);
      },
      onFocus: () => {
        this.$emit('focus');
      },
      onBlur: () => {
        this.$emit('blur');
      }
    });

    this.editor.registerPlugin(
      createPlaceholderPlugin({
        text: this.placeholder
      }).plugin
    );
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    focus() {
      this.editor.focus();
    },
    insertText(text) {
      // вставляет текст в текущую позицию, для использования снаружи
      // (нужно для функционала "вставить переменную")
      const { selection } = this.editor.state;
      let shouldAddSpaceBefore = false;
      let shouldAddSpaceAfter = false;
      if (selection instanceof TextSelection && selection.empty) {
        const isSpace = (char) => /\s/.test(char);
        const { nodeBefore, nodeAfter } = selection.$head;
        shouldAddSpaceBefore =
          nodeBefore?.isText && !isSpace(nodeBefore.text[nodeBefore.nodeSize - 1]);
        shouldAddSpaceAfter = nodeAfter?.isText && !isSpace(nodeAfter.text[0]);
      }
      const trimmedText = (text ?? '').trim();
      const textBefore = shouldAddSpaceBefore ? ' ' : '';
      const textAfter = shouldAddSpaceAfter ? ' ' : '';
      this.editor.chain().focus().insertContent(`${textBefore}${trimmedText}${textAfter}`).run();
    }
  }
};

/*
 * сейчас есть проблема с ресайзом таблиц
 * https://github.com/ueberdosis/tiptap/issues/721
 * https://discuss.prosemirror.net/t/when-using-the-domserializer-table-does-not-include-colgroup-cols-for-pixel-widths/2167
 */

function fixContent(content) {
  const tempNode = document.createElement('div');
  tempNode.innerHTML = content;

  // удаляем лишние переносы - их добавит сам редактор
  tempNode.querySelectorAll('p').forEach((node) => {
    if (node.childElementCount === 1 && node.firstChild instanceof HTMLBRElement) {
      node.removeChild(node.firstChild);
    }
  });

  // удалить как появится поддержка на бэке (? хотя может это лучше дропнуть с бэка..)
  tempNode.querySelectorAll('blockquote').forEach((node) => {
    node.removeAttribute('style');
  });

  return tempNode.innerHTML;
}

function fixGetHtml(html) {
  const tempNode = document.createElement('div');
  tempNode.innerHTML = html;
  tempNode.querySelectorAll('td').forEach((node) => {
    if (!node.hasAttribute('colwidth')) {
      return;
    }
    const width = node.getAttribute('colwidth');
    const colwidth = parseInt(width);
    node.setAttribute('width', colwidth);
  });

  const paragraphs = tempNode.querySelectorAll('p');
  if (paragraphs.length === 1 && !paragraphs[0].firstChild) {
    // пустой визивиг, нужно для того,
    // чтобы при выводе результата не отображался пустой параграф с отступами
    return '';
  }

  // для одинкавого отображения результата и содержимого редактора
  // (в редакторе в пустые параграфы вставляется перенос)
  paragraphs.forEach((paragraphNode) => {
    if (!paragraphNode.firstChild) {
      paragraphNode.appendChild(document.createElement('br'));
    }
  });

  // для отображения бордера таблиц в письме (ибо там доступны только inline-стили)
  tempNode.querySelectorAll('blockquote').forEach((blockquoteNode) => {
    const color = blockquoteNode.style.color;
    if (!color) {
      return;
    }
    blockquoteNode.querySelectorAll('td').forEach((tdNode) => {
      tdNode.style.borderColor = color;
    });
  });

  // убираем параграфы из элементов списка (они создают проблему в верстке офферов)
  tempNode.querySelectorAll('li > p:first-child').forEach((paragraphNode) => {
    if (paragraphNode.nextSibling) {
      return;
    }
    const liNode = paragraphNode.parentNode;
    while (paragraphNode.firstChild) {
      liNode.appendChild(paragraphNode.firstChild);
    }
    liNode.removeChild(paragraphNode);
  });

  return tempNode.childNodes.length > 1 ? tempNode.outerHTML : tempNode.innerHTML;
}
</script>

<style lang="less" module>
@import '~@less/common/variables.less';

.editor {
  position: relative;
}

.editorContent {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.editorContentDisabled {
  color: #ccc;
}

.editorContent :global(.ProseMirror-gapcursor)::after {
  border: none;
}

.editorContent :global(.ProseMirror-hideselection *) {
  caret-color: initial;
}

.editorContent :global(.ProseMirror) {
  min-height: 160px;
  overflow: auto;
  padding: 8px 16px;
  line-height: 1.2;
  border: 1px solid @borderColor;
  border-radius: 0 0 3px 3px;
  background: #f2f2f2;
  transition-property: border-color;
  transition-duration: 100ms;
}

:global(.form-group_invalid) .editorContent :global(.ProseMirror), /* простите пожалуйста (уйдет после выброса старых форм) */
:global(.form-group_invalid) .editorContent :global(.ProseMirror.ProseMirror-focused),
.editorContent.editorContentInvalid :global(.ProseMirror),
.editorContent.editorContentInvalid :global(.ProseMirror.ProseMirror-focused) {
  border-left-color: @errorColor;
  border-bottom-color: @errorColor;
  border-right-color: @errorColor;
}

.editorContent :global(.ProseMirror.ProseMirror-focused) {
  border-color: #2cc8df;
}

.editorContent * {
  caret-color: currentColor;
  outline: none;
}

.editorContent ol,
.editorContent ul {
  margin-top: 0;
  padding-left: 1.5rem;
}

.editorContent li > ol,
.editorContent li > p,
.editorContent li > ul {
  margin: 0;
}

.editorContent :global(.ProseMirror) > p:first-child {
  margin-top: 0;
}

.editorContent :global(.ProseMirror) > p:last-child {
  margin-bottom: 0;
}

.editorContent blockquote {
  margin: 10px 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 0.8rem;
  color: #677478;
}

.editorContent blockquote td {
  border-color: #677478 !important;
}

.editorContent a {
  color: @blueColor;
}

.editorContent img {
  max-width: unset;
  border-radius: 3px;
}

.editorContent table {
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0;
  overflow: hidden;
}

.editorContent table td,
.editorContent table th {
  min-width: 1em;
  border: 1px dotted #d0d0d0;
  box-sizing: border-box;
  position: relative;
}

.editorContent table th {
  font-weight: 700;
  text-align: left;
}

.editorContent table :global(.selectedCell):after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.editorContent table :global(.column-resize-handle) {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}

.editorContent :global(.tableWrapper) {
  overflow-x: auto;
}

.editorContent :global(.resize-cursor) {
  cursor: ew-resize;
  cursor: col-resize;
}

.editorContent :global(.wysiwyg-placeholder) {
  color: var(--placeholder-color);
  opacity: var(--placeholder-opacity);
}
</style>

<i18n lang="json">{}</i18n>
